import config from 'src/config';
import { getRequest, postRequest } from 'src/utils/RequestUtil';

export const sendOptAPI = ({
    phone,
}) => {
    const url = `${config.baseUrl}auth/otp`;
    return postRequest({
        url,
        data: {
            phone,
        },
    });
};

export const loginAPI = ({
    identifier,
    password,
}) => {
    const url = `${config.baseUrl}auth`;
    return postRequest({
        url,
        data: {
            identifier,
            password,
        },
    });
};

export const googleLoginAPI = ({
    accessToken,
    googleId,
}) => {
    const url = `${config.baseUrl}auth/google-login`;
    return postRequest({
        url,
        data: {
            accessToken,
            googleId,
        },
    });
};

export const googleOneTapLoginAPI = ({
    token,
}) => {
    const url = `${config.baseUrl}auth/google-ot`;
    return postRequest({
        url,
        data: {
            token,
        },
    });
};

export const clientDetailsAPI = ({ clientCode }) => {
    const url = `${config.baseUrl}bizgeek-host/clients/${clientCode}`;
    return getRequest({
        url,
    });
};

export default {
    sendOptAPI,
    loginAPI,
    googleLoginAPI,
    googleOneTapLoginAPI,
    clientDetailsAPI,
};
