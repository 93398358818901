import React, { useEffect, useCallback } from 'react';
import AddressListComponent from 'src/components/common/address/AddressListComponent';
import { userAddressesFetchAction, userAddressAddAction } from 'src/actions/UserActions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { deliveryAddressesUpdateAction } from 'src/actions/CartActions';

const DeliveryAddressContainer = () => {
    // REDUX
    const dispatch = useDispatch();

    const {
        selectedPincode,
    } = useSelector((state) => state.locationReducer, shallowEqual);

    const {
        userDefaultAddress,
    } = useSelector((state) => state.userReducer, shallowEqual);

    const {
        addresses,
        addressesFetchStatus,
    } = useSelector((state) => state.userReducer, shallowEqual);

    const {
        deliveryAddress,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    const onAddressChangeHandler = useCallback((address) => {
        dispatch(deliveryAddressesUpdateAction({
            deliveryAddress: address,
        }));
    }, [
        dispatch,
    ]);

    const enforcedPincode = Number(selectedPincode);
    const fallbackAddress = userDefaultAddress && userDefaultAddress.pincode === enforcedPincode ? userDefaultAddress : null;

    // LIFE CYCLE
    useEffect(() => {
        if (addresses.length < 1 && !addressesFetchStatus) {
            dispatch(userAddressesFetchAction());
        }

        // if delivery address is not selected but user has a default address then set default address as delivery  address
        if (!deliveryAddress && fallbackAddress) {
            onAddressChangeHandler(fallbackAddress);
        }
    }, [
        addresses,
        addressesFetchStatus,
        deliveryAddress,
        dispatch,
        onAddressChangeHandler,
        fallbackAddress,
    ]);

    // METHODS
    const onAddNewAddress = ({
        firstName,
        lastName,
        phone,
        fullAddress,
        pincode,
        state_id,
    }) => {
        dispatch(userAddressAddAction({
            firstName,
            lastName,
            phone,
            fullAddress,
            pincode,
            state_id,
        }));
    };

    return (
        <AddressListComponent
            addresses={addresses}
            selectedAddress={deliveryAddress || fallbackAddress}
            onAddressSelection={onAddressChangeHandler}
            onAddNewAddress={onAddNewAddress}
            enforcedPincode={enforcedPincode}
            disabledButtonText="Selected PINCODE is Different"
        />
    );
};

DeliveryAddressContainer.propTypes = {

};

export default DeliveryAddressContainer;
