export const PAYMENT_ACTIONS = {
    PAYMENT_TYPES_FETCH: 'PAYMENT_TYPES_FETCH',
    PAYMENT_TYPES_FETCH_SUCCESS: 'PAYMENT_TYPES_FETCH_SUCCESS',
    PAYMENT_TYPES_FETCH_ERROR: 'PAYMENT_TYPES_FETCH_ERROR',

    SELECTED_PAYMENT_TYPES_UPDATE: 'SELECTED_PAYMENT_TYPES_UPDATE',
};

export const paymentTypesFetchAction = () => ({
    type: PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH,
});

export default {
    PAYMENT_ACTIONS,
    paymentTypesFetchAction,
};
