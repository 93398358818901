import { put, call, select } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { searchSuggestionsApi } from 'src/api/SearchApi';
import { SEARCH_ACTIONS } from 'src/actions/SearchActions';

export function* getSearchSuggestions({
    query,
}) {
    try {
        if (!query) {
            return;
        }
        const {
            locationReducer: {
                selectedPincode,
            },
        } = yield select();

        const {
            data: {
                data: searchSuggestions,
            },
        } = yield call(searchSuggestionsApi, {
            q: query,
            pincode: selectedPincode,
        });

        yield put({
            type: SEARCH_ACTIONS.SEARCH_SUGGESTIONS_FETCH_SUCCESS,
            searchSuggestions,
        });
    }
    catch (error) {
        console.error('getSearchSuggestions', error);
        yield put({
            type: SEARCH_ACTIONS.SEARCH_SUGGESTIONS_FETCH_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(SEARCH_ACTIONS.SEARCH_SUGGESTIONS_FETCH, getSearchSuggestions),
];
