import { PRODUCT_DETAILS_ACTIONS } from 'src/actions/ProductDetailsActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';

const initialState = {
    productDetailsList: {
        // [productId]: { productId, productName, productDescription, productPrice, productImageUrl.... so on }
    },
    additionalDetails: {
        // [productId]: {productDetailsList, productCode}
    },
    productDetails: {
        // [productId]: { productId, productName, productDescription, productPrice, productImageUrl.... so on }
    },
};

export default (state = initialState, action) => {
    const newState = {
        ...state,
    };

    switch (action.type) {
        // PRODUCT LIST
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH: {
            newState.additionalDetails[action.productCode] = { productDetailsListFetchStatus: REQUEST_STATUS.PENDING };
            return newState;
        }
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH_SUCCESS: {
            const { productDetailsList, additionalDetails } = action;

            newState.productDetailsList[action.productCode] = productDetailsList;
            newState.additionalDetails[action.productCode] = additionalDetails;
            newState.additionalDetails[action.productCode].productDetailsListFetchStatus = REQUEST_STATUS.SUCCESS;
            break;
        }
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH_ERROR: {
            newState.additionalDetails[action.productCode].productDetailsListFetchStatus = REQUEST_STATUS.ERROR;
            break;
        }

        // SINGLE PRODUCT DETAILS
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH: {
            newState.productDetails[action.productCode] = { productDetailsFetchStatus: REQUEST_STATUS.PENDING };
            return newState;
        }
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH_SUCCESS: {
            const { productDetails, additionalDetails } = action;

            newState.additionalDetails[action.productCode] = additionalDetails;
            newState.productDetails[action.productCode] = productDetails;
            newState.productDetails[action.productCode].productDetailsFetchStatus = REQUEST_STATUS.SUCCESS;
            break;
        }
        case PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH_ERROR: {
            newState.productDetails[action.productCode].productDetailsFetchStatus = REQUEST_STATUS.ERROR;
            break;
        }

        default:
            return state;
    }

    return newState;
};
