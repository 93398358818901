/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../common/LinkComponent';

const RangeComponent = React.memo(({
    title,
    min,
    max,
    defaultValue,
    onChange,
    isDefaultCollapsed,
}) => {
    const [
        rangeValue,
        setRangeValue,
    ] = useState(defaultValue);

    const handleRangeChange = (e) => {
        setRangeValue(e.target.value);
    };

    return (
        <article className="filter-group">
            <h6 className="title">
                <LinkComponent
                    className="dropdown-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#collapse_3"
                >
                    {title}
                </LinkComponent>
            </h6>
            <div
                className={`filter-content ${isDefaultCollapsed ? 'collapse' : 'show'}`}
                id="collapse_3"
            >
                <div className="inner">
                    <label>
                        Min
                        {' '}
                        {min}
                    </label>
                    <label style={{ float: 'right' }}>
                        Max
                        {' '}
                        {max}
                    </label>
                    <input
                        type="range"
                        className="custom-range"
                        min={min}
                        max={max}
                        value={rangeValue}
                        onChange={handleRangeChange}
                    />

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Price Range</label>
                            <input
                                className="form-control"
                                placeholder={rangeValue}
                                onChange={handleRangeChange}
                                min={min}
                                max={max}
                                type="number"
                            />
                        </div>
                        {/* <div className="form-group text-right col-md-6">
                            <label>Max</label>
                            <input
                                className="form-control"
                                placeholder={max}
                                type="number"
                            />
                        </div> */}
                    </div>

                    {/* form-row.// */}
                    <button
                        onClick={() => {
                            if (onChange) {
                                onChange(rangeValue);
                            }
                        }}
                        type="button"
                        className="btn btn-block btn-primary"
                    >
                        Apply
                    </button>
                </div>
                {/* inner.// */}
            </div>
        </article>
    );
});

RangeComponent.propTypes = {
    title: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    defaultValue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isDefaultCollapsed: PropTypes.bool,
};

RangeComponent.defaultProps = {
    isDefaultCollapsed: false,
};

export default RangeComponent;
