import React, { useCallback } from 'react';
import UserDetailsComponent from 'src/components/profile/UserDetailsComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { updateUserDetailsAction, updateUserProfilePictureAction } from 'src/actions/UserActions';
import { appHeadMessageShow } from 'src/actions/AppActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';

const UserDetailsContainer = () => {
    const dispatch = useDispatch();
    const { userDetails, userProfilePictureUpdateStatus } = useSelector((state) => state.userReducer, shallowEqual);

    const onProfilePicUploadHandler = useCallback((event) => {
        const { files } = event.target;
        const file = files[0];
        if (!file) {
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
            return;
        }

        const maxAllowedSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxAllowedSize) {
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
            dispatch(appHeadMessageShow({ headMessage: 'Picture is too Big to Upload. Max Size: 5 MB' }));
        }

        if (files[0]) {
            dispatch(updateUserProfilePictureAction({ file }));
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
        }
    }, [
        dispatch,
    ]);

    const onSaveClickHandler = ({
        dob,
        email,
        first_name,
        last_name,
        phone,
        password,
    }) => {
        const data = {
            ...userDetails,
            dob: new Date(dob),
            email,
            first_name,
            last_name,
            phone,
            password,
        };

        if (!data.password) {
            delete data.password;
        }

        dispatch(updateUserDetailsAction(data));
    };

    const showLoaderOnProfilePic = userProfilePictureUpdateStatus === REQUEST_STATUS.PENDING;

    return (
        <div className="col-md-9 mt-3 mt-md-0">
            <UserDetailsComponent
                onProfilePicUpload={onProfilePicUploadHandler}
                details={userDetails}
                onSaveClick={onSaveClickHandler}
                showLoaderOnProfilePic={showLoaderOnProfilePic}
            />
        </div>
    );
};

export default UserDetailsContainer;
