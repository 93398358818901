import React from 'react';
import AppVersionComponent from 'src/components/common/AppVersionComponent';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import LinkComponent from 'src/components/common/LinkComponent';

const FooterContainer = () => {
    // get items form redux
    const {
        appPreference: {
            ecart_footer_mid,
        },
    } = useSelector((state) => state.appReducer, shallowEqual) || {};

    return (
        <footer className="section-footer border-top bg">
            <div className="container">
                <section className="footer-bottom row">
                    <div className="col-md-2">
                        <div className="text-muted">
                            © 2020-
                            {moment().format('YY')}
                            {' '}
                            BizGeek
                            <AppVersionComponent />
                        </div>
                    </div>

                    <div className="col-md-8 text-md-center mt-3 mt-md-0">
                        {/* <div className="px-2">infocityhosting@gmail.com</div> */}
                        <div className="px-2">{ecart_footer_mid}</div>
                    </div>
                    <div className="col-md-2 text-md-right text-muted mt-3 mt-md-0">
                        <LinkComponent
                            className="mb-2 d-block"
                            to="/privacy-policy"
                        >
                            Privacy Policy
                        </LinkComponent>
                        <LinkComponent
                            className="mb-2 d-block"
                            to="/refund-policy"
                        >
                            Refund Policy
                        </LinkComponent>
                        <i className="fab fa-lg fa-cc-visa" />
                        <i className="fab fa-lg fa-cc-paypal" />
                        <i className="fab fa-lg fa-cc-mastercard" />
                    </div>
                </section>
            </div>
        </footer>
    );
};

FooterContainer.propTypes = {

};

export default FooterContainer;
