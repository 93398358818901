import { put, call } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { PAYMENT_ACTIONS } from 'src/actions/PaymentActions';
import { getPaymentTypesAPI } from 'src/api/BillingApi';

export function* getPaymentTypes() {
    try {
        const {
            data: {
                data: paymentTypes,
            },
        } = yield call(getPaymentTypesAPI);

        yield put({
            type: PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH_SUCCESS,
            paymentTypes,
        });

        yield put({
            type: PAYMENT_ACTIONS.SELECTED_PAYMENT_TYPES_UPDATE,
            selectedPaymentType: paymentTypes[0],
        });
    }
    catch (error) {
        console.error('getPaymentTypes', error);
        yield put({
            type: PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH, getPaymentTypes),
];
