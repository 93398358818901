import PropTypes from 'prop-types';
import React from 'react';
import LinkComponent from 'src/components/common/LinkComponent';
import { gotoProductReviewPage } from 'src/utils/RoutingUtil';

const ProductDescriptionPanel = ({
    description,
    meta,
    productCode,
    productName,
}) => {
    const rows = meta.map((item) => (
        <tr key={item.name}>
            <td className="text-capitalize">{item.name}</td>
            <td>{item.value}</td>
        </tr>
    ));

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <h5 className="title-description">
                        Description
                        <LinkComponent to={gotoProductReviewPage({
                            productCode,
                            productName,
                        })}
                        >
                            <small className="label-rating text-success hand-cursor">
                                View Reviews
                            </small>
                        </LinkComponent>
                    </h5>
                    <p>{description}</p>

                    {/* <ul className="list-check">
                <li>Material: Stainless steel</li>
                <li>Weight: 82kg</li>
                <li>built-in drip tray</li>
                <li>Open base for pots and pans</li>
                <li>On request available in propane execution</li>
            </ul> */}
                    <h5 className="title-description">Specifications</h5>
                    <table className="table table-bordered">
                        <tbody>
                            {/* <tr><th colSpan={2}>Basic specs</th></tr> */}
                            {rows}
                        </tbody>
                    </table>
                </div>

                {/* <aside className="col-md-4">
                        <div className="box">
                            <h5 className="title-description">Files</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <h5 className="title-description">Videos</h5>
                            <article className="media mb-3">
                                <a href="#">
                                    <img
                                        className="img-sm mr-3"
                                        src="images/posts/3.jpg"
                                    />
                                </a>
                                <div className="media-body">
                                    <h6 className="mt-0"><a href="#">How to use this item</a></h6>
                                    <p className="mb-2">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin</p>
                                </div>
                            </article>
                            <article className="media mb-3">
                                <a href="#">
                                    <img
                                        className="img-sm mr-3"
                                        src="images/posts/2.jpg"
                                    />
                                </a>
                                <div className="media-body">
                                    <h6 className="mt-0"><a href="#">New tips and tricks</a></h6>
                                    <p className="mb-2">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin</p>
                                </div>
                            </article>
                            <article className="media mb-3">
                                <a href="#">
                                    <img
                                        className="img-sm mr-3"
                                        src="images/posts/1.jpg"
                                    />
                                </a>
                                <div className="media-body">
                                    <h6 className="mt-0"><a href="#">New tips and tricks</a></h6>
                                    <p className="mb-2">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin</p>
                                </div>
                            </article>
                        </div>
                    </aside>
                 */}
            </div>
        </div>
    );
};

ProductDescriptionPanel.propTypes = {
    description: PropTypes.string,
    productCode: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
    })),
    productName: PropTypes.string,
};

ProductDescriptionPanel.defaultProps = {
    description: '',
    productCode: '',
    meta: [],
    productName: '',
};

export default ProductDescriptionPanel;
