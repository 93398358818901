import { put, call, select } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { getStoresByPincodeAPI } from 'src/api/StoreApi';
import { STORE_ACTIONS } from 'src/actions/StoreActions';

export function* getStoresSaga() {
    const {
        locationReducer: {
            selectedPincode,
        },
    } = yield select();

    try {
        const {
            data: {
                data: stores,
            },
        } = yield call(getStoresByPincodeAPI, { pincode: selectedPincode });

        yield put({
            type: STORE_ACTIONS.STORE_FETCH_SUCCESS,
            stores,
        });
    }
    catch (error) {
        console.error('getStoresSaga', error);
        yield put({
            type: STORE_ACTIONS.STORE_FETCH_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(STORE_ACTIONS.STORE_FETCH, getStoresSaga),
];
