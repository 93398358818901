import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import LinkComponent from './LinkComponent';

const GoogleLoginComponent = ({
    googleSignInInit,
    clientId,
    onSuccess,
    onError,
}) => {
    const renderButton = ({
        onClick,
        disabled,
    }) => (
        <LinkComponent
            onClick={(e) => {
                onClick(e);
                if (googleSignInInit) {
                    googleSignInInit(e);
                }
            }}
            disabled={disabled}
            className="btn btn-google btn-block mb-4"
        >
            {' '}
            <i className="fab fa-google" />
            {' '}
            &nbsp; Sign in with Google
        </LinkComponent>
    );

    const onSuccessHandler = (response) => {
        if (onSuccess) {
            onSuccess(response);
        }
    };

    const onErrorHandler = (error) => {
        console.error(error);
        if (onError) {
            onError(error);
        }
    };

    if (!clientId) {
        return null;
    }

    return (
        <GoogleLogin
            render={renderButton}
            clientId={clientId}
            onSuccess={onSuccessHandler}
            onFailure={onErrorHandler}
            cookiePolicy="single_host_origin"
            responseType="code,token"
            scope="profile email"
        />
    );
};

GoogleLoginComponent.propTypes = {
    clientId: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    googleSignInInit: PropTypes.func,
};

GoogleLoginComponent.defaultProps = {
    clientId: null,
    onSuccess: null,
    onError: null,
    googleSignInInit: null,
};

export default GoogleLoginComponent;
