/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';

const CartAddButtonComponent = ({
    title,
    onClick,
    ...rest
}) => (
    <LinkComponent
        onClick={onClick}
        className="btn  btn-primary"
        {...rest}
    >
        <i className="fas fa-shopping-cart" />
        {' '}
        <span className="text">{title}</span>
    </LinkComponent>
);

CartAddButtonComponent.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CartAddButtonComponent;
