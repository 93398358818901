/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LoginComponent.scss';
import TextComponent from '../common/text-component/TextComponent';
import LinkComponent from '../common/LinkComponent';
import ButtonWithCountDownComponent from '../common/ButtonWithCountDownComponent';
import GoogleLoginComponent from '../common/GoogleLoginComponent';

const LoginComponent = React.memo(({
    generateOtp,
    defaultIdentifier,
    message,
    errorMessage,
    loginHandler,
    googleClientID,
    googleSignInInit,
    onSuccessGoogleLogin,
    onErrorGoogleLogin,
}) => {
    const [
        identifier,
        setIdentifier,
    ] = useState(defaultIdentifier);

    const [
        validationMessage,
        setValidationMessage,
    ] = useState(null);

    const [
        password,
        setPassword,
    ] = useState('');

    const resetInternalState = () => {
        setValidationMessage(null);
    };

    const generateOtpClick = () => {
        if (!identifier || identifier.length !== 10) {
            setValidationMessage('Please Enter a valid 10 Digit Phone Number');
            return false; // do not start count down
        }
        resetInternalState();
        generateOtp({ phone: identifier });
        return true; // disable otp button
    };

    const loginClick = () => {
        resetInternalState();
        if (!identifier) {
            setValidationMessage('Please enter phone number or email');
            return;
        }
        if (!password) {
            setValidationMessage('Please enter OTP or password');
            return;
        }
        loginHandler({
            identifier,
            password,
        });
    };

    return (
        <section
            className="section-content padding-y"
            style={{ minHeight: '84vh' }}
        >
            <div
                className="card mx-auto"
                style={{
                    maxWidth: 380,
                    marginTop: 20,
                }}
            >
                <div className="card-body">
                    <h4 className="card-title mb-4">Sign in</h4>
                    <div>
                        {/* <LinkComponent
                            className="btn btn-facebook btn-block mb-2"
                        >
                            {' '}
                            <i className="fab fa-facebook-f" />
                            {' '}
                            &nbsp; Sign in with Facebook
                        </LinkComponent> */}
                        <ButtonWithCountDownComponent
                            className={`btn btn-generate-otp btn-block mb-2 ${!generateOtp && 'hide'}`}
                            onClick={generateOtpClick}
                        >
                            {' '}
                            <i className="fas fa-mobile-alt" />
                            {' '}
                            &nbsp; Sign in with OTP
                        </ButtonWithCountDownComponent>

                        {googleClientID ? (
                            <GoogleLoginComponent
                                googleSignInInit={googleSignInInit}
                                className={googleClientID && 'hide'}
                                clientId={googleClientID}
                                onSuccess={onSuccessGoogleLogin}
                                onError={onErrorGoogleLogin}
                            />
                        ) : null}

                        <div className="form-group">
                            <TextComponent
                                className="form-control"
                                placeholder="Phone Number or Email"
                                type="text"
                                onValueChange={setIdentifier}
                                defaultValue={defaultIdentifier}
                                onEnter={loginClick}
                            />
                        </div>
                        <div className="form-group">
                            <TextComponent
                                className="form-control"
                                placeholder="OTP or Password"
                                type="password"
                                onValueChange={setPassword}
                                onEnter={loginClick}
                            />
                        </div>
                        <div className="form-group">
                            <LinkComponent
                                className="float-right hide"
                            >
                                Forgot password?
                            </LinkComponent>
                            <label className="float-left custom-control custom-checkbox">
                                {' '}
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked
                                />
                                {' '}
                                <div className="custom-control-label"> Remember </div>
                                {' '}
                            </label>
                        </div>

                        <p className={`alert alert-danger ${validationMessage || errorMessage ? '' : 'hide'}`}>
                            {' '}
                            <i className="fas fa-exclamation" />
                            {' '}
                            &nbsp;
                            {' '}
                            {validationMessage || errorMessage}
                        </p>

                        <p className={`alert alert-success ${message ? '' : 'hide'}`}>
                            {' '}
                            <i className="fas fa-info" />
                            {' '}
                            &nbsp;
                            {' '}
                            {message}
                        </p>

                        <div className={`form-group ${loginHandler ? '' : 'hide'}`}>
                            <button
                                onClick={loginClick}
                                type="submit"
                                className="btn btn-primary btn-block"
                            >
                                {' '}
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center mt-4 hide">
                Do not have an account?
                {' '}
                <LinkComponent>Sign up</LinkComponent>
            </p>
            <br />
            <br />
        </section>
    );
});

LoginComponent.propTypes = {
    generateOtp: PropTypes.func,
    loginHandler: PropTypes.func,
    defaultIdentifier: PropTypes.string,
    message: PropTypes.string,
    errorMessage: PropTypes.string,
    googleClientID: PropTypes.string,
    onSuccessGoogleLogin: PropTypes.func,
    onErrorGoogleLogin: PropTypes.func,
    googleSignInInit: PropTypes.func,
};

LoginComponent.defaultProps = {
    generateOtp: null,
    loginHandler: null,
    defaultIdentifier: '',
    message: '',
    errorMessage: '',
    googleClientID: '',
    onSuccessGoogleLogin: null,
    onErrorGoogleLogin: null,
    googleSignInInit: null,
};

export default LoginComponent;
