import { shape, string, number } from 'prop-types';

export const OrderPropType = shape({
    bill_no: string,
    channel_id: number,
    contact_person: string,
    coupon_code: string,
    created_by: string,
    created_on: string,
    customer_address_id: number,
    customer_id: number,
    delievery_address: string,
    delievery_charges: number,
    delivery_type: number,
    discount_per: number,
    discount_rs: number,
    due_amount: number,
    email: string,
    final_price: number,
    final_price_without_tax: number,
    foc: number,
    id: number,
    is_canceled: number,
    is_synced: number,
    online_bill_id: number,
    online_bill_no: string,
    order_status: string,
    payed_amount: number,
    payment_done: number,
    payment_type: number,
    phone: string,
    pickup_time: string,
    pin: string,
    pk: string,
    remarks: string,
    searchQry: string,
    source: string,
    state_id: number,
    store_id: number,
    table: string,
    tax_amount: number,
    tax_per: number,
    total_purchase_price: number,
    updated_by: string,
    updated_on: string,
});

export const OrderItemPropType = shape({
    category: string,
    discount_amount: number,
    discount_per: number,
    id: number,
    img_url: string,
    product_actual_price: number,
    product_code: string,
    product_id: number,
    product_name: string,
    product_price: number,
    product_type: string,
    sell_id: number,
    total_product_price: number,
    unit_type: number,
    units: number,
    size: string,
    color: string,
});

export const CouponPropTypes = shape({
    code: string,
    description: string,
    expire_on: string,
    id: number,
});

export const DeliveryTypePropTypes = shape({
    name: string,
    id: string,
});

export default {
    OrderPropType,
    OrderItemPropType,
    DeliveryTypePropTypes,
};
