import React from 'react';
import LinkComponent from '../common/LinkComponent';

const EmptyCartComponent = () => (

    <section className="section-content padding-y">
        <div className="container-fluid mt-100">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body cart">
                            <div className="col-sm-12 empty-cart-cls text-center">
                                {' '}
                                <i
                                    className="fas fa-shopping-cart fa-9x mb-5 mt-5"
                                    style={{ color: 'var(--theme-major-color)' }}
                                />
                                <h3><strong>Your Cart is Empty</strong></h3>
                                <h4>Add Something and Be Happy :)</h4>
                                {' '}
                                <LinkComponent
                                    to="/"
                                    className="btn btn-primary cart-btn-transform m-3"
                                    data-abc="true"
                                >
                                    Continue Shopping
                                </LinkComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default EmptyCartComponent;
