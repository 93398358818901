import React, {
    useEffect, useState, useCallback, useRef,
} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { userOrderFetchAction } from 'src/actions/UserActions';
import PaginationComponent from 'src/components/common/PaginationComponent';
import { gotoViewPort } from 'src/utils/CommonUtils';
import OrderDetailContainer from '../order/OrderDetailContainer';

const ORDERS_PER_PAGE = 3;

const OrderListingContainer = () => {
    // REDUX
    const dispatch = useDispatch();

    const [
        page,
        setPage,
    ] = useState(0);

    const {
        userOrders,
        userTotalOrders,
    } = useSelector((state) => state.userReducer, shallowEqual);

    const container = useRef();

    // LIFE CYCLE
    useEffect(() => {
        dispatch(userOrderFetchAction({
            page,
            rows: ORDERS_PER_PAGE,
        }));
    }, [
        page,
    ]);
    const onPageChangeHandler = useCallback(({
        pageIndex,
    }) => {
        setPage(pageIndex);
        gotoViewPort(container.current);
    }, []);

    const paginationElement = userOrders.length > 0 ? (
        <PaginationComponent
            recordsCount={userTotalOrders || userOrders.length}
            recordsPerPage={ORDERS_PER_PAGE}
            onPageChange={onPageChangeHandler}
            currentPage={page}
        />
    ) : null;

    const records = userOrders.map((order) => (
        <OrderDetailContainer
            key={order.id}
            order={order}
        />
    ));

    return (
        <main
            ref={container}
            className="col-md-9 mt-3 mt-md-0"
        >
            {records}
            {paginationElement}
        </main>
    );
};

export default OrderListingContainer;
