/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../common/LinkComponent';

const ItemsFilterComponent = React.memo(({
    title,
    items,
    filteredItems,
    onFilterApply,
    isDefaultCollapsed,
}) => {
    // const [
    //     list,
    //     setList,
    // ] = useState(items);

    // usePrevious(
    //     { items },
    //     (prevValues) => {
    //         // if record count changed then go to first page
    //         if (prevValues.items !== items) {
    //             setList(items);
    //         }
    //     },
    // );

    const categoryElements = Object.keys(items).map((item) => (
        <label
            key={item}
            htmlFor={item}
            className="custom-control custom-checkbox"
            style={{ cursor: 'pointer' }}
        >
            <input
                checked={Boolean(filteredItems[item])}
                type="checkbox"
                id={item}
                onChange={() => {
                    if (onFilterApply) {
                        const tempList = {
                            ...filteredItems,
                        };

                        if (tempList[item]) {
                            delete tempList[item];
                        }
                        else {
                            tempList[item] = items[item];
                        }

                        // setList(tempList);
                        onFilterApply({ items: tempList });
                    }
                }}
                className="custom-control-input"
            />
            <div className="custom-control-label text-capitalize">
                {item}
                <b className="badge badge-pill badge-light float-right">{items[item].count}</b>
            </div>
        </label>
    ));

    return (
        <article
            className="filter-group "
        >
            <h6 className="title">
                <LinkComponent
                    className="dropdown-toggle collapsed"
                    data-toggle="collapse"
                    data-target={`#${title}`}
                >
                    {title}
                </LinkComponent>
            </h6>
            <div
                className={`filter-content ${isDefaultCollapsed ? 'collapse' : 'show'}`}
                id={title}
            >
                <div className="inner">
                    {categoryElements}
                </div>
                {/* inner.// */}
                {/* <button
                    onClick={() => {
                        if (onFilterApply) {
                            onFilterApply({
                                items: list,
                            });
                        }
                    }}
                    type="button"
                    className="btn btn-block btn-primary"
                >
                    Apply
                </button> */}
            </div>
        </article>
    );
});

ItemsFilterComponent.propTypes = {
    items: PropTypes.object,
    filteredItems: PropTypes.object,
    isDefaultCollapsed: PropTypes.bool,
    onFilterApply: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

ItemsFilterComponent.defaultProps = {
    items: {},
    filteredItems: {},
    isDefaultCollapsed: false,
};

export default ItemsFilterComponent;
