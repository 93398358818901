import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const getPincodeInfoAPI = ({ pincode }) => {
    const url = `${config.baseUrl}pincode-master`;
    return getRequest({
        url,
        params: {
            postal_code: pincode,
        },
    });
};

export const getGoogleLocationDataAPI = async ({
    query,
    key,
}) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${key}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
};

export const getStateListAPI = async () => {
    const url = `${config.baseUrl}master-state`;
    return getRequest({
        url,
        params: {
            country_id: 99,
        },
    });
};

export default {
    getPincodeInfoAPI,
    getGoogleLocationDataAPI,
    getStateListAPI,
};
