/* eslint-disable */
import React from 'react';
import LinkComponent from '../common/LinkComponent';

const CategoryHeaderComponent = ({ list }) => {
    return <nav className="navbar navbar-main navbar-expand-lg navbar-light border-bottom bg-primary">
        <div className="container">
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#main_nav"
                aria-controls="main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>
            <div
                className="collapse navbar-collapse"
                id="main_nav"
            >
                <ul className="navbar-nav">
                    {list.map(item => (<li
                        key={item.category}
                        className="nav-item">
                        <LinkComponent
                            to={item.to}
                            className="nav-link text-capitalize text-light"
                        >
                            <strong>{item.category}</strong>
                        </LinkComponent>
                    </li>))}

                </ul>
            </div>
            {/* collapse .// */}
        </div>
        {/* container .// */}
    </nav>;
};

CategoryHeaderComponent.propTypes = {

};

export default CategoryHeaderComponent;
