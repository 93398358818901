export const BUTTON_TYPES = {
    ADD_CART_TOGGLE: 'ADD_CART_TOGGLE',
    ADD_CART_PLUS_MINUS: 'ADD_CART_PLUS_MINUS',
};

export const PINCODE_SIZE = 6;

export const DEFAULT_USER_DP = '/assets/images/avatars/default-dp.jpeg';

export const DEFAULT_PRODUCT_IMAGE = '/assets/images/items/default-item.png';

export default {
    BUTTON_TYPES,
    PINCODE_SIZE,
    DEFAULT_USER_DP,
    DEFAULT_PRODUCT_IMAGE,
};
