import React from 'react';
import {
    Route, Switch, useHistory,
} from 'react-router-dom';
import PrivateRoute from 'src/hoc/PrivateRouteHOC';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { appHeadMessageHide, setRedirectUrlAction } from 'src/actions/AppActions';
import ProfileContainer from 'src/containers/profile/ProfileContainer';
import CloseableMessageComponent from 'src/components/CloseableMessageComponent';
import { richMessageUtil } from 'src/utils/RichMessageUtil';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import {
    gotoUserProfilePage,
    gotoCart,
    gotoLogin,
    gotoProductSearch,
    gotoHomePage,
    gotoCouponPage,
} from 'src/utils/RoutingUtil';
import IframeComponent from 'src/components/IframeComponent';
import { ROUTING_URL } from 'src/constants/RoutingConstants';
import NavigationContainer from '../navigation/NavigationContainer';
import LoginContainer from '../login/LoginContainer';
import ProductListingContainer from '../product-listing/ProductListingContainer';
import NotFoundContainer from '../not-found/NotFoundContainer';
import CartContainer from '../cart/CartContainer';
import CouponListingContainer from '../cart/CouponListingContainer';
import CurationLayoutContainer from '../curation-layout/CurationLayoutContainer';
import FooterContainer from '../navigation/FooterContainer';
import ProductDetailContainer from '../detail/ProductDetailContainer';
import ProductReviewContainer from '../reviews/ProductReviewContainer';

const MainContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        headMessage,
        redirectUrl,
        appPreference: {
            privacy_policy,
            refund_policy,
        },
    } = useSelector((state) => state.appReducer, shallowEqual);
    const headMessageCloseHandler = () => {
        dispatch(appHeadMessageHide());
    };

    // get stores from redux
    const gotoRedirectUtl = (url) => {
        history.push(url);
    };

    usePrevious(
        { redirectUrl },
        (prevValues) => {
            if (prevValues.redirectUrl !== redirectUrl && redirectUrl) {
                gotoRedirectUtl(redirectUrl);
                dispatch(setRedirectUrlAction({
                    redirectUrl: null,
                }));
            }
        },
    );

    const richHeadMessage = richMessageUtil(headMessage);
    const headMessageElement = headMessage ? (
        <CloseableMessageComponent
            headMessage={richHeadMessage}
            onClose={headMessageCloseHandler}
        />
    ) : null;

    return (
        <>
            <NavigationContainer />
            {headMessageElement}
            <Switch>
                <Route
                    path={gotoProductSearch()}
                    exact
                    component={ProductListingContainer}
                />
                <Route
                    path={gotoLogin()}
                    exact
                    component={LoginContainer}
                />
                <PrivateRoute
                    path={gotoUserProfilePage()}
                    component={ProfileContainer}
                />
                <PrivateRoute
                    exact
                    path={gotoCart()}
                    component={CartContainer}
                />
                <PrivateRoute
                    exact
                    path={gotoCouponPage()}
                    component={CouponListingContainer}
                />
                <Route
                    exact
                    path={gotoHomePage()}
                    component={CurationLayoutContainer}
                />
                <Route
                    exact
                    path={`${ROUTING_URL.PRODUCT_DETAILS_PAGE}/:productCode/:productName?/:storeId?`}
                    component={ProductDetailContainer}
                />
                <Route
                    exact
                    path={`${ROUTING_URL.PRODUCT_REVIEW_PAGE}/:productCode/:productName?`}
                    component={ProductReviewContainer}
                />
                <Route
                    exact
                    path="/privacy-policy"
                    component={() => {
                        if (!privacy_policy) {
                            return null;
                        }
                        return (
                            <IframeComponent
                                src={privacy_policy}
                                title="Privacy Policy"
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path="/refund-policy"
                    component={() => {
                        if (!refund_policy) {
                            return null;
                        }
                        return (
                            <IframeComponent
                                src={refund_policy}
                                title="Refund Policy"
                            />
                        );
                    }}
                />
                <Route
                    component={NotFoundContainer}
                />
            </Switch>
            <FooterContainer />
        </>
    );
};

export default MainContainer;
