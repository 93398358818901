/* eslint-disable no-return-assign */
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export const usePrevious = (value, callback) => {
    const prevValues = useRef(value);

    useEffect(() => {
        callback(prevValues.current);
        return () => (prevValues.current = value);
    }, [
        value,
        callback,
    ]);
};

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default {
    usePrevious,
    useQuery,
};
