/* eslint-disable */
import React from 'react';
import Slider from 'react-slick';
import SlickNextArrow from '../slick-arrows/SlickNextArrow';
import SlickPrevArrow from '../slick-arrows/SlickPrevArrow';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //             infinite: true,
    //             dots: true,
    //         },
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //             initialSlide: 2,
    //         },
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //         },
    //     },
    // ],
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />
};

const MultipleTileCarouselComponent = ({
    children,
}) => (
    <Slider {...settings}>
        {children}
    </Slider>
);

MultipleTileCarouselComponent.propTypes = {

};

export default MultipleTileCarouselComponent;
