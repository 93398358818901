import {
    call, select, delay, put, takeLatest,
} from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import {
    APP_ACTIONS, appHeadMessageHide, appHeadMessageShown, appUpdateClientData, appUpdatePreferences,
} from 'src/actions/AppActions';
import config from 'src/config';
import { clientDetailsAPI } from 'src/api/AuthApi';
import { getAppPreferences } from 'src/api/SettingApi';
import ReactGA from 'react-ga';

export function* setDefaultHeadersSaga() {
    try {
        // if token is there then set token
        const { authReducer: { token } } = yield select();

        setDefaultHeaders({
            Authorization: token,
            'client-code': config.brandDetails.clientCode,
        });
    }
    catch (error) {
        console.error('setDefaultHeadersSaga', error);
    }
}

// fetch history messages
export function* appInitSaga() {
    try {
        // get client details
        const {
            data: {
                data: clientData,
            },
        } = yield call(clientDetailsAPI, { clientCode: config.brandDetails.clientCode });

        const {
            data: {
                data: appPreference,
            },
        } = yield call(getAppPreferences);

        yield put(appUpdatePreferences({
            appPreference: appPreference[0] || {},
        }));

        yield put(appUpdateClientData({
            clientData,
        }));

        // init google analytics
        if (appPreference[0]) {
            const { google_analytics_tracking_id } = appPreference[0];
            if (google_analytics_tracking_id) {
                ReactGA.initialize(google_analytics_tracking_id);
            }
        }
    }
    catch (error) {
        console.error('appInitSaga', error);
    }
}

export function* showHeadMessageSaga({
    visibilityTime = 5000,
    headMessage,
}) {
    yield put(appHeadMessageShown({
        headMessage,
    }));

    yield delay(visibilityTime); /// hide head after 5 seconds

    yield put(appHeadMessageHide());
}

export default [
    takeFirstSagaUtil(APP_ACTIONS.APP_INIT, appInitSaga),
    takeFirstSagaUtil(APP_ACTIONS.SET_DEFAULT_HEADERS, setDefaultHeadersSaga),
    takeLatest(APP_ACTIONS.APP_HEAD_MESSAGE_SHOW, showHeadMessageSaga),
];
