/* eslint-disable */
import React from 'react';
import LinkComponent from '../common/LinkComponent';
import LinkWrap from '../common/LinkWrap';

const LogWithSubTitleTileComponent = ({
    title,
    imgUrl,
    to,
}) => (
    <div className="col-md-2 col-6">
        <figure className="box item-logo">
            <LinkWrap to={to}><img src={imgUrl} /></LinkWrap>
            <figcaption className="border-top pt-2">{title}</figcaption>
        </figure>
    </div>
);

LogWithSubTitleTileComponent.propTypes = {

};

export default LogWithSubTitleTileComponent;
