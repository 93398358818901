/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import CartAddRemoveButtonContainer from 'src/containers/smart-components/CartAddRemoveButtonContainer';
import { gotoProductDetailPage, productImageUrl } from 'src/utils/RoutingUtil';
import { ProductItemPropType } from 'src/constants/prop-types/ProductPropTypes';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';

const CartArticleListComponent = ({ items, title }) => {
    const itemElements = [];
    for (const key in items) {
        if (Object.prototype.hasOwnProperty.call(items, key)) {
            const item = items[key];
            const imgUrl = productImageUrl({ product: item });
            const element = (
                <tr key={item.product_code}>
                    <td>
                        <LinkComponent
                            to={gotoProductDetailPage({
                                productCode: item.product_code,
                                storeId: item.store_id,
                                productName: item.product_name,
                            })}
                        >
                            <figure className="itemside align-items-center">
                                <div className="aside">
                                    <img
                                        alt={item.product_name}
                                        src={imgUrl}
                                        className="img-sm"
                                    />
                                </div>
                                <figcaption className="info">
                                    <div className="title text-dark">
                                        {item.product_name}
                                    </div>
                                    <p className="text-muted small">
                                        Code:
                                        {' '}
                                        {item.product_code}
                                        <br />
                                        {' '}
                                        Category:
                                        {' '}
                                        {item.category}
                                    </p>
                                </figcaption>
                            </figure>
                        </LinkComponent>
                    </td>
                    {/* <td>
                        <select className="form-control">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select>
                    </td> */}
                    <td>
                        <LinkComponent
                            to={gotoProductDetailPage({
                                productCode: item.product_code,
                                storeId: item.store_id,
                                productName: item.product_name,
                            })}
                        >
                            <div className="price-wrap">
                                <var className="price">
                                    <i className="fas fa-rupee-sign" />
                                    &nbsp;
                                    {item.final_selling_price * item.count}
                                </var>
                                <small className="text-muted">
                                    <i className="fas fa-rupee-sign" />
                                    &nbsp;
                                    {item.final_selling_price}
                                    &nbsp;each
                                </small>
                            </div>
                        </LinkComponent>
                        {/* price-wrap .// */}
                    </td>
                    <td className="text-center d-md-block">
                        <CartAddRemoveButtonContainer item={item} />
                        {/* <a
                        data-original-title="Save to Wishlist"
                        title
                        href
                        className="btn btn-light"
                        data-toggle="tooltip"
                    >
                        {' '}
                        <i className="fa fa-heart" />
                    </a> */}
                    </td>
                </tr>
            );

            itemElements.push(element);
        }
    }

    return (
        <div className="table-responsive">
            <table className="table table-borderless table-shopping-cart mb-0">
                <thead className="text-muted">
                    <tr className="small text-titlecase">
                        <th
                            scope="col"
                            width={620}
                        >
                            {title}
                        </th>
                        {/* <th
                            scope="col"
                            width={120}
                        >
                            Quantity
                        </th> */}
                        <th
                            scope="col"
                        // width={120}
                        >
                            Price
                        </th>
                        <th
                            scope="col"
                            className="text-center"
                        // width={200}
                        >
                            Quantity
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {itemElements}
                </tbody>
            </table>
        </div>
    );
};

CartArticleListComponent.propTypes = {
    items: PropTypes.arrayOf(ProductItemPropType),
    title: PropTypes.string.isRequired,
};

CartArticleListComponent.defaultProps = {
    items: [],
};

export default CartArticleListComponent;
