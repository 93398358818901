import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { foreColorForBackgroundColor } from 'src/utils/ColorUtils';
import { gotoProductDetailPage } from 'src/utils/RoutingUtil';
import LinkComponent from '../common/LinkComponent';

const ColorSizeCollection = ({
    title,
    options,
}) => {
    const filteredOptions = options.filter((option) => option.size || option.color);

    return (
        filteredOptions && filteredOptions.length ? (
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    {
                        filteredOptions.map(({
                            size, color, id, product_code, product_name,
                        }) => (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <LinkComponent
                                to={gotoProductDetailPage({
                                    productCode: product_code,
                                    productName: product_name,
                                })}
                                key={id}
                                className="checkbox-btn"
                            >
                                <span
                                    style={{
                                        background: color,
                                        color: foreColorForBackgroundColor(color),
                                    }}
                                    className={classnames('btn btn-light mr-1')}
                                >
                                    {' '}
                                    {size}
                                    {' '}
                                </span>
                            </LinkComponent>
                        ))
                    }
                </div>
            </div>
        )
            : null
    );
};

ColorSizeCollection.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        size: PropTypes.string,
    })),
};

ColorSizeCollection.defaultProps = {
    options: [],
};

export default ColorSizeCollection;
