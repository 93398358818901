import PropTypes from 'prop-types';
import React from 'react';
import { PAYMENT_METHODS } from 'src/constants/PaymentConstants';
import LinkComponent from './LinkComponent';

const PaymentCardComponent = ({
    total,
    discount,
    totalAfterDiscount,
    payMethod,
    onPayMethodChange,
    purchaseErrorList,
    continueShopping,
    makePurchase,
    orderTotalDeliveryCharges,
    orderTotalDeliveryChargeWavedOff,
    totalAfterDeliveryAdjustment,
}) => {
    const errorElements = purchaseErrorList.map((message) => (
        <div
            key={message}
            className="alert alert-danger mt-3 mb-2"
            role="alert"
        >
            <i className="fas fa-exclamation-triangle mr-1" />
            {message}
        </div>
    ));

    return (
        <div className="card">
            <div className="card-body">
                <dl className="dlist-align">
                    <dt>Total price:</dt>
                    <dd className="text-right">
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {total}
                    </dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Discount:</dt>
                    <dd className="text-right text-danger">
                        -
                        {' '}
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {discount}
                    </dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Total:</dt>
                    <dd className="text-right text-dark b">
                        <strong>
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {totalAfterDiscount}
                        </strong>

                    </dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Delivery:</dt>
                    <dd className="text-right">
                        +
                        {' '}
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {orderTotalDeliveryCharges}
                    </dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Wave Off:</dt>
                    <dd className="text-right text-danger">
                        -
                        {' '}
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {orderTotalDeliveryChargeWavedOff}
                    </dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Payable:</dt>
                    <dd className="text-right text-dark b">
                        <strong>
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {totalAfterDeliveryAdjustment}
                        </strong>

                    </dd>
                </dl>
                <hr />
                <p className="text-center mb-3">
                    <img
                        src="assets/images/misc/payments.png"
                        height={26}
                        alt="payments"
                    />
                </p>
                <dl className="dlist-align">
                    <dt>Pay:</dt>
                    <dd className="text-right">
                        <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                onClick={() => onPayMethodChange && onPayMethodChange(PAYMENT_METHODS.ONLINE)}
                                type="button"
                                className={`btn ${payMethod === PAYMENT_METHODS.ONLINE ? 'btn-primary' : 'btn-light'}`}
                            >
                                Online
                            </button>
                            <button
                                onClick={() => onPayMethodChange && onPayMethodChange(PAYMENT_METHODS.COD)}
                                type="button"
                                className={`btn ${payMethod === PAYMENT_METHODS.COD ? 'btn-primary' : 'btn-light'}`}
                            >
                                COD
                            </button>
                        </div>
                    </dd>
                </dl>

                {errorElements}
                <LinkComponent
                    onClick={makePurchase}
                    className="btn btn-primary btn-block mt-3"
                >
                    Make Purchase
                    {' '}
                    <i className="fa fa-chevron-right" />
                </LinkComponent>
                <LinkComponent
                    onClick={continueShopping}
                    className="btn btn-light btn-block"
                >
                    <i className="fa fa-chevron-left" />
                    {' '}
                    More Shopping
                </LinkComponent>
            </div>
        </div>
    );
};

PaymentCardComponent.propTypes = {
    discount: PropTypes.number,
    total: PropTypes.number,
    totalAfterDiscount: PropTypes.number,
    payMethod: PropTypes.number.isRequired,
    onPayMethodChange: PropTypes.func.isRequired,
    purchaseErrorList: PropTypes.arrayOf(PropTypes.string),
    continueShopping: PropTypes.func.isRequired,
    makePurchase: PropTypes.func.isRequired,
    orderTotalDeliveryCharges: PropTypes.number,
    orderTotalDeliveryChargeWavedOff: PropTypes.number,
    totalAfterDeliveryAdjustment: PropTypes.number,
};

PaymentCardComponent.defaultProps = {
    discount: 0,
    total: 0,
    totalAfterDiscount: 0,
    purchaseErrorList: [],
    orderTotalDeliveryCharges: 0,
    orderTotalDeliveryChargeWavedOff: 0,
    totalAfterDeliveryAdjustment: 0,
};

export default PaymentCardComponent;
