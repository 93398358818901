import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

// export const getProductAPI = () => {
//     const url = `${config.baseUrl}product-master/get-all-products`;
//     return getRequest({
//         url,
//     });
// };

export const getProductByPincodeAPI = (({
    pincode,
    page,
    rows,
    storeNames,
    categories,
    price,
    searchQuery,
}) => {
    const url = `${config.baseUrl}product-master/get-products-by-pincode/${pincode}`;
    return getRequest({
        url,
        params: {
            page,
            rows,
            storeNames,
            categories,
            price,
            searchQuery,
        },
    });
});

export const getProductDetailsByPincodeAPI = (({
    pincode,
    productCode,
}) => {
    const url = `${config.baseUrl}product-master/products-details-by-pincode/${pincode}/${productCode}`;
    return getRequest({
        url,
    });
});

export const getStoreAndProductCountByPincodeAPI = (({ pincode }) => {
    const url = `${config.baseUrl}product-master/store-product-count/${pincode}`;
    return getRequest({
        url,
    });
});

export const getCategoriesByPincodeAPI = (({ pincode }) => {
    const url = `${config.baseUrl}product-master/categories`;
    return getRequest({
        url,
        params: {
            pincode,
        },
    });
});

export const getProductInfoAPI = (({ productCode }) => {
    const url = `${config.baseUrl}product-master`;
    return getRequest({
        url,
        params: {
            product_code: productCode,
        },
    });
});

export const getProductsByParentSku = (({
    pincode,
    parentSku,
}) => {
    const url = `${config.baseUrl}product-master/get-products-by-parent-sku/${pincode}/${parentSku}`;
    return getRequest({
        url,
    });
});

export default {
    // getProductAPI,
    getProductByPincodeAPI,
    getStoreAndProductCountByPincodeAPI,
    getCategoriesByPincodeAPI,
    getProductDetailsByPincodeAPI,
    getProductInfoAPI,
    getProductsByParentSku,
};
