import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { openInGoogleMap } from 'src/utils/RoutingUtil';

const StoreAddressContainer = ({
    storeId,
    storeName,
}) => {
    const [
        store,
        setStore,
    ] = useState(null);

    // get stores from redux
    const {
        stores,
    } = useSelector((state) => state.storeReducer, shallowEqual);

    useEffect(() => {
        if (
            stores.length
            && (!!storeId || !!storeName)
        ) {
            const storeFound = stores.find((s) => s.name === storeName || s.id === storeId);
            setStore(storeFound);
        }
    }, [
        stores,
        storeId,
        storeName,
    ]);

    if (!store) {
        return null;
    }

    // const delivery_charges = 20;
    // const minimum_order = 100;
    // const wave_off_delivery_charges_exceeding_minimum_order = true;
    // const allow_order_lesser_than_minimum_order_with_delivery_charges = false;

    const {
        delivery_charges,
        minimum_order,
        wave_off_delivery_charges_exceeding_minimum_order,
        allow_order_lesser_than_minimum_order_with_delivery_charges,
    } = store;

    let deliveryChargesMessage = 'No Delievery Charges! No Minimum Order!';

    if (delivery_charges && minimum_order && wave_off_delivery_charges_exceeding_minimum_order && allow_order_lesser_than_minimum_order_with_delivery_charges) {
        deliveryChargesMessage = `Delievery Charges ${delivery_charges} on order less than ${minimum_order}`;
    }

    else if (delivery_charges && !minimum_order) {
        deliveryChargesMessage = `Delievery Charges ${delivery_charges}`;
    }

    else if (!delivery_charges && minimum_order) {
        deliveryChargesMessage = `Minimum Order ${minimum_order}`;
    }

    else if (delivery_charges && minimum_order) {
        deliveryChargesMessage = `Delievery Charges ${delivery_charges} and Minimum Order ${minimum_order}`;
    }

    return (
        <>
            <div className="small text-titlecase float-md-left">
                {deliveryChargesMessage}
            </div>
            <div className="small text-titlecase float-md-right mr-md-3">
                <a
                    className="always-highlight-color"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={openInGoogleMap({
                        lat: store?.lat,
                        lon: store?.lon,
                    })}
                >
                    <i className="fas fa-map-marker-alt mr-1" />
                    {`${store?.address}, ${store?.pin}`}
                </a>
            </div>
        </>
    );
};

StoreAddressContainer.propTypes = {
    storeId: PropTypes.number,
    storeName: PropTypes.string,
};

StoreAddressContainer.defaultProps = {
    storeId: null,
    storeName: null,
};

export default StoreAddressContainer;
