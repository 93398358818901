import React from 'react';
import PaymentCardComponent from 'src/components/common/PaymentCardComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { changePaymentMethodAction, makePurchaseAction } from 'src/actions/CartActions';
import { useHistory } from 'react-router';
import { gotoProductSearch } from 'src/utils/RoutingUtil';

const PaymentCardContainer = () => {
    const history = useHistory();

    const {
        purchaseErrorList,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    const {
        total,
        discount,
        totalAfterDiscount,
        totalAfterDeliveryAdjustment,
        payMethod,
        orderTotalDeliveryCharges,
        orderTotalDeliveryChargeWavedOff,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    const dispatch = useDispatch();

    const onPayMethodChange = (newPayMethod) => {
        dispatch(changePaymentMethodAction({ payMethod: newPayMethod }));
    };

    const continueShopping = () => {
        history.push(gotoProductSearch());
    };

    const makePurchase = () => {
        dispatch(makePurchaseAction());
    };

    return (
        <PaymentCardComponent
            total={total}
            discount={discount}
            totalAfterDiscount={totalAfterDiscount}
            payMethod={payMethod}
            onPayMethodChange={onPayMethodChange}
            continueShopping={continueShopping}
            makePurchase={makePurchase}
            purchaseErrorList={purchaseErrorList}
            totalAfterDeliveryAdjustment={totalAfterDeliveryAdjustment}
            orderTotalDeliveryCharges={orderTotalDeliveryCharges}
            orderTotalDeliveryChargeWavedOff={orderTotalDeliveryChargeWavedOff}
        />
    );
};

export default PaymentCardContainer;
