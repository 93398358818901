import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavLinkComponent from '../common/NavLinkComponent';
import TextComponent from '../common/text-component/TextComponent';

const NavigationComponent = ({
    logoImage,
    brandName,
    onSearchQuerySelection,
    searchInputDefaultText,
    numberOfItemsInCart,
    productsUrl,
    cartUrl,
    homeUrl,
    profileUrl,
    profileIconText,
    profilePictureUrl,
    couponPageUrl,
    couponCount,
    locationTitle,
    locationChangeUrl,
    requestSearchSuggestions,
    searchSuggestions,
}) => {
    const [
        query,
        setQuery,
    ] = useState(null);

    // if default value changed then reset query also
    useEffect(() => {
        setQuery(searchInputDefaultText);
    }, [
        searchInputDefaultText,
    ]);

    // user is typing
    const onSearchQueryChange = useCallback((value) => {
        requestSearchSuggestions(value);
        setQuery(value);
    }, [
        setQuery,
        requestSearchSuggestions,
    ]);

    // user has pressed enter on random text
    const onSearchQuerySelectionHandler = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onSearchQuerySelection(query);
    }, [
        onSearchQuerySelection,
        query,
    ]);

    // user has selected a option from suggestion
    const onSuggestionSelection = useCallback((value) => {
        onSearchQuerySelection(value);
    }, [
        onSearchQuerySelection,
    ]);

    const profilePictureElement = !profilePictureUrl ? <i className="fa fa-user" /> : (
        <img
            height="35"
            width="35"
            alt={profileIconText}
            src={profilePictureUrl}
            className="rounded-circle mb-2"
        />
    );

    return (
        <header className="section-header">
            <section className="header-main border-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-3 col-md-12">
                            <NavLinkComponent
                                to={homeUrl}
                                className="brand-wrap"
                            >
                                <img
                                    className="logo"
                                    alt={brandName}
                                    src={logoImage}
                                />
                            </NavLinkComponent>
                        </div>
                        <div className="col-xl-6 col-lg-5 col-md-6">
                            <div
                                className="search-header"
                            >
                                <div className="input-group w-100">
                                    {/* <select
                                    className="custom-select border-right"
                                    name="category_name"
                                >
                                    <option value>All type</option>
                                    <option value="codex">Special</option>
                                    <option value="comments">Only best</option>
                                    <option value="content">Latest</option>
                                </select> */}
                                    <TextComponent
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        onEnter={onSearchQuerySelectionHandler}
                                        defaultValue={searchInputDefaultText}
                                        onValueChange={onSearchQueryChange}
                                        suggestions={searchSuggestions}
                                        onSuggestionSelection={onSuggestionSelection}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            onClick={onSearchQuerySelectionHandler}
                                            className="btn btn-primary"
                                            type="submit"
                                        >
                                            <i className="fa fa-search" />
                                            {' '}
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="widgets-wrap float-md-right">
                                <div className="widget-header mr-3">
                                    <NavLinkComponent
                                        to={locationChangeUrl}
                                        exact
                                        className="widget-view"
                                    >
                                        <div className="icon-area mb-2">
                                            <i className="fas fa-map-marker-alt always-highlight-color" />
                                        </div>
                                        <small className="text always-highlight-color">
                                            {locationTitle}
                                        </small>
                                    </NavLinkComponent>
                                </div>
                                <div className="widget-header mr-3">
                                    <NavLinkComponent
                                        exact
                                        to={couponPageUrl}
                                        className="widget-view"
                                    >
                                        <div className="icon-area mb-2">
                                            <i className="fa fas fa-gifts" />
                                            <span className={`notify ${!couponCount ? 'hide' : ''}`}>{couponCount}</span>
                                        </div>
                                        <small className="text"> Coupons </small>
                                    </NavLinkComponent>
                                </div>
                                <div className="widget-header mr-3">
                                    <NavLinkComponent
                                        exact
                                        className="widget-view"
                                        to={productsUrl}
                                    >
                                        <div className="icon-area mb-2">
                                            <i className="fa fa-store" />
                                        </div>
                                        <small className="text"> Products </small>
                                    </NavLinkComponent>
                                </div>
                                <div className="widget-header mr-3">
                                    <NavLinkComponent
                                        exact
                                        to={cartUrl}
                                        className="widget-view"
                                    >
                                        <div className="icon-area mb-2">
                                            <i className="fa fa-shopping-cart" />
                                            <span className={`notify ${!numberOfItemsInCart ? 'hide' : ''}`}>{numberOfItemsInCart}</span>
                                        </div>
                                        <small className="text"> Cart </small>
                                    </NavLinkComponent>
                                </div>
                                <div className="widget-header">
                                    <NavLinkComponent
                                        to={profileUrl}
                                        className="widget-view"
                                    >
                                        <div className="icon-area mb-2">
                                            {profilePictureElement}
                                        </div>
                                        <small className="text text-capitalize">
                                            {' '}
                                            {profileIconText}
                                            {' '}
                                        </small>
                                    </NavLinkComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    );
};

NavigationComponent.propTypes = {
    logoImage: PropTypes.string,
    brandName: PropTypes.string,
    onSearchQuerySelection: PropTypes.func,
    searchInputDefaultText: PropTypes.string,
    numberOfItemsInCart: PropTypes.number,
    cartUrl: PropTypes.string,
    homeUrl: PropTypes.string,
    profileUrl: PropTypes.string,
    profileIconText: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    productsUrl: PropTypes.string,
    couponPageUrl: PropTypes.string,
    couponCount: PropTypes.number,
    locationTitle: PropTypes.string,
    locationChangeUrl: PropTypes.string,
    requestSearchSuggestions: PropTypes.func,
    searchSuggestions: PropTypes.arrayOf(PropTypes.string),
};

NavigationComponent.defaultProps = {
    logoImage: '',
    brandName: '',
    onSearchQuerySelection: null,
    searchInputDefaultText: '',
    numberOfItemsInCart: 0,
    cartUrl: null,
    homeUrl: null,
    profileUrl: null,
    profileIconText: null,
    profilePictureUrl: null,
    productsUrl: null,
    couponPageUrl: null,
    couponCount: null,
    locationTitle: '',
    locationChangeUrl: '',
    requestSearchSuggestions: () => { },
    searchSuggestions: [],
};

export default NavigationComponent;
