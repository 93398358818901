import React from 'react';
import PropTypes from 'prop-types';

const IframeComponent = ({
    src,
    title,
}) => (
    <iframe
        frameBorder="0"
        src={src}
        title={title}
        style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
        }}
        height="100%"
        width="100%"
    />
);

IframeComponent.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default IframeComponent;
