import { SEARCH_ACTIONS } from 'src/actions/SearchActions';

const initialState = {
    searchSuggestions: [],
};

const resetState = {
    searchSuggestions: [],
};

export default (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case SEARCH_ACTIONS.SEARCH_SUGGESTIONS_FETCH_SUCCESS: {
            newState = {
                ...newState,
                searchSuggestions: action.searchSuggestions,
            };
            break;
        }
        default:
            return state;
    }

    return newState;
};
