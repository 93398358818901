/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ProductReviewContainer.scss'
import RatingComponent from 'src/components/common/RatingComponent';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { productDetailsFetchAction } from 'src/actions/ProductDetailsActions';
import { useParams } from 'react-router';
import ReviewsProgressChart from './components/ReviewsProgressChart';
import ProductReviewForm from './ProductReviewForm';
import { productReviewFetchAction } from 'src/actions/ProductReviewActions';
import ReviewList from './components/ReviewList';
import LinkComponent from 'src/components/common/LinkComponent';
import { gotoProductDetailPage } from 'src/utils/RoutingUtil';

// template source: https://www.bootdey.com/snippets/view/bs4-Ratings-and-Reviews-page#html

const ProductReviewContainer = () => {
    const dispatch = useDispatch();
    const {
        productCode,
        productName,
    } = useParams();

    const productDetails = useSelector((state) => state.productDetailsReducer.productDetails[productCode], shallowEqual)

    const productReviews = useSelector((state) => state.productReviewReducer.reviewByproductCode[productCode]?.reviews || [], shallowEqual);

    const reviewAggregation = useSelector((state) => state.productReviewReducer.aggregateRatingByProductCode[productCode] || {}, shallowEqual)

    useEffect(() => {
        if (!productDetails?.id && !productDetails?.productDetailsFetchStatus) {
            dispatch(productDetailsFetchAction({ productCode }));
            dispatch(productReviewFetchAction({ productCode }));
        }
    }, [
        productCode,
        productDetails,
    ]);

    return (
        <div className="product-review mt-3">
            <div className="container">
                <div className="col-md-12">
                    <div className="offer-dedicated-body-left">
                        <div
                            className="tab-content"
                            id="pills-tabContent"
                        >
                            <div
                                className="tab-pane fade active show"
                                id="pills-reviews"
                                role="tabpanel"
                                aria-labelledby="pills-reviews-tab"
                            >
                                {/* HEADING */}
                                <LinkComponent
                                    to={gotoProductDetailPage({
                                        productCode,
                                        productName: productDetails?.product_name
                                    })}
                                >
                                    <div
                                        id="ratings-and-reviews"
                                        className="bg-white rounded shadow-sm p-4 mb-4 clearfix restaurant-detailed-star-rating"
                                    >
                                        <h5 className="mb-0 pt-1 text-capitalize">
                                            <i className="fas fa-long-arrow-alt-left"></i>
                                            {' '}
                                            {productDetails?.product_name}
                                        </h5>
                                        <RatingComponent rating={reviewAggregation?.avg_rating || 0} />
                                        {' '}
                                        <b className="text-black ml-2">{reviewAggregation?.total + " Rating" || 'No Ratings'}</b>
                                        {' '}
                                    </div>
                                </LinkComponent>

                                {/* NEW COMMENT FORM */}
                                <ProductReviewForm
                                    productCode={productCode}
                                />


                                {/* AGGREGATED RATING PROGRESS BAR */}
                                <ReviewsProgressChart
                                    avg_rating={reviewAggregation?.avg_rating}
                                    five={reviewAggregation?.five}
                                    four={reviewAggregation?.four}
                                    one={reviewAggregation?.one}
                                    three={reviewAggregation?.three}
                                    total={reviewAggregation?.total}
                                    two={reviewAggregation?.two}
                                />

                                {/* LIST OF REVIEWS */}
                                <ReviewList
                                    reviews={productReviews}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProductReviewContainer.propTypes = {

};

export default ProductReviewContainer;
