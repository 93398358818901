/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, memo } from 'react';
import { UserDetailsPropType } from 'src/constants/prop-types/UserPropTypes';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import TextComponent from '../common/text-component/TextComponent';
import FileSelectorComponent from '../common/FileSelectorComponent';
import LoaderComponent from '../common/loader-component/LoaderComponent';

const UserDetailsComponent = memo(({
    details: {
        customer_code,
        dob,
        email,
        first_name,
        last_name,
        phone,
        dp,
    },
    onSaveClick,
    onProfilePicUpload,
    showLoaderOnProfilePic,
}) => {
    const defaultDate = new Date(dob) < new Date('1950-1-1') ? null : new Date(dob).getTime();

    const [
        birthDay,
        setBirthDay,
    ] = useState(defaultDate);

    const firstNameInputRef = useRef();
    const lastNameInputRef = useRef();
    const emailInputRef = useRef();
    const phoneInputRef = useRef();
    const passwordInputRef = useRef();

    const onSaveClickHandler = (e) => {
        e.preventDefault();
        if (onSaveClick) {
            onSaveClick({
                dob: birthDay,
                email: get(emailInputRef, 'current.value', email),
                first_name: get(firstNameInputRef, 'current.value', first_name),
                last_name: get(lastNameInputRef, 'current.value', last_name),
                phone: get(phoneInputRef, 'current.value', phone),
                password: get(passwordInputRef, 'current.value', null),
            });
        }
    };

    const profilePicElement = showLoaderOnProfilePic ? <LoaderComponent /> : (
        <FileSelectorComponent
            onChange={onProfilePicUpload}
            accept="image/x-png,image/jpeg"
        >
            <img
                key={dp || Math.random()}
                alt={customer_code}
                src={dp}
                className="img-sm rounded-circle border"
            />
        </FileSelectorComponent>
    );

    return (
        <div className="card">
            <div className="card-body">
                <form
                    onSubmit={onSaveClickHandler}
                    autoComplete="nope"
                >
                    <div className="form-group">
                        <h6
                            style={{
                                display: 'inline-block',
                                float: 'right',
                            }}
                            className="text-muted"
                        >
                            Customer Code:
                            {' '}
                            {customer_code}
                        </h6>

                        {profilePicElement}

                        <div className="mt-2">
                            <DateTimePickerComponent
                                showTimeSelector={false}
                                label="Birthday Date"
                                onAccept={setBirthDay}
                                disableFuture
                                disablePast={false}
                                selectedDate={birthDay}
                                openTo="year"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col form-group">
                            <label>First Name</label>
                            <TextComponent
                                ref={firstNameInputRef}
                                type="text"
                                className="form-control"
                                defaultValue={first_name}
                                required
                            />
                        </div>
                        <div className="col form-group">
                            <label>Last Name</label>
                            <TextComponent
                                ref={lastNameInputRef}
                                type="text"
                                className="form-control"
                                defaultValue={last_name}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Email</label>
                            <TextComponent
                                ref={emailInputRef}
                                type="email"
                                defaultValue={email}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Phone</label>
                            <TextComponent
                                ref={phoneInputRef}
                                type="text"
                                className="form-control"
                                defaultValue={phone}
                                required
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Password</label>
                            <TextComponent
                                ref={passwordInputRef}
                                type="password"
                                className="form-control"
                                autoComplete="new-password"
                                pattern=".{8,}"
                                title="Eight or more characters"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-block col-md-2"
                    >
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
});

UserDetailsComponent.propTypes = {
    details: UserDetailsPropType,
    onSaveClick: PropTypes.func.isRequired,
    onProfilePicUpload: PropTypes.func.isRequired,
    showLoaderOnProfilePic: PropTypes.bool,
};

UserDetailsComponent.defaultProps = {
    details: {},
    showLoaderOnProfilePic: false,
};

export default UserDetailsComponent;
