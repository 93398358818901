/* eslint-disable react/forbid-prop-types */
import {
    string,
    number,
    shape,
    any,
} from 'prop-types';

export const UserAddressPropType = shape({
    address: string,
    contact_person: string,
    created_by: any,
    created_on: string,
    customer_id: number,
    id: number.isRequired,
    location_id: number,
    phone: string,
    pincode: number,
});

export const UserDetailsPropType = shape({
    customer_code: string,
    dob: string,
    email: string,
    first_name: string,
    last_name: string,
    full_address: string,
    gender: string,
    id: number,
    phone: string,
});

export default {
    UserAddressPropType,
    UserDetailsPropType,
};
