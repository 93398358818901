import React from 'react';
import PropTypes from 'prop-types';
import { CouponPropTypes } from 'src/constants/prop-types/OrderPropTypes';
import { gotoCart } from 'src/utils/RoutingUtil';
import moment from 'moment';
import LinkComponent from '../common/LinkComponent';

const CouponListingComponent = ({ coupons }) => {
    const couponElements = coupons.map(({
        code,
        description,
        expire_on,
        id,
    }) => (
        <div
            className=" col-md-6 col-lg-4"
            key={id}
        >
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">{code}</h5>
                    <h6 className="card-subtitle mb-2 mt-2 text-muted">
                        Valid Till:
                        {' '}
                        {moment(expire_on).format('ddd, DD-MMM-YYYY hh:mm a')}
                    </h6>
                    <p className="card-text">{description}</p>
                    <LinkComponent
                        to={gotoCart({ coupon: code })}
                        className="btn btn-primary"
                    >
                        Use It
                    </LinkComponent>
                </div>
            </div>
        </div>
    ));

    return (
        <section className="section-content padding-y">
            <div className="container">
                <div className="row">
                    {couponElements}
                </div>
            </div>
        </section>
    );
};

CouponListingComponent.propTypes = {
    coupons: PropTypes.arrayOf(CouponPropTypes),
};

CouponListingComponent.defaultProps = {
    coupons: [],
};

export default CouponListingComponent;
