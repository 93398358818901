export const APP_ACTIONS = {
    APP_INIT: 'APP_INIT',
    SET_DEFAULT_HEADERS: 'SET_DEFAULT_HEADERS',
    APP_HEAD_MESSAGE_SHOW: 'APP_HEAD_MESSAGE_SHOW',
    APP_HEAD_MESSAGE_SHOWN: 'APP_HEAD_MESSAGE_SHOWN',
    APP_HEAD_MESSAGE_HIDE: 'APP_HEAD_MESSAGE_HIDE',
    UPDATE_REDIRECT_URL: 'UPDATE_REDIRECT_URL',
    UPDATE_CLIENT_DATA: 'UPDATE_CLIENT_DATA ',
    UPDATE_APP_PREFERENCES: 'UPDATE_APP_PREFERENCES ',
};

export const appInitActions = () => ({
    type: APP_ACTIONS.APP_INIT,
});

export const appHeadMessageShow = (payload = {}) => ({
    type: APP_ACTIONS.APP_HEAD_MESSAGE_SHOW,
    ...payload,
});

export const appHeadMessageShown = (payload = {}) => ({
    type: APP_ACTIONS.APP_HEAD_MESSAGE_SHOWN,
    ...payload,
});

export const appHeadMessageHide = () => ({
    type: APP_ACTIONS.APP_HEAD_MESSAGE_HIDE,
});

export const appUpdateClientData = (payload) => ({
    type: APP_ACTIONS.UPDATE_CLIENT_DATA,
    ...payload,
});

export const appUpdatePreferences = (payload) => ({
    type: APP_ACTIONS.UPDATE_APP_PREFERENCES,
    ...payload,
});

export const setDefaultHeadersActions = () => ({
    type: APP_ACTIONS.SET_DEFAULT_HEADERS,
});

export const setRedirectUrlAction = (payload) => ({
    type: APP_ACTIONS.UPDATE_REDIRECT_URL,
    ...payload,
});

export default {
    APP_ACTIONS,
    appInitActions,
    appHeadMessageShow,
    appHeadMessageHide,
    appHeadMessageShown,
    appUpdateClientData,
    setDefaultHeadersActions,
    appUpdatePreferences,
    setRedirectUrlAction,
};
