import React from 'react';

const AppVersionComponent = () => (
    <div
        style={{
            color: '#9c9c9c',
        }}
    >
        Version
        {' '}
        {process.env.REACT_APP_VERSION}
    </div>
);

export default AppVersionComponent;
