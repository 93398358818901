import { AUTH_ACTIONS } from 'src/actions/AuthActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';

const initialState = {
    isLoggedIn: false,
    token: '',
    otpSentStatus: null,
    loginConfirmationStatus: null,
};

const resetState = {
    otpSentStatus: null,
    loginConfirmationStatus: null,
};

const authReducer = (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        // SEND OTP
        case AUTH_ACTIONS.SEND_OTP: {
            return newState;
        }
        case AUTH_ACTIONS.SEND_OTP_SUCCESS: {
            newState = {
                ...newState,
                otpSentStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case AUTH_ACTIONS.SEND_OTP_ERROR: {
            newState = {
                ...newState,
                otpSentStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }

        // CONFIRM OTP
        case AUTH_ACTIONS.LOGIN: {
            return newState;
        }
        case AUTH_ACTIONS.LOGIN_SUCCESS: {
            newState = {
                ...newState,
                loginConfirmationStatus: REQUEST_STATUS.SUCCESS,
                ...action.payload,
                isLoggedIn: !!action.payload.token,
            };
            break;
        }
        case AUTH_ACTIONS.LOGIN_ERROR: {
            newState = {
                ...newState,
                loginConfirmationStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }

        // SOCIAL SIGN IN
        case AUTH_ACTIONS.GOOGLE_LOGIN_SUCCESS:
        case AUTH_ACTIONS.FB_LOGIN_INIT:
            return newState;

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                isLoggedIn: false,
                token: '',
                otpSentStatus: null,
                loginConfirmationStatus: null,
            };
        default:
            return state;
    }

    return newState;
};

export default ReducerSyncWithStorage(authReducer, {
    keysToBeSync: [
        'isLoggedIn',
        'token',
    ],
    initialState,
    storageKeyName: 'authReducer',
    storage: localStorage,
});
