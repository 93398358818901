/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../common/LinkComponent';

const SquareInfoTileComponent = ({
    imgUrl,
    to,
    product_name
}) => (
    <div className="col-md-3">
        <div
            href="#"
            className="card card-product-grid"
        >
            <a
                href="#"
                className="img-wrap"
            >
                {' '}
                <img src={imgUrl} />
                {' '}
            </a>
            <figcaption className="info-wrap">
                <LinkComponent
                    to={to}
                    className="title"
                >
                    {product_name}
                </LinkComponent>
                <div className="rating-wrap">
                    <ul className="rating-stars">
                        <li
                            style={{ width: '80%' }}
                            className="stars-active"
                        >
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                        </li>
                        <li>
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                        </li>
                    </ul>
                    {/* <span className="label-rating text-muted"> 34 reviws</span> */}
                </div>
                {/* <div className="price mt-1">$179.00</div> */}
            </figcaption>
        </div>
    </div>
);

SquareInfoTileComponent.propTypes = {

};

export default SquareInfoTileComponent;
