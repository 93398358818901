import { LOCATION_ACTIONS } from 'src/actions/LocationActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';
import config from 'src/config';

const initialState = {
    pincodes: {},
    states: [],
    selectedPincode: config.default.pincode,
    storeAndProductCount: {
        // 110000:{store:0, products:0}
    },
    currentLatLong: null,
    currentAddress: null,
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_ACTIONS.PINCODE_INFO_FETCH: {
            return {
                ...state,
                pincodes: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.PENDING,
                    },
                },
            };
        }
        case LOCATION_ACTIONS.PINCODE_INFO_FETCH_SUCCESS: {
            return {
                ...state,
                pincodes: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.SUCCESS,
                        info: action.pincodeInfo,
                    },
                },
            };
        }
        case LOCATION_ACTIONS.PINCODE_INFO_FETCH_ERROR: {
            return {
                ...state,
                pincodes: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.ERROR,
                    },
                },
            };
        }

        case LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH: {
            return {
                ...state,
                storeAndProductCount: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.PENDING,
                    },
                },
            };
        }
        case LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH_SUCCESS: {
            return {
                ...state,
                storeAndProductCount: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.SUCCESS,
                        ...action.storeAndProductCount,
                    },
                },
            };
        }
        case LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH_ERROR: {
            return {
                ...state,
                storeAndProductCount: {
                    ...state.pincodes,
                    [action.pincode]: {
                        status: REQUEST_STATUS.ERROR,
                    },
                },
            };
        }

        case LOCATION_ACTIONS.PINCODE_SELECTED: {
            return {
                ...state,
                selectedPincode: action.selectedPincode,
            };
        }

        case LOCATION_ACTIONS.GOOGLE_REV_GEO_FETCH_SUCCESS: {
            return {
                ...state,
                currentAddress: action.currentAddress,
            };
        }

        case LOCATION_ACTIONS.LAT_LNG_SELECTED: {
            return {
                ...state,
                currentLatLong: action.currentLatLong,
            };
        }
        case LOCATION_ACTIONS.STATES_LIST_FETCH_SUCCESS: {
            return {
                ...state,
                states: action.states,
            };
        }
        default:
            return state;
    }
};

export default ReducerSyncWithStorage(locationReducer, {
    keysToBeSync: [
        'selectedPincode',
    ],
    initialState,
    storageKeyName: 'locationReducer',
    storage: localStorage,
});
