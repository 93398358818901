export const TILE_TYPES = {
    BANNER: 1,
    INFO: 2,
    CAROUSEL: 3,
    CAROUSEL_FULL_HEIGHT_WIDTH: 4,
    GRID_PRODUCT_INFO: 5,
    GRID_NORMAL: 6,
    SMALL_GIRD_IMG_INFO: 7,
};

export const CLICK_TO_ACTION = {
    GOTO_PRODUCT: 1,
    NO_ACTION: 3,
};

export default {
    TILE_TYPES,
    CLICK_TO_ACTION,
};
