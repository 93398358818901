/* eslint-disable  */
import React from 'react';
import classNames from "classnames";
import LinkWrap from '../common/LinkWrap';

const SpecialPointsTileComponent = ({
    className,
    faIcon,
    title,
    description,
    to,
}) => (
    <div className={classNames("col-md-4", className)}>
        <figure className="item-feature">
            <span className="text-primary"><i className={"fa fa-2x " + faIcon} /></span>
            <figcaption className="pt-3">
                <LinkWrap to={to}>
                    <h5 className="title">{title}</h5>
                    <p>{description}</p>
                </LinkWrap>
            </figcaption>
        </figure>
    </div>
);

SpecialPointsTileComponent.propTypes = {

};

export default SpecialPointsTileComponent;
