import config from 'src/config';
import { getRequest, postRequest } from 'src/utils/RequestUtil';

export const getPaymentTypesAPI = function () {
    const url = `${config.baseUrl}payment-type-master`;
    return getRequest({ url });
};

export const getDeliveryTypesAPI = function () {
    const url = `${config.baseUrl}delivery-types`;
    return getRequest({ url });
};

export const paymentDoneApi = function ({ orderIds }) {
    const data = {
        orderIds,
    };
    const url = `${config.baseUrl}sell-master/payment-status-done`;
    return postRequest({
        url,
        data,
    });
};

export const orderStatusChangeApi = function ({
    orderIds,
    orderStatus,
}) {
    const data = {
        orderIds,
        orderStatus,
    };
    const url = `${config.baseUrl}sell-master/change-order-status`;
    return postRequest({
        url,
        data,
    });
};

export const orderAPI = function ({
    customer, order, items, userId, printCount,
}) {
    const data = {
        order,
        details: items,
        customer,
        userId,
        printCount,
    };
    const url = `${config.baseUrl}sell-master`;
    return postRequest({
        url,
        data,
    });
};

export const orderBulkAPI = function ({
    customer,
    orderBulk,
    userId,
    printCount,
    paymentType,
    dueAmount,
}) {
    const data = {
        customer,
        orderBulk,
        userId,
        printCount,
        paymentType,
        dueAmount,
    };
    const url = `${config.baseUrl}sell-master-bulk`;
    return postRequest({
        url,
        data,
    });
};

export const getOrderFullInfoAPI = function ({ orderId }) {
    const url = `${config.baseUrl}sell-master-with-details/${orderId}`;
    return getRequest({
        url,
    });
};

export const getOrdersByCustomerAPI = function ({
    customerId,
    page,
    rows,
}) {
    const url = `${config.baseUrl}report/sell-master/customer/${customerId}`;
    return getRequest({
        url,
        params: {
            page,
            rows,
        },
    });
};

export const getEligibleCouponsAPI = function ({ customerId, channelId, pincode }) {
    const url = `${config.baseUrl}coupons/eligible`;
    return getRequest({
        url,
        params: {
            channelId,
            customerId,
            pincode,
        },
    });
};

export const applyCouponAPI = function ({
    coupon, billAmount, channelId, customerId, pincode,
}) {
    const url = `${config.baseUrl}coupons/apply`;
    return postRequest({
        url,
        data: {
            coupon,
            billAmount,
            channelId,
            customerId,
            pincode,
        },
    });
};

export default {
    getPaymentTypesAPI,
    getDeliveryTypesAPI,
    orderAPI,
    getOrderFullInfoAPI,
    getOrdersByCustomerAPI,
    getEligibleCouponsAPI,
    applyCouponAPI,
    paymentDoneApi,
    orderBulkAPI,
};
