/* eslint-disable camelcase */
import { ROUTING_URL } from 'src/constants/RoutingConstants';
import { DEFAULT_PRODUCT_IMAGE } from 'src/constants/UiConstants';
import { stringToKebabCase } from './CommonUtils';

export const gotoProductSearch = ({
    queryParams,
} = {}) => {
    const uri = `https://developer.mozilla.org${ROUTING_URL.PRODUCT_SEARCH_PAGE}`;
    const url = new URL(uri);
    if (queryParams) {
        for (const param in queryParams) {
            if (Object.prototype.hasOwnProperty.call(queryParams, param)) {
                url.searchParams.set(param, queryParams[param]);
            }
        }
    }
    return `${url.pathname}${url.search}`;
};

export const gotoCouponPage = () => ROUTING_URL.COUPON_PAGE;

export const gotoCart = ({ coupon } = {}) => (ROUTING_URL.CART_PAGE + (coupon ? `?coupon=${coupon}` : ''));

export const gotoLogin = () => ROUTING_URL.LOGIN_PAGE;

export const gotoOrdersPage = () => ROUTING_URL.ORDERS_PAGE;

export const gotoUserAddressPage = () => ROUTING_URL.USER_ADDRESS_PAGE;

export const gotoUserProfilePage = () => ROUTING_URL.USER_PROFILE_PAGE;

export const gotoHomePage = () => ROUTING_URL.HOME_PAGE;

export const gotoUserDetailsPage = () => ROUTING_URL.USER_DETAILS_PAGE;

export const gotoPinCodeSelection = () => ROUTING_URL.PIN_CODE_SELECTION_PAGE;

export const productImageUrl = ({ product }) => product.image_url || DEFAULT_PRODUCT_IMAGE;

export const openInGoogleMap = ({ lat, lon }) => `https://maps.google.com/?q=${lat},${lon}`;

export const gotoProductDetailPage = ({ productCode, storeId, productName }) => `${ROUTING_URL.PRODUCT_DETAILS_PAGE}/${productCode}${productName ? `/${stringToKebabCase(productName)}` : ''}${storeId ? `/${storeId} ` : ''}`;

export const gotoProductReviewPage = ({ productCode, productName }) => `/product-review/${productCode}${productName ? `/${stringToKebabCase(productName)}` : ''}`;

export default {
    gotoProductSearch,
    gotoCart,
    gotoLogin,
    productImageUrl,
    gotoOrdersPage,
    gotoUserAddressPage,
    gotoHomePage,
    gotoUserDetailsPage,
    gotoCouponPage,
    gotoPinCodeSelection,
    openInGoogleMap,
    gotoProductDetailPage,
    gotoProductReviewPage,
};
