import React, { useEffect } from 'react';
import AddressListComponent from 'src/components/common/address/AddressListComponent';
import { userAddressesFetchAction, userAddressAddAction, updateUserDefaultAddressAction } from 'src/actions/UserActions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

const UserAddressProfileContainer = () => {
    // REDUX
    const dispatch = useDispatch();
    const {
        addresses,
        addressesFetchStatus,
        userDefaultAddress,
    } = useSelector((state) => state.userReducer, shallowEqual);

    const onAddressChangeHandler = (address) => {
        dispatch(updateUserDefaultAddressAction({
            userDefaultAddress: address,
        }));
    };

    // FETCH USER ADDRESSES
    useEffect(() => {
        if (addresses.length < 1 && !addressesFetchStatus) {
            dispatch(userAddressesFetchAction());
        }
    }, [
        addresses,
        addressesFetchStatus,
        dispatch,
    ]);

    // METHODS
    const onAddNewAddress = ({
        firstName,
        lastName,
        phone,
        fullAddress,
        pincode,
        state_id,
    }) => {
        dispatch(userAddressAddAction({
            firstName,
            lastName,
            phone,
            fullAddress,
            pincode,
            state_id,
        }));
    };

    return (
        <main className="col-md-9 mt-3 mt-md-0">
            <AddressListComponent
                addresses={addresses}
                selectedAddress={userDefaultAddress}
                onAddressSelection={onAddressChangeHandler}
                onAddNewAddress={onAddNewAddress}
                selectedButtonText="Default"
                unSelectedButtonText="Make Default"
            />
        </main>
    );
};

UserAddressProfileContainer.propTypes = {

};

export default UserAddressProfileContainer;
