export const AUTH_ACTIONS = {
    SEND_OTP: 'SEND_OTP',
    SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
    SEND_OTP_ERROR: 'SEND_OTP_ERROR',

    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'CONFIRM_OTP_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    GOOGLE_LOGIN_INIT: 'GOOGLE_LOGIN_INIT',
    GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
    GOOGLE_LOGIN_ONE_TAP_SUCCESS: 'GOOGLE_LOGIN_ONE_TAP_SUCCESS',

    FB_LOGIN_INIT: 'FB_LOGIN_INIT',
    FB_LOGIN_SUCCESS: 'FB_LOGIN_SUCCESS',

    LOGOUT: 'LOGOUT',
};

export const sendOtpAction = (payload) => ({
    type: AUTH_ACTIONS.SEND_OTP,
    ...payload,
});

export const confirmCredentialsAction = (payload) => ({
    type: AUTH_ACTIONS.LOGIN,
    ...payload,
});

export const googleLoginOneTapSuccessAction = (payload) => ({
    type: AUTH_ACTIONS.GOOGLE_LOGIN_ONE_TAP_SUCCESS,
    ...payload,
});

export const googleLoginSuccessAction = (payload) => ({
    type: AUTH_ACTIONS.GOOGLE_LOGIN_SUCCESS,
    ...payload,
});

export const googleLoginInitAction = () => ({
    type: AUTH_ACTIONS.GOOGLE_LOGIN_INIT,
});

export const fbLoginInitAction = () => ({
    type: AUTH_ACTIONS.FB_LOGIN_INIT,
});

export const logoutAction = () => ({
    type: AUTH_ACTIONS.LOGOUT,
});

export default {
    AUTH_ACTIONS,
    sendOtpAction,
    confirmCredentialsAction,
    googleLoginSuccessAction,
    googleLoginOneTapSuccessAction,
    logoutAction,
};
