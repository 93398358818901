import { APP_ACTIONS } from 'src/actions/AppActions';
import { AUTH_ACTIONS } from 'src/actions/AuthActions';

const initialState = {
    headMessage: null,
    redirectUrl: null,
    clientData: {
        client_name: null,
        google_client_id: null,
        razor_key: null,
    },
    appPreference: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case APP_ACTIONS.APP_HEAD_MESSAGE_SHOWN: {
            return {
                ...state,
                headMessage: action.headMessage,
            };
        }
        case APP_ACTIONS.APP_HEAD_MESSAGE_HIDE: {
            return {
                ...state,
                headMessage: null,
            };
        }
        case APP_ACTIONS.UPDATE_REDIRECT_URL: {
            return {
                ...state,
                redirectUrl: action.redirectUrl,
            };
        }
        case AUTH_ACTIONS.LOGOUT: {
            return {
                ...state,
                headMessage: null,
                redirectUrl: null,
            };
        }
        case APP_ACTIONS.UPDATE_CLIENT_DATA: {
            return {
                ...state,
                clientData: action.clientData,
            };
        }
        case APP_ACTIONS.UPDATE_APP_PREFERENCES: {
            return {
                ...state,
                appPreference: action.appPreference,
            };
        }
        default:
            return state;
    }
};
