/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState } from 'react';
import { getLocation } from 'src/utils/CommonUtils';
import PropTypes from 'prop-types';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { googleRevGeoAction, latLngSelectedAction } from 'src/actions/LocationActions';
import classNames from 'classnames';

const CurrentLocationContainer = ({
    onNoSupported,
    onError,
    shouldFetchAddress,
}) => {
    const dispatch = useDispatch();

    const [
        disable,
        setDisable,
    ] = useState(false);

    const [
        latLog,
        setLatLog,
    ] = useState(null);

    const {
        clientData: {
            google_geo_api_key,
        },
    } = useSelector((state) => state.appReducer, shallowEqual);

    const latLongSuccess = useCallback((position) => {
        const {
            latitude,
            longitude,
        } = position.coords;

        setLatLog({
            latitude,
            longitude,
        });

        setDisable(false);
    }, [
        setLatLog,
        setDisable,
    ]);

    const latLongError = useCallback(() => {
        setDisable(false);
        onError();
    }, [
        setDisable,
        onError,
    ]);

    const getLatLog = useCallback(() => {
        if (disable) {
            return;
        }

        setDisable(true);

        if (!getLocation(latLongSuccess, latLongError)) {
            onNoSupported();
        }
    }, [
        latLongSuccess,
        disable,
    ]);

    usePrevious(
        { latLog },
        (prevValues) => {
            // get location data from google
            if (shouldFetchAddress && latLog && prevValues.latLog !== latLog) {
                dispatch(googleRevGeoAction(latLog));
            }

            // update lat lng in redux store
            if (latLog && prevValues.latLog !== latLog) {
                dispatch(latLngSelectedAction({
                    currentLatLong: latLog,
                }));
            }
        },
    );

    if (!google_geo_api_key) {
        return null;
    }

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            className="input-group-prepend cursor-pointer"
            onClick={getLatLog}
        >
            <span
                className={classNames({
                    'input-group-text': true,
                    'bg-warning text-light': disable,
                    'bg-white text-dark': !disable,
                })}
            >
                <i className={classNames({
                    'fas fa-crosshairs ': true,
                    rotating: disable,
                })}
                />
            </span>
        </div>
    );
};

CurrentLocationContainer.propTypes = {
    onNoSupported: PropTypes.func,
    onError: PropTypes.func,
    shouldFetchAddress: PropTypes.bool,
};

CurrentLocationContainer.defaultProps = {
    onNoSupported: () => { },
    onError: () => { },
    shouldFetchAddress: false,
};

export default CurrentLocationContainer;
