import { all } from 'redux-saga/effects';
import AuthSaga from './AuthSaga';
import ProductSaga from './ProductSaga';
import UserSaga from './UserSaga';
import AppInitSaga from './AppInitSaga';
import StoreSaga from './StoreSaga';
import PaymentSaga from './PaymentSaga';
import EnumSaga from './EnumSaga';
import CartSaga from './CartSaga';
import OrderSaga from './OrderSaga';
import LocationSaga from './LocationSaga';
import SearchSaga from './SearchSaga';
import LayoutSaga from './LayoutSaga';
import ProductDetailsSaga from './ProductDetailsSaga';
import ProductReviewSaga from './ProductReviewSaga';

export default function* sagas() {
    yield all([
        ...AuthSaga,
        ...ProductSaga,
        ...UserSaga,
        ...AppInitSaga,
        ...StoreSaga,
        ...PaymentSaga,
        ...EnumSaga,
        ...CartSaga,
        ...OrderSaga,
        ...LocationSaga,
        ...SearchSaga,
        ...LayoutSaga,
        ...ProductDetailsSaga,
        ...ProductReviewSaga,
    ]);
}
