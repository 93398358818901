export const PRODUCT_DETAILS_ACTIONS = {
    PRODUCT_DETAILS_LIST_FETCH: 'PRODUCT_DETAILS_LIST_FETCH',
    PRODUCT_DETAILS_LIST_FETCH_SUCCESS: 'PRODUCT_DETAILS_LIST_FETCH_SUCCESS',
    PRODUCT_DETAILS_LIST_FETCH_ERROR: 'PRODUCT_DETAILS_LIST_FETCH_ERROR',

    PRODUCT_DETAILS_FETCH: 'PRODUCT_DETAILS_FETCH',
    PRODUCT_DETAILS_FETCH_SUCCESS: 'PRODUCT_DETAILS_FETCH_SUCCESS',
    PRODUCT_DETAILS_FETCH_ERROR: 'PRODUCT_DETAILS_FETCH_ERROR',
};

export const productDetailsListFetchAction = (payload) => ({
    type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH,
    ...payload,
});

export const productDetailsFetchAction = (payload) => ({
    type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH,
    ...payload,
});

export default {
    PRODUCT_DETAILS_ACTIONS,
    productDetailsListFetchAction,
    productDetailsFetchAction,
};
