import React from 'react';
import './LoaderComponent.scss';

const LoaderComponent = () => (
    <div className="loader" />
);

LoaderComponent.propTypes = {
};

export default LoaderComponent;
