/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import BreadCrumbComponent from 'src/components/common/BreadCrumbComponent';
import { useParams } from 'react-router-dom';
import { productDetailsListFetchAction } from 'src/actions/ProductDetailsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { gotoProductDetailPage } from 'src/utils/RoutingUtil';
import ColorSizeCollection from 'src/components/product/ColorSizeCollection';
import ProductMediaPanel from './components/ProductMediaPanel';
import ProductDetailsPanel from './components/ProductDetailsPanel';
import ProductDescriptionPanel from './components/ProductDescriptionPanel';

const ProductDetailContainer = () => {
    const dispatch = useDispatch();

    const {
        productCode,
        storeId,
    } = useParams();

    const productDetailsList = useSelector((state) => state.productDetailsReducer.productDetailsList[productCode], shallowEqual) || [];

    const {
        productMedia,
        productMetaData,
        productColorSizeCollection,
        productDetailsListFetchStatus,
    } = useSelector((state) => state.productDetailsReducer.additionalDetails[productCode] || {
        productMedia: [],
        productMetaData: [],
    }, shallowEqual);

    const reviewAggregation = useSelector((state) => state.productReviewReducer.aggregateRatingByProductCode[productCode] || {}, shallowEqual);

    const selectedProduct = useMemo(() => {
        // lowest price by default
        let result = productDetailsList.reduce((a, b) => {
            if (a.final_selling_price < b.final_selling_price) {
                return a;
            }

            return b;
        }, {});

        // if store id is provided, then filter the product
        if (storeId) {
            result = productDetailsList.find((p) => Number(p.store_id) === Number(storeId)) || result;
        }

        return result;
    }, [
        productDetailsList,
        productCode,
        storeId,
    ]);

    useEffect(() => {
        if (!productDetailsList.length && !productDetailsListFetchStatus) {
            dispatch(productDetailsListFetchAction({ productCode }));
        }
    }, [
        productCode,
        productDetailsList,
        productDetailsListFetchStatus,
    ]);

    const otherStoresProductPages = productDetailsList.map((p) => ({
        label: p.store_name,
        url: gotoProductDetailPage({
            storeId: p.store_id,
            productCode: p.product_code,
            productName: p.product_name,
        }),
        storeId: p.store_id,
        final_selling_price: p.final_selling_price,
    }));

    return (
        <section className="section-content padding-y">

            <div className="container">

                <BreadCrumbComponent />

                <div className="row">
                    <aside className="col-md-6">
                        <div className="card">
                            <ProductMediaPanel
                                mediaList={productMedia}
                            />
                        </div>
                    </aside>
                    <main className="col-md-6">
                        <ProductDetailsPanel
                            product={selectedProduct}
                            otherStoresProductPages={otherStoresProductPages}
                            reviewAggregation={reviewAggregation}
                        />
                    </main>
                </div>
                <section className="section-name padding-y bg">
                    <div>
                        <ColorSizeCollection
                            title="Available Sizes & Colors"
                            options={productColorSizeCollection}
                        />
                    </div>

                    <ProductDescriptionPanel
                        meta={productMetaData}
                        productCode={productCode}
                        description={selectedProduct.description}
                        productName={selectedProduct.product_name}
                    />
                </section>
            </div>
        </section>
    );
};

ProductDetailContainer.propTypes = {

};

export default ProductDetailContainer;
