/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import TextComponent from 'src/components/common/text-component/TextComponent';
import './PinCodeSelectionContainer.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TupleComponent from 'src/components/common/TupleComponent';
import { fetchPincodeInfoAction, fetchPincodeStoreProductCountAction } from 'src/actions/LocationActions';
import { PINCODE_SIZE } from 'src/constants/UiConstants';
import PropTypes from 'prop-types';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import CurrentLocationContainer from './CurrentLocationContainer';

const PinCodeSelectionContainer = ({
    onPincodeChange,
    onEnter,
    TextComponentClassName,
    TupleComponentClassName,
    inputRef,
    placeholder,
    defaultPincode,
}) => {
    // REDUX
    const dispatch = useDispatch();
    const {
        pincodes,
        currentAddress,

    } = useSelector((state) => state.locationReducer, shallowEqual);

    // if default value changed then update the local value
    const [
        pincode,
        setPincode,
    ] = useState(defaultPincode || '');

    // currentAddress is filled from google api
    usePrevious(
        { currentAddress },
        (prevValues) => {
            // update selected pincode | got from google api
            if (currentAddress && prevValues.currentAddress !== currentAddress && currentAddress.pincode) {
                setPincode(currentAddress.pincode);
                onPincodeChange(currentAddress.pincode);
            }
        },
    );

    const [
        pincodeAreas,
        setPincodeAreas,
    ] = useState([]);

    const onValueChange = useCallback((newPincode) => {
        setPincode(newPincode);
        onPincodeChange(newPincode);
    }, [
        onPincodeChange,
    ]);

    // empty the pincode area on invalid pin
    useEffect(() => {
        if (pincode && pincode.length !== PINCODE_SIZE && pincodeAreas.length) {
            setPincodeAreas([]);
        }
    }, [
        pincodeAreas,
        pincode,
    ]);

    // calculate pincode areas for listing
    useEffect(() => {
        const selectPincodeInfo = pincodes[pincode] && pincodes[pincode].info;
        if (Array.isArray(selectPincodeInfo)) {
            const newPincodeAreas = selectPincodeInfo.map((pin) => pin.place_name);
            setPincodeAreas(newPincodeAreas);
        }
    }, [
        pincodes,
        pincode,
    ]);

    // fetch pincode areas
    useEffect(() => {
        if (pincode && pincode.length === PINCODE_SIZE) {
            dispatch(fetchPincodeInfoAction({ pincode }));
            dispatch(fetchPincodeStoreProductCountAction({ pincode }));
        }
    }, [
        pincode,
        dispatch,
    ]);

    return (
        <>
            <div className="input-group mb-3">
                <CurrentLocationContainer
                    shouldFetchAddress
                />
                <TextComponent
                    ref={inputRef}
                    maxLength={PINCODE_SIZE}
                    className={TextComponentClassName}
                    placeholder={placeholder}
                    type="text"
                    onValueChange={onValueChange}
                    defaultValue={pincode}
                    onEnter={onEnter}
                />

            </div>

            <TupleComponent
                className={`${TupleComponentClassName} area-list`}
                list={pincodeAreas}
            />
        </>
    );
};

PinCodeSelectionContainer.propTypes = {
    onPincodeChange: PropTypes.any,
    inputRef: PropTypes.any,
    onEnter: PropTypes.func,
    TextComponentClassName: PropTypes.string,
    TupleComponentClassName: PropTypes.string,
    placeholder: PropTypes.string,
    defaultPincode: PropTypes.string,
};

PinCodeSelectionContainer.defaultProps = {
    onPincodeChange: () => { },
    inputRef: () => { },
    onEnter: () => { },
    TextComponentClassName: '',
    TupleComponentClassName: '',
    placeholder: 'PINCODE e.g. 110001',
    defaultPincode: '',
};

export default PinCodeSelectionContainer;
