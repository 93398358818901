import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const getAppPreferences = function () {
    const url = `${config.baseUrl}preferences`;
    return getRequest({
        url,
        params: {
            id: 1,
        },
    });
};

export default {
    getAppPreferences,
};
