import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { PAYMENT_ACTIONS } from 'src/actions/PaymentActions';
import { AUTH_ACTIONS } from 'src/actions/AuthActions';
import { get } from 'lodash-es';

const initialState = {
    paymentTypes: [],
    paymentTypesFetchStatus: null,
    selectedPaymentType: null,
};

const resetState = {
    paymentTypesFetchStatus: null,
};

export default (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH: {
            return newState;
        }
        case PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH_SUCCESS: {
            newState = {
                ...newState,
                paymentTypes: action.paymentTypes,
                paymentTypesFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case PAYMENT_ACTIONS.PAYMENT_TYPES_FETCH_ERROR: {
            newState = {
                ...newState,
                paymentTypesFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        case PAYMENT_ACTIONS.SELECTED_PAYMENT_TYPES_UPDATE: {
            newState = {
                ...newState,
                selectedPaymentType: action.selectedPaymentType,
            };
            break;
        }
        case AUTH_ACTIONS.LOGOUT: {
            return {
                ...newState,
                selectedPaymentType: get(newState, 'paymentTypes[0]'),
            };
        }
        default:
            return state;
    }

    return newState;
};
