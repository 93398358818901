export const PAYMENT_METHODS = {
    CASH: 1,
    DEBIT_CARD: 2,
    CREDIT_CARD: 3,
    DEMAND_DRAFT: 4,
    CHEQUE: 5,
    NEFT: 6,
    ONLINE: 7,
    COD: 8,
    PAYTM: 9,
    MOBIKWIK: 10,
    FREECHARGE: 11,
};

export const DELIVERY_TYPES = {
    TAKE_AWAY: 1,
    HOME_DELIVERY: 2,
};

export const USER_ADDRESS_MANDATORY = {
    [DELIVERY_TYPES.HOME_DELIVERY]: true,
};

export const ORDER_STATUS = {
    COMPLETED: 1,
    ON_DELIVERY: 2,
    IN_PROGRESS: 3,
    ON_HOLD: 4,
    CONFIRMATION_NEED: 5,
    CANCELED: 6,
};

export default {
    PAYMENT_METHODS,
    DELIVERY_TYPES,
    ORDER_STATUS,
    USER_ADDRESS_MANDATORY,
};
