import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const getAggregateReviewByProductCode = function ({
    productCode,
}) {
    const url = `${config.baseUrl}reviews/product/${productCode}/aggregated`;
    return getRequest({
        url,
    });
};

export const getReviewByProductCode = function ({
    productCode,
}) {
    const url = `${config.baseUrl}reviews/product/${productCode}`;
    return getRequest({
        url,
    });
};

export const getReviewByProductCodeAndUserId = function ({
    productCode,
    userId,
}) {
    const url = `${config.baseUrl}reviews`;
    return getRequest({
        url,
        params: {
            product_code: productCode,
            customer_id: userId,
        },
    });
};

export const upsertReviewAPI = function (data) {
    const url = `${config.baseUrl}reviews`;
    if (data.id) {
        // update
        return putRequest({
            url: `${url}/${data.id}`,
            data,
        });
    }
    // insert
    return postRequest({
        url,
        data,
    });
};

export default {
    getAggregateReviewByProductCode,
    getReviewByProductCode,
    upsertReviewAPI,
};
