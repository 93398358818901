/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import CartAddRemoveButtonContainer from 'src/containers/smart-components/CartAddRemoveButtonContainer';
import { ProductItemPropType } from 'src/constants/prop-types/ProductPropTypes';
import { productImageUrl } from 'src/utils/RoutingUtil';
import PropTypes from 'prop-types';
import LinkComponent from '../common/LinkComponent';
import RatingComponent from '../common/RatingComponent';

const ProductArticleComponent = ({
    item,
    productDetailUrl,
}) => {
    const {
        product_name,
        description,
        final_selling_price,
        product_code,
        // gst_tax,
        category,
        image_url,
        store_name,
        avg_rating,
    } = item;

    const imgUrl = productImageUrl({
        product: {
            image_url,
        },
    });

    const descriptionElement = description ? (
        <p>
            {description}
        </p>
    ) : null;

    return (
        <article className="card card-product-list">
            <div className="row no-gutters">
                <aside className="col-md-3">
                    <LinkComponent
                        className="img-wrap"
                        to={productDetailUrl}
                    >
                        {/* <span className="badge badge-danger"> NEW </span> */}
                        <img
                            alt={product_name}
                            src={imgUrl}
                        />
                    </LinkComponent>
                </aside>
                {/* col.// */}
                <div
                    className="col-md-6"
                >
                    <div className="info-main pb-0">
                        <LinkComponent
                            to={productDetailUrl}
                            className="h5 title"
                        >
                            {product_name}
                        </LinkComponent>
                        {/* <div className="rating-wrap mb-2">
                            <ul className="rating-stars">
                                <li
                                    style={{ width: '100%' }}
                                    className="stars-active"
                                >
                                    <i className="fa fa-star" />
                                    {' '}
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    {' '}
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                </li>
                                <li>
                                    <i className="fa fa-star" />
                                    {' '}
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    {' '}
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                </li>
                            </ul>
                            <div className="label-rating">9/10</div>
                        </div> */}
                        <RatingComponent
                            rating={avg_rating}
                        />
                        {/* rating-wrap.// */}
                        <p className="mb-3">
                            <span className="tag">
                                {' '}
                                <i className="fa fa-check" />
                                {' '}
                                Verified
                            </span>
                        </p>
                        <p className="mb-3">
                            Sold By
                            {' '}
                            <LinkComponent className="text-primary">
                                {store_name}
                            </LinkComponent>
                            {/* <span className="tag"> 5 Years </span>
                        <span className="tag"> 80 reviews </span>
                        <span className="tag"> Russia </span> */}
                        </p>
                        {descriptionElement}
                    </div>
                    {/* info-main.// */}
                </div>
                {/* col.// */}
                <aside className="col-md-3">
                    <div className="info-aside">
                        <div className="price-wrap">
                            <span className="h5 price">
                                <i className="fas fa-rupee-sign" />
                                &nbsp;
                                {final_selling_price}
                            </span>
                            <small className="text-muted">/per item</small>
                        </div>
                        {/* price-wrap.// */}
                        <small className="text-warning">
                            Product Code:
                            {' '}
                            {product_code}
                        </small>
                        <p className="text-muted mt-3">{category}</p>
                        <div className="mt-3">
                            {/* <LinkComponent

                            className="btn btn-outline-primary"
                        >
                            <i className="fa fa-envelope" />
                            {' '}
                            Contact supplier
                        </LinkComponent> */}
                            <CartAddRemoveButtonContainer item={item} />
                        </div>
                        {/* <label className="custom-control mt-3 custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                            />
                            <div className="custom-control-label">Add to compare</div>
                        </label> */}
                    </div>
                </aside>
            </div>
        </article>
    );
};

ProductArticleComponent.propTypes = {
    item: ProductItemPropType,
    productDetailUrl: PropTypes.string,
};

ProductArticleComponent.defaultProps = {
    item: {},
    productDetailUrl: '',
};

export default ProductArticleComponent;
