import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { appInitActions, setDefaultHeadersActions } from './actions/AppActions';
import MainContainer from './containers/main/MainContainer';
import PincodeWelcomeScreenContainer from './containers/pin-code-selection/PincodeWelcomeScreenContainer';
import PincodeRouteHOC from './hoc/PincodeRouteHOC';
import { gotoPinCodeSelection } from './utils/RoutingUtil';

export default () => {
    const dispatch = useDispatch();

    // SET DEFAULT HEADERS
    dispatch(setDefaultHeadersActions());

    useEffect(() => {
        dispatch(appInitActions());
    }, [
        dispatch,
    ]);

    return (
        <BrowserRouter>
            <>
                <Switch>
                    <Route
                        path={gotoPinCodeSelection()}
                        exact
                        component={PincodeWelcomeScreenContainer}
                    />
                    <PincodeRouteHOC
                        path="/"
                        component={MainContainer}
                    />
                </Switch>
            </>
        </BrowserRouter>
    );
};
