/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const getCustomerAddressAPI = function ({ id }) {
    if (!id) {
        return Promise.reject('Id not found');
    }

    const url = `${config.baseUrl}customer-addresses`;

    return getRequest({
        url,
        params: {
            customer_id: id,
        },
    });
};

export const newAddressAPI = function (addressDetails) {
    const url = `${config.baseUrl}customer-addresses`;
    return postRequest({
        url,
        data: addressDetails,
    });
};

export const addUpdateUserDetailsAPI = function ({ userDetails }) {
    const url = `${config.baseUrl}customer-master/${userDetails.id}`;
    if (userDetails.id) {
        return putRequest({
            url,
            data: userDetails,
        });
    }

    return postRequest({
        url,
        data: userDetails,
    });
};

export const profilePicUpload = function ({ file, customer_code }) {
    const fd = new FormData();
    fd.append('files', file);
    fd.append('ref_code', customer_code);
    fd.append('deletePreviousAll', 1);
    const url = `${config.baseUrl}assets/upload`;

    return postRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getProfilePicAPI = function ({ customer_code }) {
    const url = `${config.baseUrl}assets/${customer_code}`;

    return getRequest({
        url,
    });
};

export default {
    getCustomerAddressAPI,
    newAddressAPI,
    addUpdateUserDetailsAPI,
    profilePicUpload,
    getProfilePicAPI,
};
