export const USER_ACTIONS = {
    USER_ADDRESSES_FETCH: 'USER_ADDRESSES_FETCH',
    USER_ADDRESSES_FETCH_SUCCESS: 'USER_ADDRESSES_FETCH_SUCCESS',
    USER_ADDRESSES_FETCH_ERROR: 'USER_ADDRESSES_FETCH_ERROR',

    USER_ADD_ADDRESSES_REQUEST: 'USER_ADD_ADDRESSES_REQUEST',
    USER_ADD_ADDRESSES_REQUEST_SUCCESS: 'USER_ADD_ADDRESSES_REQUEST_SUCCESS',
    USER_ADD_ADDRESSES_REQUEST_ERROR: 'USER_ADD_ADDRESSES_REQUEST_ERROR',

    UPDATE_USER_DEFAULT_ADDRESS: 'UPDATE_USER_DEFAULT_ADDRESS',

    USER_ORDER_LIST_FETCH: 'USER_ORDER_LIST_FETCH',
    USER_ORDER_LIST_FETCH_SUCCESS: 'USER_ORDER_LIST_FETCH_SUCCESS',
    USER_ORDER_LIST_FETCH_ERROR: 'USER_ORDER_LIST_FETCH_ERROR',

    USER_DETAILS_UPDATE_REQUEST: 'USER_DETAILS_UPDATE_REQUEST',
    USER_DETAILS_UPDATE_REQUEST_SUCCESS: 'USER_DETAILS_UPDATE_REQUEST_SUCCESS',
    USER_DETAILS_UPDATE_REQUEST_ERROR: 'USER_DETAILS_UPDATE_REQUEST_ERROR',

    USER_PROFILE_PICTURE_UPDATE_REQUEST: 'USER_PROFILE_PICTURE_UPDATE_REQUEST',
    USER_PROFILE_PICTURE_UPDATE_REQUEST_SUCCESS: 'USER_PROFILE_PICTURE_UPDATE_REQUEST_SUCCESS',
    USER_PROFILE_PICTURE_UPDATE_REQUEST_ERROR: 'USER_PROFILE_PICTURE_UPDATE_REQUEST_ERROR',
};

export const userAddressesFetchAction = () => ({
    type: USER_ACTIONS.USER_ADDRESSES_FETCH,
});

export const userAddressAddAction = (payload) => ({
    type: USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST,
    ...payload,
});

export const updateUserDefaultAddressAction = (payload) => ({
    type: USER_ACTIONS.UPDATE_USER_DEFAULT_ADDRESS,
    ...payload,
});

export const userOrderFetchAction = (payload) => ({
    type: USER_ACTIONS.USER_ORDER_LIST_FETCH,
    ...payload,
});

export const updateUserDetailsAction = (payload = {}) => ({
    type: USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST,
    ...payload,
});

export const updateUserProfilePictureAction = (payload = {}) => ({
    type: USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST,
    ...payload,
});

export default {
    USER_ACTIONS,
    userAddressesFetchAction,
    userAddressAddAction,
    updateUserDefaultAddressAction,
    userOrderFetchAction,
    updateUserDetailsAction,
    updateUserProfilePictureAction,
};
