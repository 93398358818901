/* eslint-disable */
import {
    call, select, put,
} from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { fetchPageByPincodeErrorAction, fetchPageByPincodeSuccessAction, LAYOUT_ACTIONS } from 'src/actions/LayoutActions';
import { getPageDataByPincodeApi } from 'src/api/CurationApi';
import RoutingUtil from 'src/utils/RoutingUtil';
import { CLICK_TO_ACTION } from 'src/constants/LayoutConstants';

export function* fetchPageByPincodeSaga() {
    const {
        locationReducer: {
            selectedPincode,
        },
        layoutReducer: {
            pageByPincode,
        }
    } = yield select();


    try {
        // already has data
        if (pageByPincode.length > 0) {
            return;
        }

        const { data: { data } } = yield call(getPageDataByPincodeApi, { pincode: selectedPincode });

        const rails = {};

        data.forEach(({
            page_id,
            rail_id,
            title,
            tile_type,
            product_code,
            cta,
            img_url,
            tile_title,
            description,
            icon_class,
            product_name,
            tile_id,
            position
        }) => {

            // rail already exits
            if (rails[rail_id]) {
                rails[rail_id].tiles.push({
                    product_code,
                    cta,
                    img_url,
                    tile_title,
                    description,
                    icon_class,
                    product_name,
                    tile_id,
                })
            }
            // new rail
            else {
                const actionLink = cta === CLICK_TO_ACTION.GOTO_PRODUCT ? RoutingUtil.gotoProductDetailPage({
                    productCode: product_code,
                    productName: product_name
                }) : null

                rails[rail_id] = {
                    page_id,
                    rail_id,
                    title,
                    tile_type,
                    position,
                    tiles: [
                        {
                            product_code,
                            cta,
                            img_url,
                            tile_title,
                            description,
                            icon_class,
                            product_name,
                            tile_id,
                            to: actionLink
                        }
                    ]
                }
            }
        });

        // sort by position so the rails will appear on the right order on page
        let railIdsSortedByPosition = Object.keys(rails).sort(function (a, b) {
            return rails[a].position - rails[b].position;
        })

        yield put(fetchPageByPincodeSuccessAction({
            pageByPincode: rails,
            railIdsSortedByPosition
        }))
    }
    catch (error) {
        console.error('fetchPageByPincodeSaga', error);
        yield put(fetchPageByPincodeErrorAction())
    }
}

export default [
    takeFirstSagaUtil(LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE, fetchPageByPincodeSaga),
];
