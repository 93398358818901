/* eslint-disable*/
import React from 'react';
import LinkWrap from '../common/LinkWrap';

const BannerComponent = ({
    imgUrl,
    to,
}) => (
    <section className="section-intro padding-y-sm">
        <div className="container">
            <div className="intro-banner-wrap">
                <LinkWrap to={to}>
                    <img
                        src={imgUrl}
                        className="img-fluid rounded w-100"
                    />
                </LinkWrap>
            </div>
        </div>
    </section>
);

BannerComponent.propTypes = {

};

export default BannerComponent;
