import React from 'react';
import PropTypes from 'prop-types';
import { OrderItemPropType } from 'src/constants/prop-types/OrderPropTypes';
import { productImageUrl } from 'src/utils/RoutingUtil';

const ProductGridComponent = ({ items }) => {
    const itemElements = items.map(({
        product_code,
        product_name,
        category,
        units,
        product_price,
        total_product_price,
        image_url,
        size,
        color,
    }) => (
        <div
            className="col-md-6"
            key={product_code}
        >
            <figure className="itemside mb-3">
                <div className="aside">
                    <img
                        alt={product_name}
                        src={productImageUrl({
                            product: {
                                image_url,
                            },
                        })}
                        className="border img-xs"
                    />
                </div>
                <figcaption className="info">
                    <p>
                        {[
                            product_name,
                            category,
                        ].filter((item) => !!item).join(', ')}
                        <span
                            style={{
                                visibility: color ? 'visible' : 'hidden',
                            }}
                        >
                            {', '}
                            <strong>
                                {' '}
                                Size:
                                {' '}
                                {size}
                            </strong>
                        </span>
                        <input
                            type="color"
                            disabled
                            className="ml-1"
                            style={{
                                border: 'none',
                                visibility: color ? 'visible' : 'hidden',
                            }}
                            value={color}
                        />
                    </p>
                    <span>
                        {units}
                        x
                        {' '}
                        {' '}
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {product_price}

                        {' '}
                        {' '}
                        = Total:
                        {' '}
                        {' '}
                        <i className="fas fa-rupee-sign" />
                        {' '}
                        {total_product_price}
                    </span>
                </figcaption>
            </figure>
        </div>
    ));

    return (
        <div
            className="row"
        >
            {itemElements}
        </div>
    );
};

ProductGridComponent.propTypes = {
    items: PropTypes.arrayOf(OrderItemPropType),
};

ProductGridComponent.defaultProps = {
    items: [],
};

export default ProductGridComponent;
