/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';
import LinkComponent from 'src/components/common/LinkComponent';
import CartAddRemoveButtonContainer from 'src/containers/smart-components/CartAddRemoveButtonContainer';
import RatingComponent from 'src/components/common/RatingComponent';
import { gotoProductReviewPage } from 'src/utils/RoutingUtil';

const ProductDetailsPanel = ({
    product,
    otherStoresProductPages,
    reviewAggregation,
}) => {
    const storesOptions = otherStoresProductPages.length ? (
        <div className="dropdown mt-2">
            <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {otherStoresProductPages.length}
                {' '}
                Stores Selling
            </button>
            <div
                className="dropdown-menu mt-2"
                aria-labelledby="dropdownMenuButton"
            >
                {otherStoresProductPages.map(({
                    label, url, storeId, final_selling_price,
                }) => (
                    <LinkComponent
                        className={`dropdown-item ${product.store_id === storeId ? 'active' : ''}`}
                        to={url}
                        key={label}
                    >
                        {label}
                        {' '}
                        @
                        {' INR '}
                        {final_selling_price}
                    </LinkComponent>
                ))}
            </div>
        </div>
    ) : null;

    const dynamicInfo = [];
    if (product.size) {
        dynamicInfo.push(
            <dt
                key="size-label"
                className="col-sm-3"
            >
                Size
            </dt>,
            <dd
                key="size-value"
                className="col-sm-9"
            >
                {product.size}
            </dd>,
        );
    }

    if (product.color) {
        dynamicInfo.push(
            <dt
                key="color-label"
                className="col-sm-3"
            >
                Color
            </dt>,
            <dd
                key="color-value"
                className="col-sm-9"
            >
                <input
                    type="color"
                    disabled
                    value={product.color}
                />
            </dd>,
        );
    }
    return (
        <article className="product-info-aside">
            <div className="container-fluid">
                <h2 className="title mt-3">{product.product_name}</h2>
                <div className="my-3">
                    <LinkComponent to={gotoProductReviewPage({
                        productCode: product.product_code,
                        productName: product.product_name,
                    })}
                    >
                        <RatingComponent rating={reviewAggregation.avg_rating} />
                        <small className="label-rating text-muted hand-cursor">
                            {`${reviewAggregation.total || 'No'} `}
                            reviews
                        </small>
                        {' '}
                        <small className="label-rating text-success hand-cursor">
                            <i className="fa fa-clipboard-check" />
                            {` ${reviewAggregation.total_sold || 'Be the 1st to buy'} `}
                            orders
                        </small>
                    </LinkComponent>
                </div>

                <div className="mb-3">
                    <var className="price h4">
                        INR
                        {' '}
                        {product.final_selling_price}
                    </var>
                    {' '}
                    <span className="text-muted">incl. GST</span>
                </div>

                <div>
                    <CartAddRemoveButtonContainer item={product} />
                </div>

                {/* <p>{product.description}</p> */}
                <dl className="row mt-3">
                    {dynamicInfo}
                    <dt className="col-sm-3">Sold By</dt>
                    <dd className="col-sm-9">{product.store_name}</dd>
                    <dt className="col-sm-3">Category</dt>
                    <dd className="col-sm-9">{product.category || 'NA'}</dd>
                    <dt className="col-sm-3">Manufacturer</dt>
                    <dd className="col-sm-9">{product.manufacturer || 'NA'}</dd>
                    <dt className="col-sm-3">Article number</dt>
                    <dd className="col-sm-9">{product.article_number || 'NA'}</dd>
                    <dt className="col-sm-3">Guarantee</dt>
                    <dd className="col-sm-9">
                        {product.guarantee_days ? `${product.guarantee_days} Days` : 'NA'}
                    </dd>
                    <dt className="col-sm-3">Expire</dt>
                    <dd className="col-sm-9">
                        {product.expire_days ? `${product.expire_days} Days` : 'NA'}
                    </dd>
                    <dt className="col-sm-3">Availability</dt>
                    <dd className="col-sm-9">in Stock</dd>
                </dl>
            </div>

            <div className="">
                {storesOptions}
            </div>
        </article>
    );
};

ProductDetailsPanel.propTypes = {
    product: PropTypes.shape({
        description: PropTypes.string,
        final_selling_price: PropTypes.number,
        product_name: PropTypes.string,
        article_number: PropTypes.string,
        manufacturer: PropTypes.string,
        guarantee_days: PropTypes.number,
        expire_days: PropTypes.number,
        category: PropTypes.string,
        store_name: PropTypes.string,
        store_id: PropTypes.number,
        product_code: PropTypes.string,
        size: PropTypes.string,
        color: PropTypes.string,
    }),
    otherStoresProductPages: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
        storeId: PropTypes.number,
    })),
    reviewAggregation: PropTypes.shape({
        avg_rating: PropTypes.number,
        total: PropTypes.number,
        total_sold: PropTypes.number,
    }),
};

ProductDetailsPanel.defaultProps = {
    product: {
        description: 'N/A',
        final_selling_price: 0,
        product_name: 'N/A',
        article_number: 'N/A',
        manufacturer: 'N/A',
        guarantee_days: 0,
        expire_days: 0,
        category: 'N/A',
        store_name: 'N/A',
        store_id: 0,
        product_code: '',
        size: '',
        color: '',
    },
    otherStoresProductPages: [],
    reviewAggregation: {
        avg_rating: 0,
        total: 0,
        total_sold: 0,
    },
};

export default ProductDetailsPanel;
