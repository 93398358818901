/* eslint-disable */
import React from 'react';
import { getRandomDarkColor } from 'src/utils/CommonUtils';
import LinkComponent from '../common/LinkComponent';
import PropTypes from 'prop-types';
import classNames from 'classnames'

const TitleSubtitleTile = ({
    to,
    title,
    subTitle,
    addRandomColor
}) => {
    let style = {}
    let classes = []
    if (addRandomColor) {
        style = {
            backgroundColor: addRandomColor ? getRandomDarkColor() : '',
        }
        classes.push('text-light')
    }
    return (
        <LinkComponent
            to={to}
        >
            <figure
                className={classNames("box item-logo", classes)}
                style={style}
            >
                <h5>{title}</h5>
                <figcaption className="border-top pt-2">{subTitle}</figcaption>
            </figure>
        </LinkComponent>
    );
}

TitleSubtitleTile.propTypes = {
    addRandomColor: PropTypes.bool,
};

TitleSubtitleTile.defaultProps = {
    addRandomColor: false
};

export default React.memo(TitleSubtitleTile);
