/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState, useRef, forwardRef, memo, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { clamp, throttle } from 'lodash-es';
import './TextComponent.scss';
import { clickedOutside } from 'src/utils/CommonUtils';

const TextComponent = memo(forwardRef(({
    onChange,
    onValueChange,
    defaultValue,
    onEnter,
    suggestions,
    onSuggestionSelection,
    onKeyDown,
    ...rest
}, ref) => {
    const [
        value,
        setValue,
    ] = useState(defaultValue);

    const [
        activeIndex,
        setActiveIndex,
    ] = useState(-1);

    const [
        suggestionVisibility,
        setSuggestionVisibility,
    ] = useState(false);

    const [
        selectedSuggestion,
        setSelectedSuggestion,
    ] = useState(null);

    const anywhereClickedHandler = useCallback((event) => {
        if (event) {
            const result = clickedOutside(event, ref?.current);
            setSuggestionVisibility(result);
        }
        else {
            setSuggestionVisibility(false);
        }
    }, [
        ref,
    ]);

    // notify consumer that suggestion is selected
    useEffect(() => {
        if (selectedSuggestion) {
            onSuggestionSelection(selectedSuggestion);
        }
    }, [
        selectedSuggestion,
    ]);

    // if default value is changed then update
    useEffect(() => {
        setValue(defaultValue);
    }, [
        defaultValue,
    ]);

    // debounce fast entries
    const debouncedOnValueChange = useRef(throttle(onValueChange, 500));

    const valueChangeHandler = useCallback((inputText) => {
        setValue(inputText);

        if (debouncedOnValueChange && debouncedOnValueChange.current) {
            debouncedOnValueChange.current(inputText);
        }
    }, [
        debouncedOnValueChange,
    ]);

    const onChangeHandler = useCallback((e) => {
        const { target: { value: inputText } } = e;
        valueChangeHandler(inputText);

        if (onChange) {
            onChange(e);
        }
    }, [
        onChange,
        valueChangeHandler,
    ]);

    const onSuggestionSelectionHandler = useCallback((event) => {
        const inputText = event.target.getAttribute('data-value');
        // set value
        valueChangeHandler(inputText);
        // notify selection change
        onSuggestionSelection(inputText);
        // hide suggestions
        setSuggestionVisibility(false);
    }, [
        onSuggestionSelection,
        valueChangeHandler,
        setSuggestionVisibility,
    ]);

    const suggestionElements = suggestions.map((item, index) => (
        <div
            className={`item ${index === activeIndex ? 'active' : ''}`}
            key={item}
            data-value={item}
            onClick={onSuggestionSelectionHandler}
        >
            {item}
        </div>
    ));

    // if suggestions changed then active index reset
    useEffect(() => {
        setActiveIndex(-1);
    }, [
        suggestions,
        setActiveIndex,
    ]);

    const activeIndexChangeHandler = useCallback((event) => {
        if (onKeyDown) {
            onKeyDown(event);
        }

        // UP
        if (event.keyCode === 38) {
            const newIndex = clamp(activeIndex - 1, 0, suggestions.length - 1);
            setActiveIndex(newIndex);
        }
        // DOWN
        if (event.keyCode === 40) {
            const newIndex = clamp(activeIndex + 1, 0, suggestions.length - 1);
            setActiveIndex(newIndex);
        }
        // Enter
        if (event.keyCode === 13) {
            const inputText = suggestions[activeIndex];

            // if suggestion is selected then its a enter event itself
            if (inputText) {
                valueChangeHandler(inputText);
                setSelectedSuggestion(inputText);
            }
            else {
                onEnter(event);
            }
            // hide suggestion on enter
            setSuggestionVisibility(false);
            return;
        }

        // user searched a keyword and pressed enter setSuggestionVisibility(false) will hide suggestion however it should be shown on next search
        if (!suggestionVisibility) {
            setSuggestionVisibility(true);
        }
    }, [
        onEnter,
        suggestions,
        activeIndex,
        suggestionVisibility,
        valueChangeHandler,
        onKeyDown,
    ]);

    useEffect(() => {
        document.removeEventListener('click', anywhereClickedHandler);
        document.addEventListener('click', anywhereClickedHandler);
        return () => {
            document.removeEventListener('click', anywhereClickedHandler);
        };
    }, []);

    return (
        <>
            <input
                type="text"
                {...rest}
                value={value || ''}
                onChange={onChangeHandler}
                ref={ref}
                onKeyDown={activeIndexChangeHandler}
            />
            {suggestions ? (
                <div
                    className={`suggestions ${suggestionVisibility ? '' : 'd-none'}`}
                >
                    {suggestionElements}
                </div>
            ) : null}
        </>
    );
}));

TextComponent.propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onValueChange: PropTypes.func,
    defaultValue: PropTypes.string,
    suggestions: PropTypes.arrayOf(PropTypes.string),
    onSuggestionSelection: PropTypes.func,
    onKeyDown: PropTypes.func,
};

TextComponent.defaultProps = {
    onChange: () => { },
    onValueChange: () => { },
    defaultValue: '',
    onEnter: () => { },
    suggestions: [],
    onSuggestionSelection: () => { },
    onKeyDown: null,
};

export default TextComponent;
