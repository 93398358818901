import { ORDER_ACTIONS } from 'src/actions/OrderActions';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';
import { AUTH_ACTIONS } from 'src/actions/AuthActions';

const initialState = {
    orderDetails: {},
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_ACTIONS.ORDER_DETAILS_FETCH_SUCCESS: {
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.orderId]: action.orderDetails,
                },
            };
        }
        case AUTH_ACTIONS.LOGOUT: {
            return {
                orderDetails: {},
            };
        }
        default:
            return state;
    }
};

export default ReducerSyncWithStorage(orderReducer, {
    keysToBeSync: [
        'orderDetails',
    ],
    initialState,
    storageKeyName: 'orderReducer',
    storage: localStorage,
});
