/* eslint-disable camelcase */
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { addItemAction, removeItemAction } from 'src/actions/CartActions';
import { get } from 'lodash-es';
import { BUTTON_TYPES } from 'src/constants/UiConstants';
import CartItemToggleButtonComponent from 'src/components/common/CartItemToggleButtonComponent';
import CartAddRemoveButton from 'src/components/common/CartAddRemoveButton';
import { ProductItemPropType } from 'src/constants/prop-types/ProductPropTypes';

const CartAddRemoveButtonContainer = ({
    item,
}) => {
    // REDUX
    const dispatch = useDispatch();
    const cartItem = useSelector((state) => get(state, `cartReducer.itemsInCart.[${item.id}-${item.store_id}]`), shallowEqual);

    const itemCountInCart = cartItem ? cartItem.count : 0;

    const onItemAddHandler = () => {
        dispatch(addItemAction({
            newItem: item,
        }));
    };
    const onItemRemoveHandler = () => {
        dispatch(removeItemAction({
            newItem: item,
        }));
    };

    const buttonType = item.product_type_id === 2 ? BUTTON_TYPES.ADD_CART_TOGGLE : BUTTON_TYPES.ADD_CART_PLUS_MINUS;

    if (buttonType === BUTTON_TYPES.ADD_CART_PLUS_MINUS) {
        return (
            <CartAddRemoveButton
                onPlus={onItemAddHandler}
                onMinus={onItemRemoveHandler}
                value={itemCountInCart}
                title="Add To Cart"
            />
        );
    }
    return (
        <CartItemToggleButtonComponent
            className="btn btn-light"
            title="Book"
            toggledTitle="Remove"
            onPlus={onItemAddHandler}
            onMinus={onItemRemoveHandler}
            value={itemCountInCart}
        />
    );
};

CartAddRemoveButtonContainer.propTypes = {
    item: ProductItemPropType,
};

CartAddRemoveButtonContainer.defaultProps = {
    item: {},
};

export default CartAddRemoveButtonContainer;
