/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import RangeComponent from 'src/components/product/RangeComponent';
import ItemsFilterComponent from 'src/components/product/ItemsFilterComponent';
import ProductStackHeader from 'src/components/product/ProductStackHeader';
import PaginationComponent from 'src/components/common/PaginationComponent';
import BreadCrumbComponent from 'src/components/common/BreadCrumbComponent';
import { productFetchAction } from 'src/actions/ProductActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { QUERY_PARAMS } from 'src/constants/RoutingConstants';
import isMobile from 'is-mobile';
import { gotoProductDetailPage, gotoProductSearch } from 'src/utils/RoutingUtil';
import { isInViewport } from 'src/utils/DomUtils';
import ProductArticleComponent from 'src/components/product/ProductArticleComponent';
import { ProductItemPropType } from 'src/constants/prop-types/ProductPropTypes';
import ReactRouterPropTypes from 'react-router-prop-types';
import { addItemAction, removeItemAction } from 'src/actions/CartActions';

const PAGE_LENGTH = 5;

class ProductListingContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filteredCategories: {},

            filteredStores: {},

            filterPriceRange: null,

            page: 0,
        };

        this.mainContentHeaderRef = React.createRef();
    }

    componentDidMount() {
        this.fetchProducts();
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;

        const {
            filteredStores,
            filteredCategories,
            filterPriceRange,
            page,
        } = this.state;

        if (
            // filter has changed, refilter the products
            filteredCategories !== prevState.filteredCategories
            // filter if price range changed
            || filterPriceRange !== prevState.filterPriceRange
            // location change
            || location !== prevProps.location
            // filter store selection change
            || filteredStores !== prevState.filteredStores
            // page change
            || page !== prevState.page
        ) {
            this.fetchProducts();
        }
    }

    fetchProducts = () => {
        const { productFetchActionDispatch } = this.props;
        const {
            page,
            filteredCategories,
            filteredStores,
            filterPriceRange,
        } = this.state;

        const categories = Object.keys(filteredCategories).join(',');
        const storeNames = Object.keys(filteredStores).join(',');

        this.gotoViewPort();

        productFetchActionDispatch({
            page,
            rows: PAGE_LENGTH,
            storeNames,
            categories,
            price: filterPriceRange,
            searchQuery: this.getSearchQuery(),
        });
    }

    getSearchQuery = () => {
        const { location: { search } } = this.props;
        const searchQuery = new URLSearchParams(search).get(QUERY_PARAMS.SEARCH_QUERY);
        if (searchQuery) {
            return searchQuery;
        }
        return '';
    }

    gotoViewPort = () => {
        if (this.mainContentHeaderRef.current && !isInViewport(this.mainContentHeaderRef.current)) {
            this.mainContentHeaderRef.current.scrollIntoView();
        }
    }

    onItemAddHandler = (item) => {
        const { addItemActionDispatch } = this.props;
        addItemActionDispatch({
            newItem: item,
        });
    }

    onItemRemoveHandler = (item) => {
        const { removeItemActionDispatch } = this.props;
        removeItemActionDispatch({
            newItem: item,
        });
    }

    getItemCountInCart = (itemId) => {
        const { itemsInCart } = this.props;
        const item = itemsInCart[itemId];
        return (item && item.count) || 0;
    }

    getProductCards = () => {
        const { products } = this.props;

        return products.map((item) => (
            <ProductArticleComponent
                key={item.id + item.store_name}
                item={item}
                productDetailUrl={gotoProductDetailPage({
                    productCode: item.product_code,
                    storeId: item.store_id,
                    productName: item.product_name,
                })}
            />
        ));
    }

    onPageChangeHandler = ({
        pageIndex = 0,
    } = {}) => {
        this.setState({
            page: pageIndex,
        });
    };

    onCategorySelectionChange = ({
        items,
    }) => {
        this.setState({
            filteredCategories: { ...items },
        });
    }

    onStoreSelectionChange = ({
        items,
    }) => {
        this.setState({
            filteredStores: { ...items },
        });
    }

    onPriceRangeChange = (priceRange) => {
        this.setState({
            filterPriceRange: priceRange,
        });
    }

    onClearFilterHandler = () => {
        this.setState({
            filteredStores: {},
            filteredCategories: {},
            filterPriceRange: null,
            page: 0,
        });

        if (this.getSearchQuery()) {
            const { history } = this.props;
            const url = gotoProductSearch();
            history.push(url);
        }
    }

    render() {
        const {
            filteredCategories,

            filterPriceRange,

            filteredStores,

            page,
        } = this.state;

        const {
            availableCategories,
            availableStores,
            totalProducts,
        } = this.props;

        const searchQuery = this.getSearchQuery();

        return (
            <section className="section-content padding-y">
                <div className="container">
                    <BreadCrumbComponent />
                    <div className="row">
                        <aside className="col-md-3">
                            {/* filter-group  category */}
                            <ItemsFilterComponent
                                title="Stores"
                                items={availableStores}
                                onFilterApply={this.onStoreSelectionChange}
                                isDefaultCollapsed={isMobile()}
                                filteredItems={filteredStores}
                            />

                            {/* filter-group  category */}
                            <ItemsFilterComponent
                                title="Categories"
                                items={availableCategories}
                                onFilterApply={this.onCategorySelectionChange}
                                isDefaultCollapsed={isMobile()}
                                filteredItems={filteredCategories}
                            />
                            {/* filter-group price */}
                            <RangeComponent
                                title="Price Range"
                                min={50}
                                max={10000}
                                defaultValue={5000}
                                onChange={this.onPriceRangeChange}
                                isDefaultCollapsed={isMobile()}
                            />
                            {' '}
                        </aside>
                        {/* col.// */}
                        <main className="col-md-9">
                            <ProductStackHeader
                                searchQuery={searchQuery}
                                total={totalProducts}
                                onClearFilter={this.onClearFilterHandler}
                                clearButtonVisibility={
                                    Boolean(Object.keys(filteredCategories).length)
                                    || Boolean(Object.keys(filteredStores).length)
                                    || Boolean(filterPriceRange)
                                    || Boolean(searchQuery)
                                }
                                clearButtonLabel="Clear Search Filters"
                                ref={this.mainContentHeaderRef}
                            />
                            {this.getProductCards()}
                            <PaginationComponent
                                recordsCount={totalProducts}
                                recordsPerPage={PAGE_LENGTH}
                                currentPage={page}
                                onPageChange={this.onPageChangeHandler}
                            />
                        </main>
                        {/* col.// */}
                    </div>
                </div>
                {/* container .//  */}
            </section>
        );
    }
}

ProductListingContainer.propTypes = {
    productFetchActionDispatch: PropTypes.func.isRequired,
    addItemActionDispatch: PropTypes.func.isRequired,
    removeItemActionDispatch: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    itemsInCart: PropTypes.any.isRequired,
    products: PropTypes.arrayOf(ProductItemPropType),
    availableCategories: PropTypes.object,
    availableStores: PropTypes.object,
    totalProducts: PropTypes.number,
};

ProductListingContainer.defaultProps = {
    products: [],
    availableCategories: {},
    totalProducts: 0,
    availableStores: {},
};

function mapStateToProps(state) {
    const {
        productReducer: {
            products,
            categories,
            stores,
            totalProducts,
        },
        cartReducer: {
            itemsInCart,
        },
    } = state;
    return {
        availableCategories: categories,
        availableStores: stores,
        products,
        itemsInCart,
        totalProducts,
    };
}

export default connect(
    mapStateToProps,
    {
        productFetchActionDispatch: productFetchAction,
        addItemActionDispatch: addItemAction,
        removeItemActionDispatch: removeItemAction,
    },
)(withRouter(ProductListingContainer));
