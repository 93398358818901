import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from 'src/sagas';
// import { localStorageMiddleware, reHydrateStore } from 'src/sagas/LocalStorageMiddlewareSaga';
import reducers from './reducers';

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    let middleware = applyMiddleware(
        sagaMiddleware,
        // localStorageMiddleware, // add app state in local storage
    );

    if (process.env.NODE_ENV !== 'production') {
        const { devToolsExtension } = window;
        if (typeof devToolsExtension === 'function') {
            middleware = compose(middleware, devToolsExtension());
        }
    }

    const store = createStore(
        reducers,
        // reHydrateStore(), // re hydrate store from local storage
        middleware,
    );

    sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(require('./reducers').default);
        });
    }

    return store;
}
