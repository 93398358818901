export const LOCATION_ACTIONS = {
    PINCODE_INFO_FETCH: 'PINCODE_INFO_FETCH',
    PINCODE_INFO_FETCH_SUCCESS: 'PINCODE_INFO_FETCH_SUCCESS',
    PINCODE_INFO_FETCH_ERROR: 'PINCODE_INFO_FETCH_ERROR',

    PINCODE_STORE_PRODUCT_COUNT_FETCH: 'PINCODE_STORE_PRODUCT_COUNT_FETCH',
    PINCODE_STORE_PRODUCT_COUNT_FETCH_SUCCESS: 'PINCODE_STORE_PRODUCT_COUNT_FETCH_SUCCESS',
    PINCODE_STORE_PRODUCT_COUNT_FETCH_ERROR: 'PINCODE_STORE_PRODUCT_COUNT_FETCH_ERROR',

    STATES_LIST_FETCH: 'STATES_LIST_FETCH',
    STATES_LIST_FETCH_SUCCESS: 'STATES_LIST_FETCH_SUCCESS',
    STATES_LIST_FETCH_ERROR: 'STATES_LIST_FETCH_ERROR',

    GOOGLE_REV_GEO_FETCH: 'GOOGLE_REV_GEO_FETCH',
    GOOGLE_REV_GEO_FETCH_SUCCESS: 'GOOGLE_REV_GEO_FETCH_SUCCESS',
    GOOGLE_REV_GEO_FETCH_ERROR: 'GOOGLE_REV_GEO_FETCH_ERROR',

    PINCODE_SELECTED: 'PINCODE_SELECTED',

    LAT_LNG_SELECTED: 'LAT_LNG_SELECTED',

    PINCODE_CHANGED: 'PINCODE_CHANGED',
};

export const fetchStateListAction = () => ({
    type: LOCATION_ACTIONS.STATES_LIST_FETCH,
});

export const fetchStateListSuccessAction = (payload) => ({
    type: LOCATION_ACTIONS.STATES_LIST_FETCH_SUCCESS,
    ...payload,
});

export const pincodeChangedAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_CHANGED,
    ...payload,
});

export const fetchPincodeInfoAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_INFO_FETCH,
    ...payload,
});

export const fetchPincodeInfoSuccessAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_INFO_FETCH_SUCCESS,
    ...payload,
});

export const fetchPincodeInfoErrorAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_INFO_FETCH_ERROR,
    ...payload,
});

export const fetchPincodeStoreProductCountAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH,
    ...payload,
});

export const fetchPincodeStoreProductCountSuccessAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH_SUCCESS,
    ...payload,
});

export const fetchPincodeStoreProductCountErrorAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH_ERROR,
    ...payload,
});

export const googleRevGeoAction = (payload) => ({
    type: LOCATION_ACTIONS.GOOGLE_REV_GEO_FETCH,
    ...payload,
});

export const googleRevGeoSuccessAction = (payload) => ({
    type: LOCATION_ACTIONS.GOOGLE_REV_GEO_FETCH_SUCCESS,
    ...payload,
});

export const googleRevGeoErrorAction = (payload) => ({
    type: LOCATION_ACTIONS.GOOGLE_REV_GEO_FETCH_ERROR,
    ...payload,
});

export const pincodeSelectedAction = (payload) => ({
    type: LOCATION_ACTIONS.PINCODE_SELECTED,
    ...payload,
});

export const latLngSelectedAction = (payload) => ({
    type: LOCATION_ACTIONS.LAT_LNG_SELECTED,
    ...payload,
});

export default {
    fetchPincodeInfoAction,
    fetchPincodeInfoSuccessAction,
    fetchPincodeInfoErrorAction,
    pincodeSelectedAction,
    fetchPincodeStoreProductCountAction,
    fetchPincodeStoreProductCountSuccessAction,
    fetchPincodeStoreProductCountErrorAction,
    googleRevGeoSuccessAction,
    googleRevGeoErrorAction,
    latLngSelectedAction,
    fetchStateListAction,
    fetchStateListSuccessAction,
};
