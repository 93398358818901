import React from 'react';

const NoCouponComponent = () => (
    <section className="section-content padding-y">
        <div className="container-fluid mt-100">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body cart">
                            <div className="col-sm-12 empty-cart-cls text-center">
                                {' '}
                                <i
                                    className="fas fa-gifts fa-10x mb-5"
                                    style={{ color: 'var(--theme-major-color)' }}
                                />
                                <h3><strong className="text-muted">Oops! Not Available</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default NoCouponComponent;
