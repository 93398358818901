import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const getStoresByPincodeAPI = function ({ pincode }) {
    const url = `${config.baseUrl}stores-by-pincode`;
    return getRequest({
        url,
        params: {
            pincode,
        },
    });
};

export default {
    getStoresByPincodeAPI,
};
