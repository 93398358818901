import React from 'react';
import LinkComponent from './LinkComponent';

const BreadCrumbComponent = () => (
    <div className="card mb-3">
        <div className="card-body">
            <ol className="breadcrumb float-left">
                <li className="breadcrumb-item"><LinkComponent to="/">Home</LinkComponent></li>
                <li className="breadcrumb-item active"><LinkComponent>Products</LinkComponent></li>
                {/* <li className="breadcrumb-item active">Item details</li> */}
            </ol>
        </div>
        {/* card-body .// */}
    </div>
);

export default BreadCrumbComponent;
