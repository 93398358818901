import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { STORE_ACTIONS } from 'src/actions/StoreActions';

const initialState = {
    stores: [],
    storesFetchStatus: null,
};

const resetState = {
    storesFetchStatus: null,
};

export default (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case STORE_ACTIONS.STORE_FETCH: {
            return newState;
        }
        case STORE_ACTIONS.STORE_FETCH_SUCCESS: {
            newState = {
                ...newState,
                stores: action.stores,
                storesFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case STORE_ACTIONS.STORE_FETCH_ERROR: {
            newState = {
                ...newState,
                storesFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        default:
            return state;
    }

    return newState;
};
