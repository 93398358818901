export const SEARCH_ACTIONS = {
    SEARCH_SUGGESTIONS_FETCH: 'SEARCH_SUGGESTIONS_FETCH',
    SEARCH_SUGGESTIONS_FETCH_SUCCESS: 'SEARCH_SUGGESTIONS_FETCH_SUCCESS',
    SEARCH_SUGGESTIONS_FETCH_ERROR: 'SEARCH_SUGGESTIONS_FETCH_ERROR',
};

export const searchSuggestionFetchAction = (payload) => ({
    type: SEARCH_ACTIONS.SEARCH_SUGGESTIONS_FETCH,
    ...payload,
});

export default {
    SEARCH_ACTIONS,
    searchSuggestionFetchAction,
};
