import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from 'src/components/common/LinkComponent';
import RatingComponent from 'src/components/common/RatingComponent';
import moment from 'moment';
import { DEFAULT_USER_DP } from 'src/constants/UiConstants';

const ReviewList = ({
    reviews,
}) => {
    const heading = reviews.length > 1 ? 'All Ratings and Reviews' : 'Be the first to review';

    const reviewElements = reviews.map((review) => (
        <div
            className="reviews-members pt-4 pb-4"
            key={review.id}
        >
            <div className="media">
                <LinkComponent>
                    <img
                        alt="user"
                        src={review.user_dp_url || DEFAULT_USER_DP}
                        className="mr-3 rounded-pill"
                    />
                </LinkComponent>
                <div className="media-body">
                    <div className="reviews-members-header">
                        <span className="float-right">
                            <RatingComponent rating={review.rating} />
                        </span>
                        <h6 className="mb-1">
                            <LinkComponent
                                className="text-black"
                            >
                                {`${review.first_name || 'Anonymous'} ${review.last_name || ''}`}
                            </LinkComponent>
                        </h6>
                        <p className="text-secondary">{moment(review.updated_on).format('ddd, DD-MM-YYYY hh:mm a')}</p>
                    </div>
                    <div className="reviews-members-body">
                        <strong>{review.title}</strong>
                        <p>{review.description}</p>
                    </div>
                    {/*
                                                <div className="reviews-members-footer">
                                                    <a
                                                        className="total-like"
                                                        href="#"
                                                    >
                                                        <i className="icofont-thumbs-up" />
                                                        {' '}
                                                        856M
                                                    </a>
                                                    {' '}
                                                    <a
                                                        className="total-like"
                                                        href="#"
                                                    >
                                                        <i className="icofont-thumbs-down" />
                                                        {' '}
                                                        158K
                                                    </a>
                                                    <span
                                                        className="total-like-user-main ml-2"
                                                        dir="rtl"
                                                    >
                                                        <a
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title
                                                            href="#"
                                                            data-original-title="Gurdeep Osahan"
                                                        >
                                                            <img
                                                                alt="Generic placeholder image"
                                                                src="http://bootdey.com/img/Content/avatar/avatar5.png"
                                                                className="total-like-user rounded-pill"
                                                            />
                                                        </a>
                                                        <a
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title
                                                            href="#"
                                                            data-original-title="Gurdeep Singh"
                                                        >
                                                            <img
                                                                alt="Generic placeholder image"
                                                                src="http://bootdey.com/img/Content/avatar/avatar2.png"
                                                                className="total-like-user rounded-pill"
                                                            />
                                                        </a>
                                                        <a
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title
                                                            href="#"
                                                            data-original-title="Askbootstrap"
                                                        >
                                                            <img
                                                                alt="Generic placeholder image"
                                                                src="http://bootdey.com/img/Content/avatar/avatar3.png"
                                                                className="total-like-user rounded-pill"
                                                            />
                                                        </a>
                                                        <a
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title
                                                            href="#"
                                                            data-original-title="Osahan"
                                                        >
                                                            <img
                                                                alt="Generic placeholder image"
                                                                src="http://bootdey.com/img/Content/avatar/avatar4.png"
                                                                className="total-like-user rounded-pill"
                                                            />
                                                        </a>
                                                    </span>
                                                </div>
                                                     */}
                </div>
            </div>
        </div>
    ));
    return (
        <div className="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
            <LinkComponent
                className="btn btn-outline-primary btn-sm float-right"
            >
                Latest Rated
            </LinkComponent>
            <h5 className="mb-1">{heading}</h5>
            {reviewElements}
            {/* <hr /> */}
            {/* <hr /> */}

            {/* LAZY LOAD */}
            {/* <a
                className="text-center w-100 d-block mt-4 font-weight-bold"
                href="#"
            >
                See All Reviews
            </a> */}
        </div>
    );
};

ReviewList.propTypes = {
    reviews: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        rating: PropTypes.number,
    })),
};

ReviewList.defaultProps = {
    reviews: [],
};

export default ReviewList;
