export const PRODUCT_ACTIONS = {
    PRODUCT_FETCH: 'PRODUCT_FETCH',
    PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
    PRODUCT_FETCH_ERROR: 'PRODUCT_FETCH_ERROR',

    PRODUCT_RESET: 'PRODUCT_RESET',
};

export const productFetchAction = (payload) => ({
    type: PRODUCT_ACTIONS.PRODUCT_FETCH,
    ...payload,
});

export const productReset = (payload) => ({
    type: PRODUCT_ACTIONS.PRODUCT_RESET,
    ...payload,
});

export default {
    PRODUCT_ACTIONS,
    productFetchAction,
    productReset,
};
