/* eslint-disable */
export default function SlickPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                left: '20px',
                zIndex:'999'
            }}
            onClick={onClick}
        />
    );
}
