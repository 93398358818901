/* eslint-disable*/
import React from 'react';
import LinkWrap from '../common/LinkWrap';

const ImgTile = ({
    imgUrl,
    alt,
    to,
}) => (
    <LinkWrap to={to}>
        <img
            className="d-block w-100"
            src={imgUrl}
            alt={alt}
        />
    </LinkWrap>
);

export default ImgTile;
