import React, { useRef } from 'react';
import NavigationComponent from 'src/components/navigation/NavigationComponent';
import config from 'src/config';
import { useQuery } from 'src/utils/ReactHooksUtil';
import {
    gotoCart, gotoProductSearch, gotoLogin, gotoUserProfilePage, gotoCouponPage, gotoPinCodeSelection, gotoHomePage,
} from 'src/utils/RoutingUtil';
import { useHistory } from 'react-router';
import { QUERY_PARAMS } from 'src/constants/RoutingConstants';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { searchSuggestionFetchAction } from 'src/actions/SearchActions';
import { debounce } from 'lodash-es';

const {
    brandDetails: {
        logo,
        name,
    },
} = config;

const NavigationContainer = () => {
    // REDUX
    const {
        itemsInCart,
        couponList,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    const {
        searchSuggestions,
    } = useSelector((state) => state.searchReducer, shallowEqual);

    const {
        isLoggedIn,
    } = useSelector((state) => state.authReducer, shallowEqual);

    const {
        selectedPincode,
    } = useSelector((state) => state.locationReducer, shallowEqual);

    const {
        userDetails: {
            id: userId,
            first_name,
            dp,
        },
    } = useSelector((state) => state.userReducer, shallowEqual);

    const dispatch = useDispatch();

    // HOOKS
    const history = useHistory();
    const queryParams = useQuery();
    const searchQuery = queryParams.get(QUERY_PARAMS.SEARCH_QUERY);

    const onSearchQuerySelectionHandler = (q) => {
        if (!q) {
            return;
        }

        const url = gotoProductSearch({
            queryParams: {
                ...queryParams,
                q,
            },
        });
        history.push(url);
    };

    // throttle suggestion api call
    const requestSearchSuggestions = useRef(debounce((query) => dispatch(searchSuggestionFetchAction({ query })), 250));

    const profileUrl = isLoggedIn ? gotoUserProfilePage() : gotoLogin();
    const profileIconText = isLoggedIn ? first_name || 'User' : 'Sign In';
    const profilePictureUrl = userId ? dp : '';

    return (
        <NavigationComponent
            logoImage={logo}
            brandName={name}
            searchInputDefaultText={searchQuery}
            onSearchQuerySelection={onSearchQuerySelectionHandler}
            numberOfItemsInCart={itemsInCart && itemsInCart.length}
            cartUrl={gotoCart()}
            homeUrl={gotoHomePage()}
            profileUrl={profileUrl}
            profileIconText={profileIconText}
            profilePictureUrl={profilePictureUrl}
            productsUrl={gotoProductSearch()}
            couponPageUrl={gotoCouponPage()}
            couponCount={couponList.length}
            locationTitle={selectedPincode}
            locationChangeUrl={gotoPinCodeSelection()}
            requestSearchSuggestions={requestSearchSuggestions.current}
            searchSuggestions={searchSuggestions}
        />
    );
};

export default NavigationContainer;
