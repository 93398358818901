import React, { useEffect, memo } from 'react';
import ProductGridComponent from 'src/components/common/ProductGridComponent';
import { OrderPropType } from 'src/constants/prop-types/OrderPropTypes';
import { useDispatch, useSelector } from 'react-redux';
import { orderDetailsFetchAction } from 'src/actions/OrderActions';
import moment from 'moment';
import LinkComponent from 'src/components/common/LinkComponent';
import StoreAddressContainer from '../smart-components/StoreAddressContainer';

const OrderDetailContainer = memo(({ order: { id: orderId } }) => {
    const dispatch = useDispatch();

    const {
        order,
        details: orderDetails,
    } = useSelector((state) => state.orderReducer.orderDetails[orderId]) || {
        order: {},
        details: [],
    };

    useEffect(() => {
        dispatch(orderDetailsFetchAction({ orderId }));
    }, [
        orderId,
        dispatch,
    ]);

    const totalItemCount = orderDetails && orderDetails.reduce((total, { units }) => total + units, 0);
    const purchaseDate = moment(order.created_on).format('ddd, DD-MM-YYYY hh:mm a');
    const deliveryDateTime = moment(order.pickup_date).format('ddd, DD-MM-YYYY hh:mm a');

    const deliveryToElement = order.customer_name?.trim() ? (
        <div className="col-md-8">
            <h6 className="text-muted">Delivery to</h6>
            <p className="text-capitalize">
                {order.customer_name}
                {' '}
                <br />
                <strong>Phone: </strong>
                {' '}
                {order.phone}
                {' '}
                <br />
                <strong>Location:</strong>
                {' '}
                {[
                    order.delievery_address,
                    order.pincode,
                ].join(', ')}
            </p>
        </div>
    ) : null;

    return (
        <div className="card mb-3">
            <header className="card-header">
                <LinkComponent
                    className="float-right d-none"
                >
                    {' '}
                    <i className="fa fa-print" />
                    {' '}
                    Print
                </LinkComponent>
                <strong className="d-inline-block mr-3">
                    Order ID:
                    {' '}
                    {[
                        order.bill_no,
                        order.parent_bill_no,
                    ].filter((i) => !!i).join(' / ')}
                </strong>
                <br className="d-md-none" />
                <span className="text-capitalize">
                    <strong>Order Status:</strong>
                    {' '}
                    {order.order_status_name}
                </span>
            </header>
            <article className="card-body">
                <ProductGridComponent
                    items={orderDetails}
                />

                <div className="row border-bottom pb-3">
                    {deliveryToElement}
                    <div className="col-md-4 col-lg-5">
                        <h6 className="text-muted">Delivery at</h6>
                        <div className="text-capitalize">
                            <strong>Order Date:</strong>
                            {' '}
                            {purchaseDate}
                            <br />

                            <strong>Delivery Date:</strong>
                            {' '}
                            {deliveryDateTime}
                            {' '}
                            <br />

                            <div>
                                <strong>
                                    Remarks:
                                </strong>
                                {' '}
                                {order.remarks || 'No Remarks'}
                            </div>

                            <strong>Delivery Type:</strong>
                            {' '}
                            {order.delivery_name}
                            <br />

                            <strong>Store:</strong>
                            {' '}
                            {order.store_name}
                            <br />

                            <div className="mt-1">
                                <StoreAddressContainer storeId={order.store_id} />
                            </div>
                            {' '}
                        </div>
                    </div>
                </div>

                <dl className="row mt-3">
                    <dt className="col-sm-10">
                        Subtotal:
                        {' '}
                        <span className="float-right text-muted">
                            {totalItemCount}
                            {' '}
                            items
                        </span>
                    </dt>
                    <dd className="col-sm-2 text-right">
                        <strong>
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.total_purchase_price}
                        </strong>

                    </dd>

                    {/* COUPON */}
                    <dt className={`col-sm-10 text-warning ${!order.coupon_code && 'd-none'}`}>
                        Discount:
                        {' '}
                        <span className="float-right text-muted">
                            Coupon Applied
                            {order.coupon_code}
                        </span>
                    </dt>
                    <dd className={`col-sm-2 text-danger text-right text-warning ${!order.coupon_code && 'd-none'}`}>
                        <strong>
                            - &nbsp;
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.discount_rs}
                        </strong>
                    </dd>

                    {/* DELIVERY */}
                    <dt className="col-sm-10">
                        Delivery charge:
                        {' '}
                        {/* <span className="float-right text-muted">Express Delivery</span> */}
                    </dt>
                    <dd className="col-sm-2 text-right">
                        <strong>
                            + &nbsp;
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.delievery_charges}
                        </strong>
                    </dd>

                    {/* Wave OFF DELIVERY */}
                    <dt className={`col-sm-10 text-warning ${!order.delievery_charges_wave_off && 'd-none'}`}>
                        Delivery Wave Off:
                        {' '}
                        {/* <span className="float-right text-muted">Express Delivery</span> */}
                    </dt>
                    <dd className={`col-sm-2 text-right text-warning ${!order.delievery_charges_wave_off && 'd-none'}`}>
                        <strong>
                            - &nbsp;
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.delievery_charges_wave_off}
                        </strong>
                    </dd>
                    <dt className="col-sm-10">
                        Total:
                    </dt>
                    <dd className="col-sm-2 text-right">
                        <strong className="h5 text-dark">
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.final_price}
                        </strong>
                    </dd>

                    {/* DUE AMOUNT */}
                    <dt className="col-sm-10">
                        Due Amount:
                        <span className="float-right text-muted text-uppercase">{order.payment_type_name}</span>
                    </dt>
                    <dd className="col-sm-2 text-right">
                        <strong className="h5 text-dark">
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.due_amount}
                        </strong>
                    </dd>

                    {/* PAID AMOUNT */}
                    <dt className="col-sm-10">
                        Paid Amount:
                        <span className="float-right text-muted text-uppercase">{order.payment_type_name}</span>
                    </dt>
                    <dd className="col-sm-2 text-right">
                        <strong className="h5 text-dark">
                            <i className="fas fa-rupee-sign" />
                            {' '}
                            {order.payed_amount}
                        </strong>
                    </dd>

                </dl>
            </article>
        </div>
    );
});

OrderDetailContainer.propTypes = {
    order: OrderPropType.isRequired,
};

export default OrderDetailContainer;
