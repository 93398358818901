/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const NoScrollRailWithHeader = ({
    title,
    children,
}) => {
    return <section className="section-content">
        <div className="container">
            <header className="section-heading">
                <h3 className="section-title">{title}</h3>
            </header>
            {/* sect-heading */}
            <div className="row ">
                {children}
            </div>
        </div>
    </section>
}

NoScrollRailWithHeader.propTypes = {

};

export default NoScrollRailWithHeader;
