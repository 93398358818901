import { put, call, takeEvery } from 'redux-saga/effects';
import { ORDER_ACTIONS } from 'src/actions/OrderActions';
import { getOrderFullInfoAPI } from 'src/api/BillingApi';

export function* getOrderDetailsSaga({ orderId }) {
    try {
        const {
            data: {
                data: orderDetails,
            },
        } = yield call(getOrderFullInfoAPI, { orderId });

        yield put({
            type: ORDER_ACTIONS.ORDER_DETAILS_FETCH_SUCCESS,
            orderDetails,
            orderId,
        });
    }
    catch (error) {
        console.error('getOrderDetailsSaga', error);
    }
}

export default [
    takeEvery(ORDER_ACTIONS.ORDER_DETAILS_FETCH, getOrderDetailsSaga),
];
