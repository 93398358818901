import {
    put, call, select, take, fork,
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import {
    updateItemsDataAction, CART_ACTIONS, resetCartAction,
} from 'src/actions/CartActions';
import {
    applyCouponAPI, getEligibleCouponsAPI, paymentDoneApi, orderStatusChangeApi, orderBulkAPI,
} from 'src/api/BillingApi';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import {
    ORDER_STATUS, PAYMENT_METHODS, USER_ADDRESS_MANDATORY, DELIVERY_TYPES,
} from 'src/constants/PaymentConstants';
import { appHeadMessageShow, APP_ACTIONS, setRedirectUrlAction } from 'src/actions/AppActions';
import { MESSAGE_ELEMENT_TYPES } from 'src/utils/RichMessageUtil';
import { gotoOrdersPage } from 'src/utils/RoutingUtil';
import {
    getChannelID, getPlatform, calculateBilling, percentageToValue, valueAsPercentageOf,
} from 'src/utils/CommonUtils';
import config from 'src/config';
import { groupBy } from 'lodash-es';
import { ENUMS_ACTIONS } from 'src/actions/EnumsActions';
import { STORE_ACTIONS } from 'src/actions/StoreActions';
import { PRODUCT_TYPES } from 'src/constants/ProductConstants';

const callbackOnPaymentSuccess = [];

export function* applyCouponSaga({ couponCode }) {
    const {
        cartReducer: {
            total,
        },
        userReducer: {
            userDetails: {
                id: userId,
            },
        },
        locationReducer: {
            selectedPincode,
        },
    } = yield select();

    // eslint-disable-next-line no-nested-ternary
    const channelId = getChannelID();

    try {
        const {
            data: {
                data,
            },
        } = yield call(applyCouponAPI, {
            coupon: couponCode,
            billAmount: total,
            channelId,
            customerId: userId,
            pincode: selectedPincode,
        });

        const {
            discount,
            message,
        } = data;

        yield put({
            type: CART_ACTIONS.APPLY_COUPON_REQUEST_SUCCESS,
            discount,
            appliedCoupon: couponCode,
            applyCouponMessage: message,
        });
    }
    catch (error) {
        console.error('applyCouponSaga', error);
        yield put({
            type: CART_ACTIONS.APPLY_COUPON_REQUEST_ERROR,
            discount: 0,
            appliedCoupon: couponCode,
            applyCouponMessage: 'Unable to apply the coupon.',
        });
    }
}

export function* makePurchaseSaga() {
    const {
        locationReducer: {
            selectedPincode,
        },
        appReducer: {
            clientData: {
                razor_key,
            },
        },
        enumReducer: {
            selectedDeliveryType,
        },
        cartReducer: {
            deliveryDateTime,
            itemsInCart,
            total: totalPrice,
            discount: discountAmount,
            totalAfterDiscount,
            appliedCoupon,
            payMethod,
            deliveryAddress,
            remarks,
            storeOrderDetails,
        },
        userReducer: {
            userDetails,
        },
    } = yield select();

    // user address selection needed
    const isUserAddressNeeded = !!USER_ADDRESS_MANDATORY[selectedDeliveryType?.id];

    const purchaseErrorList = [];
    if (!deliveryAddress && isUserAddressNeeded) {
        purchaseErrorList.push('Please select an delivery address');
    }
    if (!itemsInCart.length) {
        purchaseErrorList.push('Please select some items in cart.');
    }

    if (storeOrderDetails) {
        Object.keys(storeOrderDetails).forEach((storeId) => {
            if (storeOrderDetails[storeId]?.minOrderReached === false) {
                purchaseErrorList.push(`Min Order for ${storeOrderDetails[storeId]?.storeName} is ₹${storeOrderDetails[storeId]?.minimumOrder}. Current order is ₹${storeOrderDetails[storeId]?.total}`);
            }
        });
    }

    // ERRORS ARE THERE THEN SHOW ERRORS
    if (purchaseErrorList.length) {
        yield put({
            type: CART_ACTIONS.MAKE_PURCHASE_REQUEST_ERROR,
            purchaseErrorList,
        });
        return;
    }
    const channelId = getChannelID();
    const source = getPlatform();

    const { length, ...products } = itemsInCart;

    /*
        WE NEED TO CREATE MULTIPLE BILLS FOR:
            - for every store there will be a new bill
            - for every service there will be a new bill even if services are from same store
    */
    const productGroupByBilling = groupBy(products, (product) => {
        if (product.product_type_id === PRODUCT_TYPES.SERVICE) {
            return `${product.store_id}-${product.product_code}`;
        }
        return product.store_id;
    });

    const discountPercentageOnOrderAmount = valueAsPercentageOf(discountAmount, totalPrice);

    // take address only when its home delivery
    const delievery_address = isUserAddressNeeded ? deliveryAddress.address : '';

    // state id
    const stateId = isUserAddressNeeded ? deliveryAddress.state_id : '';

    // use user details if its not home delivery
    const contact_person = isUserAddressNeeded ? deliveryAddress.contact_person : `${userDetails.first_name} ${userDetails.last_name}`;
    // use user details if its not home delivery
    const phone = isUserAddressNeeded ? deliveryAddress.phone : userDetails.phone;

    const orderBulk = [];

    const bills = Object.keys(productGroupByBilling);

    for (let index = 0; index < bills.length; index++) {
        const billKey = bills[index];
        const storeId = billKey.split('-')[0]; // e.g. storeId | storeId-product_code

        const productsFromBill = productGroupByBilling[billKey];

        const details = [];
        productsFromBill.forEach((product) => {
            const gstAmount = percentageToValue(product.gst_tax, product.total_price_without_tax);

            const item = {
                product_id: product.id,
                units: product.count,
                product_price: product.final_selling_price,
                total_product_price: (product.final_selling_price * product.count),
                product_type: product.product_type_id,
                product_actual_price: product.total_price_without_tax,
                gst_tax: product.gst_tax,
                gst_amount: gstAmount,
                unit_type: null,
                discount_per: 0,
                discount_amount: 0,
            };
            details.push(item);
        });

        const {
            total: totalStoreOrder,
        } = calculateBilling(productsFromBill);

        const discountAmountByStoreOrder = percentageToValue(discountPercentageOnOrderAmount, totalStoreOrder);

        const {
            totalAfterDiscount: totalAfterDiscountStoreOrder,
        } = calculateBilling(productsFromBill, discountAmountByStoreOrder);

        if (!phone) {
            if (!isUserAddressNeeded) {
                yield put(appHeadMessageShow({
                    headMessage: [
                        'Please add a valid phone number: ',
                        {
                            children: 'Update Profile',
                            type: MESSAGE_ELEMENT_TYPES.LINK,
                            to: '/profile/details',
                        },
                    ],
                }));
            }
            else {
                yield put(appHeadMessageShow({
                    headMessage: [
                        'Please add a valid phone number in address',
                    ],
                }));
            }
            return;
        }

        // take delivery charges if its home delivery
        const delievery_charges = isUserAddressNeeded ? storeOrderDetails[storeId]?.deliveryCharges : 0;
        const delievery_charges_wave_off = isUserAddressNeeded ? storeOrderDetails[storeId]?.waveOffDeliveryCharges : 0;

        const totalAfterDeliveryCharges = totalAfterDiscountStoreOrder + (delievery_charges - delievery_charges_wave_off);

        // placing order
        const order = {
            discount_per: valueAsPercentageOf(discountAmountByStoreOrder, totalStoreOrder),
            discount_rs: discountAmountByStoreOrder,
            final_price: totalAfterDeliveryCharges,
            due_amount: totalAfterDeliveryCharges,
            total_purchase_price: totalStoreOrder,
            final_price_without_tax: totalAfterDeliveryCharges,
            tax_per: 0,
            tax_amount: 0,

            created_by: '0',
            customer_id: userDetails.id,
            order_status: payMethod === PAYMENT_METHODS.ONLINE ? ORDER_STATUS.ON_HOLD : ORDER_STATUS.IN_PROGRESS, /// until payment is done. it will be on hold
            payed_amount: 0,
            payment_type: payMethod,
            coupon_code: appliedCoupon,
            store_id: storeId,
            channel_id: channelId,
            delivery_type: selectedDeliveryType.id,
            source,
            // state_id: stateId,
            email: userDetails.email,
            remarks,
            pincode: selectedPincode,

            delievery_address,
            delievery_charges,
            delievery_charges_wave_off,
            contact_person,
            phone,
            state_id: stateId,
        };

        const orderPickupDT = new Date(deliveryDateTime);
        order.pickup_time = `${orderPickupDT.getFullYear()}-${orderPickupDT.getMonth() + 1}-${orderPickupDT.getDate()} ${orderPickupDT.getHours()}:${orderPickupDT.getMinutes()}:${orderPickupDT.getSeconds()}`;

        orderBulk.push({
            order,
            details,
        });
    }

    let orderResponse = null;

    try {
        ({ data: { data: orderResponse } } = yield call(orderBulkAPI, {
            customer: userDetails,
            orderBulk,
            userId: userDetails.id,
            printCount: 1,
            paymentType: payMethod,
            dueAmount: totalAfterDiscount,
        }));
    }
    catch (error) {
        console.error('error while placing order', error);

        yield put(appHeadMessageShow({
            headMessage: [
                `Error while placing order: ${error?.response?.data?.msg}`,
            ],
        }));
        // break the flow here
        return;
    }

    if (payMethod === PAYMENT_METHODS.ONLINE) {
        const onPaymentCancel = (/* response */) => {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata);

            orderStatusChangeApi({
                orderIds: orderResponse.storeOrders.map((order) => order.id),
                orderStatus: ORDER_STATUS.CANCELED,
            });
        };

        const options = {
            modal: {
                ondismiss: onPaymentCancel,
            },

            // key: 'rzp_test_4rJWJnAn5fEnIW', // TEST key

            key: razor_key,
            order_id: orderResponse.razorOrderId, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            amount: totalAfterDiscount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: 'INR',
            name: config.brandDetails.name,
            // description: '',
            image: config.brandDetails.logo,
            handler(successResponse) {
                callbackOnPaymentSuccess.forEach((callback) => callback({
                    successResponse,
                    orderResponse,
                }));
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
            },
            prefill: {
                name: contact_person,
                email: userDetails.email,
                contact: phone,
            },
            notes: {
                address: delievery_address,
            },
            theme: {
                color: '#ff6a00',
            },
        };

        const rzPay = new window.Razorpay(options);
        rzPay.on('payment.failed', onPaymentCancel);
        rzPay.open();
    }
    else {
        yield put(appHeadMessageShow({
            headMessage: [
                'Your Order No. is: ',
                {
                    children: orderResponse.parentBillNo,
                    type: MESSAGE_ELEMENT_TYPES.LINK,
                    to: '/profile/orders',
                },
            ],
        }));

        yield put(resetCartAction());

        yield put(setRedirectUrlAction({
            redirectUrl: gotoOrdersPage(),
        }));
    }
}

function* paymentSuccessHandlerSaga() {
    const channel = eventChannel((emitter) => {
        const onPaymentSuccess = (response) => {
            emitter(response);
        };

        callbackOnPaymentSuccess.push(onPaymentSuccess);

        // Return an unsubscribe method
        return () => {
            emitter(END);
        };
    });

    // Process events until operation completes
    while (true) {
        const {
            orderResponse,
        } = yield take(channel);

        yield call(paymentDoneApi, {
            orderIds: orderResponse.storeOrders.map((order) => order.id),
        });

        yield call(orderStatusChangeApi, {
            orderIds: orderResponse.storeOrders.map((order) => order.id),
            orderStatus: ORDER_STATUS.IN_PROGRESS,
        });

        yield put(appHeadMessageShow({
            headMessage: [
                'Your Order No. is: ',
                {
                    children: orderResponse.parentBillNo,
                    type: MESSAGE_ELEMENT_TYPES.LINK,
                    to: '/profile/orders',
                },
            ],
        }));

        yield put(resetCartAction());

        yield put({
            type: APP_ACTIONS.UPDATE_REDIRECT_URL,
            redirectUrl: gotoOrdersPage(),
        });
    }
}

export function* fetchCouponsSaga() {
    const {
        userReducer: {
            userDetails: {
                id: userId,
            },
        },
        locationReducer: {
            selectedPincode,
        },
    } = yield select();
    try {
        const {
            data: {
                data: couponList,
            },
        } = yield call(getEligibleCouponsAPI, {
            customerId: userId,
            channelId: getChannelID(),
            pincode: selectedPincode,
        });

        yield put({
            type: CART_ACTIONS.COUPONS_FETCH_SUCCESS,
            couponList,
        });
    }
    catch (error) {
        yield put({
            type: CART_ACTIONS.COUPONS_FETCH_ERROR,
        });
    }
}

function* recalculateOrderOnNewItem(state, stores, selectedDeliveryType) {
    let newState = {
        ...state,
    };
    // for fast length lookup add a key with name 'length'
    newState.itemsInCart.length = Object.keys(newState.itemsInCart).length - 1; // -1 is for the length key it self

    newState = {
        ...newState,
        appliedCoupon: '',
        applyCouponMessage: '',
        discount: 0,
        ...calculateBilling(newState.itemsInCart),
    };
    // calculate store order details
    const storeOrderDetails = {};

    // getting products from cart
    const { length, ...products } = newState.itemsInCart;

    // grouping products by store
    const productsGroupedByStore = groupBy(products, (product) => product.store_id);

    // array of stores that has products in cart
    const orderStoresIdList = Object.keys(productsGroupedByStore);

    // total delivery charges and waved off charges on full order
    let orderTotalDeliveryCharges = 0;
    let orderTotalDeliveryChargeWavedOff = 0;

    // eslint-disable-next-line no-debugger
    // debugger;

    // loop on stores
    for (let index = 0; index < orderStoresIdList.length; index++) {
        // get store id
        const storeId = orderStoresIdList[index];

        // get products of store
        const productsFromStore = productsGroupedByStore[storeId];

        // calculate total order from store
        const storeTotal = productsFromStore.reduce((total, product) => total + (product.final_selling_price * product.count), 0);

        // get store rules
        const {
            name: storeName,
            delivery_charges,
            minimum_order,
            wave_off_delivery_charges_exceeding_minimum_order,
            allow_order_lesser_than_minimum_order_with_delivery_charges,
        } = stores.find((s) => s.id.toString() === storeId) || {};

        // default store order data
        const storeCharges = {
            storeName,
            minOrderReached: true,
            deliveryCharges: 0,
            total: storeTotal,
            waveOffDeliveryCharges: 0,
            minimumOrder: 0,
        };

        // delivery charges only for home delivery
        if (DELIVERY_TYPES.HOME_DELIVERY.toString() === selectedDeliveryType?.id) {
            // set default value
            storeCharges.deliveryCharges = delivery_charges || 0;
            storeCharges.minimumOrder = minimum_order || 0;

            // apply rule
            if (delivery_charges
                && minimum_order
                && wave_off_delivery_charges_exceeding_minimum_order) {
                // `Delievery Charges ${delivery_charges} on order less than 200`;
                if (storeTotal >= minimum_order) {
                    storeCharges.waveOffDeliveryCharges = delivery_charges;
                }
            }

            // do not allow order lesser than min order
            if (
                !allow_order_lesser_than_minimum_order_with_delivery_charges
                && storeTotal < minimum_order
            ) {
                storeCharges.minOrderReached = false;
            }

            // adding delivery charges on total
            orderTotalDeliveryCharges += storeCharges.deliveryCharges;
            orderTotalDeliveryChargeWavedOff += storeCharges.waveOffDeliveryCharges;
        }

        // store data in stores order details. this will go in redux store
        storeOrderDetails[storeId] = storeCharges;
    }

    // add delievery details in new state
    newState = {
        ...newState,
        orderTotalDeliveryCharges,
        orderTotalDeliveryChargeWavedOff,
        storeOrderDetails,
        ...calculateBilling(
            newState.itemsInCart,
            newState.discount,
            orderTotalDeliveryCharges,
            orderTotalDeliveryChargeWavedOff,
        ),
    };

    // update in store
    yield put(updateItemsDataAction({
        addRemoveItemState: newState,
    }));
}

export function* addItemInCartSaga({
    newItem,
}) {
    const {
        cartReducer,
        storeReducer: {
            stores,
        },
        enumReducer: {
            selectedDeliveryType,
        },
    } = yield select();

    const {
        itemsInCart,
    } = cartReducer;

    const newState = {
        ...cartReducer,
        itemsInCart: {
            ...itemsInCart,
        },
        purchaseErrorList: [],
    };
    // if the item is already in cart
    const existingItem = itemsInCart[`${newItem.id}-${newItem.store_id}`];

    if (!existingItem) {
        // item is not in the cart so add it
        newState.itemsInCart[`${newItem.id}-${newItem.store_id}`] = {
            ...newItem,
            count: 1,
        };
    }
    else {
        // increment item count if its already there
        newState.itemsInCart[`${newItem.id}-${newItem.store_id}`] = {
            ...newItem,
            count: existingItem.count + 1,
        };
    }

    yield recalculateOrderOnNewItem(newState, stores, selectedDeliveryType);
}
export function* removeItemFromCartSaga({
    newItem,
}) {
    const {
        cartReducer,
        storeReducer: {
            stores,
        },
        enumReducer: {
            selectedDeliveryType,
        },
    } = yield select();

    const {
        itemsInCart,
    } = cartReducer;

    const newState = {
        ...cartReducer,
        itemsInCart: {
            ...itemsInCart,
        },
        purchaseErrorList: [],
    };
    // if the item is already in cart
    const existingItem = itemsInCart[`${newItem.id}-${newItem.store_id}`];

    if (existingItem) {
        // if item count is more than 1 than --
        if (existingItem.count > 1) {
            newState.itemsInCart[`${newItem.id}-${newItem.store_id}`] = {
                ...newItem,
                count: existingItem.count - 1,
            };
        }
        // if there is only one item then remove item from cart
        else {
            delete newState.itemsInCart[`${newItem.id}-${newItem.store_id}`];
        }
    }

    yield recalculateOrderOnNewItem(newState, stores, selectedDeliveryType);
}

export function* deliveryTypeChangedSaga({ selectedDeliveryType }) {
    const {
        cartReducer,
        storeReducer: {
            stores,
        },
        enumReducer: {
            selectedDeliveryType: selectedDeliveryTypeInRedux,
        },
    } = yield select();

    const {
        itemsInCart,
    } = cartReducer;

    const newState = {
        ...cartReducer,
        itemsInCart: {
            ...itemsInCart,
        },
        purchaseErrorList: [],
    };

    yield recalculateOrderOnNewItem(newState, stores, selectedDeliveryType || selectedDeliveryTypeInRedux);
}

export default [
    takeFirstSagaUtil(CART_ACTIONS.APPLY_COUPON_REQUEST, applyCouponSaga),
    takeFirstSagaUtil(CART_ACTIONS.MAKE_PURCHASE_REQUEST, makePurchaseSaga),
    takeFirstSagaUtil(CART_ACTIONS.COUPONS_FETCH, fetchCouponsSaga),
    takeFirstSagaUtil(CART_ACTIONS.ADD_ITEM_IN_CART_INIT, addItemInCartSaga),
    takeFirstSagaUtil(CART_ACTIONS.REMOVE_ITEM_FROM_CART_INIT, removeItemFromCartSaga),
    takeFirstSagaUtil(ENUMS_ACTIONS.SELECTED_DELIVERY_TYPES_UPDATE, deliveryTypeChangedSaga),
    takeFirstSagaUtil(STORE_ACTIONS.STORE_FETCH_SUCCESS, deliveryTypeChangedSaga),
    fork(paymentSuccessHandlerSaga),
];
