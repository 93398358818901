import React from 'react';
import PropTypes from 'prop-types';
import CartAddButtonComponent from './CartAddButtonComponent';

function CartItemToggleButtonComponent({
    onPlus,
    onMinus,
    value,
    title,
    toggledTitle,
}) {
    if (!value) {
        return (
            <CartAddButtonComponent
                onClick={onPlus}
                className="btn btn-primary"
                title={title}
            />
        );
    }
    return (
        <CartAddButtonComponent
            onClick={onMinus}
            className="btn btn-light"
            title={toggledTitle}
        />
    );
}
CartItemToggleButtonComponent.propTypes = {
    onPlus: PropTypes.func.isRequired,
    onMinus: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    toggledTitle: PropTypes.string.isRequired,
};

export default CartItemToggleButtonComponent;
