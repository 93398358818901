import {
    put, call, select,
} from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { USER_ACTIONS, updateUserDefaultAddressAction } from 'src/actions/UserActions';
import {
    getCustomerAddressAPI, newAddressAPI, addUpdateUserDetailsAPI, profilePicUpload,
} from 'src/api/UserApi';
import { getOrdersByCustomerAPI } from 'src/api/BillingApi';
import { appHeadMessageShow } from 'src/actions/AppActions';
import { get } from 'lodash-es';

export function* getUserAddressesSaga() {
    try {
        const {
            locationReducer: {
                selectedPincode,
            },
            userReducer: {
                userDetails: {
                    id,
                },
            },
            userReducer: {
                userDefaultAddress,
            },
        } = yield select();

        const {
            data: {
                data: addresses,
            },
        } = yield call(getCustomerAddressAPI, { id });

        yield put({
            type: USER_ACTIONS.USER_ADDRESSES_FETCH_SUCCESS,
            addresses,
        });

        // address > 0 and default address is not selected
        if (Array.isArray(addresses) && addresses.length > 0 && !userDefaultAddress) {
            const defaultAddress = addresses.find((address) => address.pincode === Number(selectedPincode)) || null;
            yield put(updateUserDefaultAddressAction({
                userDefaultAddress: defaultAddress,
            }));
        }
    }
    catch (error) {
        console.error('user address', error);
        yield put({
            type: USER_ACTIONS.USER_ADDRESSES_FETCH_ERROR,
        });
    }
}

export function* addUserAddressSaga({
    firstName,
    lastName,
    phone,
    fullAddress,
    pincode,
    state_id,
}) {
    try {
        const { userReducer: { userDetails: { id } } } = yield select();

        // call api to add
        yield call(newAddressAPI, {
            contact_person: `${firstName} ${lastName}`,
            phone,
            address: fullAddress,
            customer_id: id,
            location_id: 1, // TODO: Make a location selector : or make it non mandatory
            pincode,
            state_id,
        });

        // success
        yield put({
            type: USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST_SUCCESS,
        });

        // get user address again
        yield call(getUserAddressesSaga);
    }
    catch (error) {
        console.error('addUserAddressSaga', error);
        yield put({
            type: USER_ACTIONS.USER_ADDRESSES_FETCH_ERROR,
        });
    }
}

export function* getUserOrdersSaga({
    page,
    rows,
}) {
    try {
        const {
            userReducer: {
                userDetails: {
                    id,
                },
            },
        } = yield select();

        const {
            data: {
                data: {
                    data: userOrders,
                },
            },
        } = yield call(getOrdersByCustomerAPI, {
            customerId: id,
            page,
            rows,
        });

        const userTotalOrders = get(userOrders, '[0].total_rows', 0);

        yield put({
            type: USER_ACTIONS.USER_ORDER_LIST_FETCH_SUCCESS,
            userOrders,
            userTotalOrders,
        });
    }
    catch (error) {
        console.error('getUserOrdersSaga', error);
        yield put({
            type: USER_ACTIONS.USER_ORDER_LIST_FETCH_ERROR,
        });
    }
}

function* updateUserDetailsSaga(userDetails) {
    try {
        yield call(addUpdateUserDetailsAPI, { userDetails });

        yield put({
            type: USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST_SUCCESS,
            userDetails,
        });

        yield put(appHeadMessageShow({
            headMessage: [
                'Your profile information is updated.',
            ],
        }));
    }
    catch (error) {
        console.error('updateUserDetailsSaga', error);

        yield put(appHeadMessageShow({
            headMessage: [
                error.response.data.msg,
            ],
        }));
    }
}

export function* uploadUserProfilePicSaga({ file }) {
    try {
        const {
            userReducer: {
                userDetails: {
                    customer_code,
                },
            },
        } = yield select();

        const {
            data: {
                data,
            },
        } = yield profilePicUpload({
            file,
            customer_code,
        });

        yield put({
            type: USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST_SUCCESS,
            dp: data[0].url,
        });

        yield put(appHeadMessageShow({
            headMessage: 'Profile Picture Updated',
        }));
    }
    catch (error) {
        console.error(error);
        yield put({
            type: USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST_ERROR,
        });

        yield put(appHeadMessageShow({
            headMessage: 'Unable to Update Picture Updated',
        }));
    }
}

export default [
    takeFirstSagaUtil(USER_ACTIONS.USER_ADDRESSES_FETCH, getUserAddressesSaga),
    takeFirstSagaUtil(USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST, addUserAddressSaga),
    takeFirstSagaUtil(USER_ACTIONS.USER_ORDER_LIST_FETCH, getUserOrdersSaga),
    takeFirstSagaUtil(USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST, updateUserDetailsSaga),
    takeFirstSagaUtil(USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST, uploadUserProfilePicSaga),
];
