/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import CartAddButtonComponent from './CartAddButtonComponent';

function CartAddRemoveButton({
    onPlus,
    onMinus,
    value,
    title,
    ...rest
}) {
    if (value < 1) {
        return (
            <CartAddButtonComponent
                onClick={onPlus}
                className="btn btn-primary"
                title={title}
                {...rest}
            />
        );
    }
    return (
        <div className="input-group input-spinner">
            <div className="input-group-prepend">
                <button
                    onClick={onMinus}
                    className="btn btn-light"
                    type="button"
                    id="button-minus"
                >
                    {' '}
                    −
                </button>
            </div>
            <input
                readOnly
                type="text"
                className="form-control"
                value={value}
            />
            <div className="input-group-append">
                <button
                    onClick={onPlus}
                    className="btn btn-light"
                    type="button"
                    id="button-plus"
                >
                    {' '}
                    +
                </button>
            </div>
        </div>
    );
}
CartAddRemoveButton.propTypes = {
    onPlus: PropTypes.func.isRequired,
    onMinus: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

export default CartAddRemoveButton;
