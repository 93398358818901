import { AUTH_ACTIONS } from 'src/actions/AuthActions';
import {
    put, call, take, fork,
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import {
    loginAPI, sendOptAPI, googleOneTapLoginAPI, googleLoginAPI,
} from 'src/api/AuthApi';
import { getProfilePicAPI } from 'src/api/UserApi';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { setDefaultHeaders, addResponseInterceptor } from 'src/utils/RequestUtil';
import { USER_ACTIONS } from 'src/actions/UserActions';
import { decodeJwt } from 'src/utils/JwtUtils';
import { couponsFetchAction } from 'src/actions/CartActions';
import { DEFAULT_USER_DP } from 'src/constants/UiConstants';

export function* sendOtpSaga({ phone }) {
    if (!phone) {
        yield put({
            type: AUTH_ACTIONS.SEND_OTP_ERROR,
        });
        return;
    }
    try {
        const { status } = yield call(sendOptAPI, { phone });
        if (status === 200) {
            yield put({
                type: AUTH_ACTIONS.SEND_OTP_SUCCESS,
            });
        }
    }
    catch (error) {
        console.error('sending otp', error);
        yield put({
            type: AUTH_ACTIONS.SEND_OTP_ERROR,
        });
    }
}

export function* updateUserData({ token }) {
    const {
        data: {
            customer_code,
            dob,
            email,
            first_name,
            full_address,
            gender,
            id,
            last_name,
            phone,
        },
    } = decodeJwt(token);

    setDefaultHeaders({ Authorization: token });
    const defaultProfilePic = DEFAULT_USER_DP;

    const {
        data: {
            data,
        },
    } = yield call(getProfilePicAPI, { customer_code });

    let dp = defaultProfilePic;
    if (Array.isArray(data) && data.length) {
        dp = data[0].url;
    }
    yield put({
        type: USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST_SUCCESS,
        userDetails: {
            customer_code,
            dob,
            email,
            first_name,
            full_address,
            gender,
            id,
            last_name,
            phone,
            dp,
        },
    });

    yield put({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        payload: {
            token,
        },
    });

    // get coupons
    yield put(couponsFetchAction());
}

export function* googleLoginOneTapSaga({ credential: googleToken }) {
    if (!googleToken) {
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
        return;
    }

    try {
        const {
            data: {
                data: token,
            },
        } = yield call(googleOneTapLoginAPI, {
            token: googleToken,
        });

        yield updateUserData({ token });
    }
    catch (error) {
        console.error('sending otp', error);
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

export function* googleLoginSaga({
    accessToken,
    googleId,
}) {
    if (!accessToken || !googleId) {
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
        return;
    }
    try {
        const {
            data: {
                data: token,
            },
        } = yield call(googleLoginAPI, {
            accessToken,
            googleId,
        });
        yield updateUserData({ token });
    }
    catch (error) {
        console.error('sending otp', error);
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

export function* LoginSaga({ identifier, password }) {
    if (!identifier || !password) {
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
        return;
    }

    try {
        const {
            data: {
                data: token,
            },
        } = yield call(loginAPI, {
            identifier: identifier.toLowerCase(),
            password,
        });

        yield updateUserData({ token });
    }
    catch (error) {
        console.error('sending otp', error);
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

function* requestErrorHandlerSaga() {
    const channel = eventChannel((emitter) => {
        addResponseInterceptor({
            callback: (error) => {
                emitter({ error: { ...error } });
            },
        });
        // Return an unsubscribe method
        return () => {
            emitter(END);
        };
    });

    // Process events until operation completes
    while (true) {
        const { error } = yield take(channel);
        if (!error) {
            break;
        }
        if (error.response.status === 401) {
            yield put({ type: AUTH_ACTIONS.LOGOUT });
        }
    }
}

export default [
    takeFirstSagaUtil(AUTH_ACTIONS.SEND_OTP, sendOtpSaga),
    takeFirstSagaUtil(AUTH_ACTIONS.LOGIN, LoginSaga),
    takeFirstSagaUtil(AUTH_ACTIONS.GOOGLE_LOGIN_ONE_TAP_SUCCESS, googleLoginOneTapSaga),
    takeFirstSagaUtil(AUTH_ACTIONS.GOOGLE_LOGIN_SUCCESS, googleLoginSaga),
    fork(requestErrorHandlerSaga),
];
