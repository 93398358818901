/* eslint-disable */
import React from 'react';
import LinkComponent from './LinkComponent';

const LinkWrap = ({
    to,
    children,
    className
}) => {
    if (!to) {
        return children;
    }
    return (
        <LinkComponent className={className} to={to}>
            {children}
        </LinkComponent>
    );
};

LinkWrap.propTypes = {

};

export default LinkWrap;
