export const ENUMS_ACTIONS = {
    DELIVERY_TYPES_FETCH: 'DELIVERY_TYPES_FETCH',
    DELIVERY_TYPES_FETCH_SUCCESS: 'DELIVERY_TYPES_FETCH_SUCCESS',
    DELIVERY_TYPES_FETCH_ERROR: 'DELIVERY_TYPES_FETCH_ERROR',

    SELECTED_DELIVERY_TYPES_UPDATE: 'SELECTED_DELIVERY_TYPES_UPDATE',
};

export const deliveryTypesFetchAction = () => ({
    type: ENUMS_ACTIONS.DELIVERY_TYPES_FETCH,
});

export const selectedDeliveryTypesUpdateAction = (payload) => ({
    type: ENUMS_ACTIONS.SELECTED_DELIVERY_TYPES_UPDATE,
    ...payload,
});

export default {
    ENUMS_ACTIONS,
    deliveryTypesFetchAction,
    selectedDeliveryTypesUpdateAction,
};
