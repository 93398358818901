import React, { useState } from 'react';
import { UserAddressPropType } from 'src/constants/prop-types/UserPropTypes';
import PropTypes from 'prop-types';
import LinkComponent from '../LinkComponent';
import AddressCardComponent from './AddressCardComponent';
import NewAddressFormComponent from './NewAddressFormComponent';

const AddressListComponent = ({
    addresses,
    selectedAddress,
    onAddressSelection,
    onAddNewAddress,
    selectedButtonText,
    unSelectedButtonText,
    enforcedPincode,
    disabledButtonText,
}) => {
    const [
        addAddressVisibility,
        setAddAddressVisibility,
    ] = useState(false);

    const toggleNewAddressFormComponent = () => {
        setAddAddressVisibility(!addAddressVisibility);
    };

    const submitNewAddress = ({
        firstName,
        lastName,
        phone,
        fullAddress,
        pincode,
        state_id,
    }) => {
        if (
            firstName
            && lastName
            && phone
            && fullAddress
            && pincode
            && state_id
        ) {
            toggleNewAddressFormComponent();
            if (onAddNewAddress) {
                onAddNewAddress({
                    firstName,
                    lastName,
                    phone,
                    fullAddress,
                    pincode,
                    state_id,
                });
            }
        }
    };
    const addressCards = addresses.map((address) => (
        <div
            key={address.id}
            className="col-md-6"
        >
            <AddressCardComponent
                selectedAddress={selectedAddress && enforcedPincode === address.pincode && selectedAddress.id === address.id}
                selectedButtonText={selectedButtonText}
                unSelectedButtonText={unSelectedButtonText}
                addressDetails={address}
                onSelect={() => onAddressSelection && onAddressSelection(address)}
                onUnSelect={() => onAddressSelection && onAddressSelection(null)}
                disabled={enforcedPincode !== address.pincode}
                disabledButtonText={disabledButtonText}
            />
        </div>
    ));

    const addAddressButtonElement = !addAddressVisibility ? (
        <LinkComponent
            onClick={toggleNewAddressFormComponent}
            className="btn btn-light"
        >
            {' '}
            <i className="fa fa-plus" />
            {' '}
            Add New Address
            {' '}
        </LinkComponent>
    ) : null;

    const addAddressElement = addAddressVisibility ? (
        <NewAddressFormComponent
            onSubmit={submitNewAddress}
            onCancel={toggleNewAddressFormComponent}
        />
    ) : null;

    return (
        <div>
            {addAddressButtonElement}

            {addAddressElement}

            <div className="row mt-3">
                {addressCards}
            </div>
        </div>
    );
};

AddressListComponent.propTypes = {
    addresses: PropTypes.arrayOf(UserAddressPropType),
    selectedAddress: UserAddressPropType,
    onAddressSelection: PropTypes.func,
    onAddNewAddress: PropTypes.func,
    selectedButtonText: PropTypes.string,
    unSelectedButtonText: PropTypes.string,
    enforcedPincode: PropTypes.number,
    disabledButtonText: PropTypes.string,
};

AddressListComponent.defaultProps = {
    addresses: [],
    selectedAddress: null,
    onAddressSelection: null,
    onAddNewAddress: null,
    selectedButtonText: 'Selected',
    unSelectedButtonText: 'Use This Address',
    enforcedPincode: null,
    disabledButtonText: 'Disabled',
};
export default AddressListComponent;
