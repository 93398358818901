export const STORE_ACTIONS = {
    STORE_FETCH: 'STORE_FETCH',
    STORE_FETCH_SUCCESS: 'STORE_FETCH_SUCCESS',
    STORE_FETCH_ERROR: 'STORE_FETCH_ERROR',

    SELECTED_STORE_UPDATE: 'SELECTED_STORE_UPDATE',
};

export const storesFetchAction = () => ({
    type: STORE_ACTIONS.STORE_FETCH,
});

export const defaultStoreUpdate = (payload) => ({
    type: STORE_ACTIONS.SELECTED_STORE_UPDATE,
    ...payload,
});

export default {
    STORE_ACTIONS,
    storesFetchAction,
    defaultStoreUpdate,
};
