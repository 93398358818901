import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { pincodeChangedAction } from 'src/actions/LocationActions';
import { PINCODE_SIZE } from 'src/constants/UiConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import config from 'src/config';
import { get } from 'lodash-es';
import PinCodeSelectionContainer from './PinCodeSelectionContainer';

const {
    brandDetails: {
        logo,
        name,
    },
} = config;

const PincodeWelcomeScreenContainer = () => {
    const {
        selectedPincode,
        storeAndProductCount,
    } = useSelector((state) => state.locationReducer, shallowEqual);

    const {
        itemsInCart,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    const [
        pincode,
        setPincode,
    ] = useState(selectedPincode || '');

    const [
        errorMessage,
        setErrorMessage,
    ] = useState('');

    const onPincodeChange = useCallback((newPincode) => {
        setErrorMessage('');
        setPincode(newPincode);
    }, [
        setPincode,
        setErrorMessage,
    ]);

    // REDUX
    const dispatch = useDispatch();

    // HOOKS
    const history = useHistory();
    const location = useLocation();

    // store and product counts
    let storeProductInfoMessageElement = null;
    const storeCount = get(storeAndProductCount, `[${pincode}].store_count`, null);
    const productCount = get(storeAndProductCount, `[${pincode}].product_count`, null);
    if (productCount !== null) {
        let storeProductInfoMessage = '';
        if (productCount > 0) {
            storeProductInfoMessage = `${storeCount} Store and ${productCount} Products/Services available here`;
        }
        else {
            storeProductInfoMessage = 'Oops! No Products/Services available here';
        }

        storeProductInfoMessageElement = (
            <div
                className="alert alert-primary"
                role="alert"
            >
                {storeProductInfoMessage}
            </div>
        );
    }

    // on main button click on enter
    const onSubmitHandler = useCallback(() => {
        if (pincode.length === PINCODE_SIZE && productCount) {
            dispatch(pincodeChangedAction({ pincode }));

            // go to the router from which user came
            // redirect to "from" state or home page
            const { from } = location.state || { from: { pathname: '/' } };
            history.replace(from);
        }
        else if (pincode.length !== PINCODE_SIZE) {
            setErrorMessage('Pincode length must be 6');
        }
    }, [
        dispatch,
        pincode,
        history,
        location,
        selectedPincode,
        productCount,
    ]);

    // changing pincode will empty your cartReducer
    const itemsInCartWarningElement = itemsInCart.length ? (
        <div
            className="alert alert-secondary"
            role="alert"
        >
            Changing PINCODE will remove all items from cart
        </div>
    ) : null;

    const errorMessageElement = errorMessage ? (
        <div
            className="alert alert-warning"
            role="alert"
        >
            {errorMessage}
        </div>
    ) : null;

    return (
        <section
            className="section-content pl-3 pr-3 padding-y pincode-bg force-center flex-column"
            style={{ minHeight: '84vh' }}
        >

            <div>
                <div
                    className="card mx-auto content"
                    style={{
                        maxWidth: 400,
                    }}
                >
                    <div className="card-body">
                        <div>
                            <div className="form-group">
                                <i className="fas fa-map-marker-alt fa-8x primary-color p-1 force-center" />
                            </div>
                            <div className="form-group force-center">
                                <span className="display-4">
                                    Start with entering your
                                    {' '}
                                    <span className="display-3">Pincode</span>
                                </span>
                            </div>
                            {itemsInCartWarningElement}

                            <PinCodeSelectionContainer
                                onPincodeChange={onPincodeChange}
                                onEnter={onSubmitHandler}
                                TextComponentClassName="form-control"
                                TupleComponentClassName="mb-3 area-list"
                                defaultPincode={selectedPincode}
                            />
                            {errorMessageElement}

                            {storeProductInfoMessageElement}

                            <div className="form-group">
                                <button
                                    disabled={!productCount || pincode.length !== PINCODE_SIZE}
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    onClick={onSubmitHandler}
                                >
                                    Let&apos;s Start
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="force-center mt-3">
                                <img
                                    height="50"
                                    className="logo"
                                    alt={name}
                                    src={logo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

PincodeWelcomeScreenContainer.propTypes = {

};

export default PincodeWelcomeScreenContainer;
