/* eslint-disable */
import React from 'react';
import Slider from 'react-slick';
import SlickNextArrow from '../slick-arrows/SlickNextArrow';
import SlickPrevArrow from '../slick-arrows/SlickPrevArrow';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />
};

const FullWidthCarouselComponent = ({
    children,
}) => (
    <Slider {...settings}>
        {children}
    </Slider>
);

FullWidthCarouselComponent.propTypes = {

};

export default FullWidthCarouselComponent;
