import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { USER_ACTIONS } from 'src/actions/UserActions';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';
import { AUTH_ACTIONS } from 'src/actions/AuthActions';

const defaultUserDetails = () => ({
    customer_code: '',
    dob: null,
    email: '',
    first_name: '',
    last_name: '',
    full_address: '',
    gender: '',
    id: '',
    phone: '',
    dp: '',
});

const getInitialState = () => ({
    addresses: [],
    addressesFetchStatus: null,

    addAddressRequest: null,

    userDefaultAddress: null,

    userOrders: [],
    userTotalOrders: 0,
    ordersFetchStatus: null,

    userDetails: defaultUserDetails(),
    userDetailsUpdateRequestStatus: null,

    userProfilePictureUpdateStatus: null,
});

const resetState = {
    addressesFetchStatus: null,
    addAddressRequest: null,
    ordersFetchStatus: null,
    userDetailsUpdateRequestStatus: null,
    userProfilePictureUpdateStatus: null,
};

const userReducer = (state = getInitialState(), action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        // PROFILE PICTURE UPDATE
        case USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST: {
            return {
                ...newState,
                userProfilePictureUpdateStatus: REQUEST_STATUS.PENDING,
            };
        }
        case USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST_SUCCESS: {
            return {
                ...newState,
                userProfilePictureUpdateStatus: REQUEST_STATUS.SUCCESS,
                userDetails: {
                    ...newState.userDetails,
                    dp: action.dp,
                },
            };
        }
        case USER_ACTIONS.USER_PROFILE_PICTURE_UPDATE_REQUEST_ERROR: {
            return {
                ...newState,
                userProfilePictureUpdateStatus: REQUEST_STATUS.ERROR,
            };
        }

        // USER DETAILS UPDATE
        case USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST: {
            return newState;
        }
        case USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST_SUCCESS: {
            return {
                ...newState,
                userDetails: action.userDetails,
                userDetailsUpdateRequestStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case USER_ACTIONS.USER_DETAILS_UPDATE_REQUEST_ERROR: {
            return {
                ...newState,
                userDetailsUpdateRequestStatus: REQUEST_STATUS.ERROR,
            };
        }
        // GET ADDRESS
        case USER_ACTIONS.USER_ADDRESSES_FETCH: {
            return newState;
        }
        case USER_ACTIONS.USER_ADDRESSES_FETCH_SUCCESS: {
            newState = {
                ...newState,
                addresses: action.addresses,
                addressesFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case USER_ACTIONS.USER_ADDRESSES_FETCH_ERROR: {
            newState = {
                ...newState,
                addressesFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }

        // ADD ADDRESS
        case USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST: {
            return newState;
        }
        case USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST_SUCCESS: {
            return {
                ...newState,
                addAddressRequest: REQUEST_STATUS.SUCCESS,
            };
        }
        case USER_ACTIONS.USER_ADD_ADDRESSES_REQUEST_ERROR: {
            return {
                ...newState,
                addAddressRequest: REQUEST_STATUS.ERROR,
            };
        }

        // DEFAULT ADDRESS
        case USER_ACTIONS.UPDATE_USER_DEFAULT_ADDRESS: {
            return {
                ...newState,
                userDefaultAddress: action.userDefaultAddress,
            };
        }

        // USER ORDERS
        case USER_ACTIONS.USER_ORDER_LIST_FETCH: {
            return newState;
        }
        case USER_ACTIONS.USER_ORDER_LIST_FETCH_SUCCESS: {
            newState = {
                ...newState,
                userOrders: action.userOrders,
                userTotalOrders: action.userTotalOrders,
                ordersFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case USER_ACTIONS.USER_ORDER_LIST_FETCH_ERROR: {
            newState = {
                ...newState,
                ordersFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        case AUTH_ACTIONS.LOGOUT: {
            return getInitialState();
        }
        default:
            return state;
    }

    return newState;
};

export default ReducerSyncWithStorage(userReducer, {
    keysToBeSync: [
        'userDefaultAddress',
        'userDetails',
    ],
    initialState: getInitialState(),
    storageKeyName: 'userReducer',
    storage: localStorage,
});
