import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const getPageDataByPincodeApi = ({ pincode }) => {
    const url = `${config.baseUrl}curation-pages/page-data-by-pincode/${pincode}`;
    return getRequest({
        url,
    });
};

export default {
    getPageDataByPincodeApi,
};
