import { PRODUCT_REVIEW_ACTIONS } from 'src/actions/ProductReviewActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';

const initialState = {
    reviewByproductCode: {
        // [productCode]: { reviews:[{id, title, comment...so on}], userReview:{id, title, comment...so on} }
    },
    aggregateRatingByProductCode: {
        // [productCode]: {avg_rating, total}
    },
    createReviewStatus: null,
};

export default (state = initialState, action) => {
    let newState = {
        ...state,
    };

    switch (action.type) {
        // FETCH
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH: {
            newState.reviewByproductCode[action.productCode] = { productReviewFetchStatus: REQUEST_STATUS.PENDING };
            break;
        }
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH_SUCCESS: {
            newState.reviewByproductCode[action.productCode] = {
                reviews: action.reviews,
                userReview: action.userReview,
                productReviewFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH_ERROR: {
            newState.reviewByproductCode[action.productCode] = { productReviewFetchStatus: REQUEST_STATUS.ERROR };
            break;
        }

        // AGGREGATED REVIEW UPDATE
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_AGGREGATED_REVIEW_SUCCESS: {
            newState.aggregateRatingByProductCode[action.productCode] = action.reviewAggregation;
            break;
        }

        // UPSERT
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT: {
            return {
                ...newState,
                createReviewStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT_SUCCESS: {
            newState = {
                ...newState,
                createReviewStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT_ERROR: {
            newState = {
                ...newState,
                createReviewStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        default:
            return state;
    }

    return newState;
};
