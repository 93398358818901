import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavLinkComponent from 'src/components/common/NavLinkComponent';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'src/actions/AuthActions';
import {
    gotoOrdersPage, gotoUserAddressPage, gotoUserProfilePage, gotoUserDetailsPage,
} from 'src/utils/RoutingUtil';
import UserAddressProfileContainer from './UserAddressProfileContainer';
import OrderListingContainer from './OrderListingContainer';
import UserDetailsContainer from './UserDetailsContainer';

const ProfileContainer = () => {
    const dispatch = useDispatch();
    const onLogoutClick = () => {
        dispatch(logoutAction());
    };
    return (
        <>
            <section className="section-content padding-y">
                <div className="container">
                    <div className="row">
                        <aside className="col-md-3">
                            <nav className="list-group">
                                <NavLinkComponent
                                    exact
                                    to={gotoUserDetailsPage()}
                                    className="list-group-item"
                                >
                                    {' '}
                                    Profile
                                </NavLinkComponent>
                                <NavLinkComponent
                                    exact
                                    to={gotoOrdersPage()}
                                    className="list-group-item"
                                >
                                    {' '}
                                    My Orders
                                </NavLinkComponent>
                                <NavLinkComponent
                                    exact
                                    to={gotoUserAddressPage()}
                                    className="list-group-item "
                                >
                                    {' '}
                                    My Address
                                </NavLinkComponent>
                                <NavLinkComponent
                                    exact
                                    onClick={onLogoutClick}
                                    className="list-group-item"
                                >
                                    {' '}
                                    Log out
                                    {' '}
                                </NavLinkComponent>
                            </nav>
                        </aside>
                        <Switch>
                            <Route
                                path={gotoOrdersPage()}
                                component={OrderListingContainer}
                            />
                            <Route
                                path={gotoUserDetailsPage()}
                                component={UserDetailsContainer}
                            />
                            <Route
                                path={gotoUserAddressPage()}
                                component={UserAddressProfileContainer}
                            />
                            <Route
                                path={gotoUserProfilePage()}
                            >
                                <Redirect
                                    from={gotoUserProfilePage()}
                                    to={gotoUserDetailsPage()}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProfileContainer;
