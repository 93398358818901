import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import config from 'src/config';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import * as serviceWorker from './serviceWorker';

import configureStore from './store';
import Routes from './routes';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={configureStore()}>
            <Routes />
        </Provider>
        <Helmet>
            <title>{config.brandDetails.name}</title>
        </Helmet>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
