import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { ENUMS_ACTIONS } from 'src/actions/EnumsActions';

const initialState = {
    deliveryTypes: [],
    deliveryTypesFetchStatus: null,
    selectedDeliveryType: null,
};

const resetState = {
    deliveryTypesFetchStatus: null,
};

export default (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case ENUMS_ACTIONS.DELIVERY_TYPES_FETCH: {
            return newState;
        }
        case ENUMS_ACTIONS.DELIVERY_TYPES_FETCH_SUCCESS: {
            newState = {
                ...newState,
                deliveryTypes: action.deliveryTypes,
                deliveryTypesFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case ENUMS_ACTIONS.DELIVERY_TYPES_FETCH_ERROR: {
            newState = {
                ...newState,
                deliveryTypesFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        case ENUMS_ACTIONS.SELECTED_DELIVERY_TYPES_UPDATE: {
            newState = {
                ...newState,
                selectedDeliveryType: action.selectedDeliveryType,
            };
            break;
        }
        default:
            return state;
    }

    return newState;
};
