/* eslint-disable react/prop-types */
import { chunk } from 'lodash-es';
import React from 'react';

const NoScrollRailComponent = ({
    children,
}) => {
    const elements = chunk(children, 3).map((nodes, row) => (
        <div
            className="row mb-3"
            // eslint-disable-next-line react/no-array-index-key
            key={row}
        >
            {nodes}
        </div>
    ));

    return (
        <section className="section-content padding-y-sm">
            <div className="container">
                <article className="card card-body last-nmb">
                    {elements}
                </article>
            </div>
        </section>
    );
};

NoScrollRailComponent.propTypes = {

};

export default NoScrollRailComponent;
