/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PinCodeSelectionContainer from 'src/containers/pin-code-selection/PinCodeSelectionContainer';
import { PINCODE_SIZE } from 'src/constants/UiConstants';
import StateDropdown from 'src/components/state-dropdown/StateDropdown';
import TextComponent from '../text-component/TextComponent';

const NewAddressFormComponent = memo(({
    onSubmit,
    onCancel,
}) => {
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const phoneRef = useRef();
    const fullAddressRef = useRef();
    const pincodeRef = useRef();

    const [
        selectedState,
        setSelectedState,
    ] = useState({});

    const [
        message,
        setMessage,
    ] = useState('');

    const onSubmitHandler = () => {
        const firstName = firstNameRef.current ? firstNameRef.current.value : '';
        const lastName = lastNameRef.current ? lastNameRef.current.value : '';
        const phone = phoneRef.current ? phoneRef.current.value : '';
        const fullAddress = fullAddressRef.current ? fullAddressRef.current.value : '';
        const pincode = pincodeRef.current ? pincodeRef.current.value : '';

        if (!firstName) {
            setMessage('Please Enter First Name');
        }
        else if (!lastName) {
            setMessage('Please Enter Last Name');
        }
        else if (!phone) {
            setMessage('Please Enter Phone');
        }
        else if (!fullAddress) {
            setMessage('Please Enter Address');
        }
        else if (!pincode || pincode.length !== PINCODE_SIZE) {
            setMessage('Please Enter Pincode');
        }
        else if (!selectedState.state_id) {
            setMessage('Please select State');
        }
        else {
            setMessage('');
        }

        if (onSubmit) {
            onSubmit({
                firstName,
                lastName,
                phone,
                fullAddress,
                pincode,
                state_id: selectedState.state_id,
            });
        }
    };

    return (
        <section className="section-content">
            <div
                className="card mx-auto"
            >
                <article className="card-body">
                    <header className="mb-4"><h4 className="card-title">New Address</h4></header>
                    <div>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label>First name</label>
                                <TextComponent
                                    ref={firstNameRef}
                                    type="text"
                                    className="form-control"
                                    placeholder
                                />
                            </div>
                            {/* form-group end.// */}
                            <div className="col-md-4 form-group">
                                <label>Last name</label>
                                <TextComponent
                                    ref={lastNameRef}
                                    type="text"
                                    className="form-control"
                                    placeholder
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <label>Contact Phone</label>
                                <TextComponent
                                    ref={phoneRef}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Full Address</label>
                                <TextComponent
                                    ref={fullAddressRef}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Pincode</label>
                                <PinCodeSelectionContainer
                                    inputRef={pincodeRef}
                                    TextComponentClassName="form-control"
                                    TupleComponentClassName="mt-3"
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label>State</label>
                                <StateDropdown
                                    selectedStateId={selectedState.state_id}
                                    onChange={(state) => {
                                        setSelectedState(state);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <button
                                    onClick={onCancel}
                                    type="button"
                                    className="btn btn-light"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="form-group ml-2">
                                <button
                                    onClick={onSubmitHandler}
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Add Address
                                </button>
                            </div>
                        </div>
                        <div
                            className={`alert alert-warning ${!message && 'd-none'}`}
                            role="alert"
                        >
                            {message}
                        </div>
                    </div>
                </article>
            </div>
        </section>
    );
});

NewAddressFormComponent.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default NewAddressFormComponent;
