import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchStateListAction } from 'src/actions/LocationActions';
import LinkComponent from '../common/LinkComponent';

const StateDropdown = ({
    selectedStateId,
    onChange,
}) => {
    const {
        states,
    } = useSelector((state) => state.locationReducer, shallowEqual);

    const [
        selectedState,
        setSelectedState,
    ] = useState('Choose State');

    const dispatch = useDispatch();

    // FETCH STATES
    useEffect(() => {
        if (!states.length) {
            dispatch(fetchStateListAction());
        }
    }, [
        states,
    ]);

    // SET SELECTED STATE
    useEffect(() => {
        const found = states.find((state) => state.state_id === selectedStateId) || {
            state_name: 'Choose State',
        };
        setSelectedState(found);
    }, [
        setSelectedState,
        selectedStateId,
        states,
    ]);

    return (
        <div className="dropdown show">
            <LinkComponent
                className="btn btn-light dropdown-toggle"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {selectedState.state_name}
            </LinkComponent>
            <div
                className="dropdown-menu dropdown-scroll"
                aria-labelledby="dropdownMenuLink"
            >
                {states.map((state) => (
                    <LinkComponent
                        key={state.state_id}
                        onClick={() => onChange(state)}
                        className="dropdown-item"
                    >
                        {state.state_name}
                    </LinkComponent>
                ))}
            </div>
        </div>

    );
};

StateDropdown.propTypes = {
    selectedStateId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

StateDropdown.defaultProps = {
    selectedStateId: 0,
};

export default StateDropdown;
