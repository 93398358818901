export const LAYOUT_ACTIONS = {
    FETCH_PAGE_BY_PINCODE: 'FETCH_PAGE_BY_PINCODE',
    FETCH_PAGE_BY_PINCODE_SUCCESS: 'FETCH_PAGE_BY_PINCODE_SUCCESS',
    FETCH_PAGE_BY_PINCODE_ERROR: 'FETCH_PAGE_BY_PINCODE_ERROR',
    RESET_PAGE_BY_PINCODE: 'RESET_PAGE_BY_PINCODE',
};

export const fetchPageByPincodeAction = () => ({
    type: LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE,
});

export const fetchPageByPincodeSuccessAction = (payload = {}) => ({
    type: LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE_SUCCESS,
    ...payload,
});

export const fetchPageByPincodeErrorAction = () => ({
    type: LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE_ERROR,
});

export const resetPageByPincodeAction = () => ({
    type: LAYOUT_ACTIONS.RESET_PAGE_BY_PINCODE,
});

export default {
    LAYOUT_ACTIONS,
    fetchPageByPincodeAction,
    fetchPageByPincodeSuccessAction,
    fetchPageByPincodeErrorAction,
    resetPageByPincodeAction,
};
