import { put, call, select } from 'redux-saga/effects';
import { getProductDetailsByPincodeAPI, getProductInfoAPI, getProductsByParentSku } from 'src/api/ProductApi';
import { PRODUCT_DETAILS_ACTIONS } from 'src/actions/ProductDetailsActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { assetApi } from 'src/api/AssetApi';
import { metaDataApi } from 'src/api/MetaApi';
import { productAggregatedReviewFetchAction } from 'src/actions/ProductReviewActions';
import { get } from 'lodash-es';

export function* fetchProductDetailsListSaga({
    productCode,
}) {
    try {
        const {
            locationReducer: {
                selectedPincode,
            },
        } = yield select();

        if (!selectedPincode) {
            return;
        }

        let productDetailsList = [];

        ({ data: { data: productDetailsList } } = yield call(getProductDetailsByPincodeAPI, {
            pincode: selectedPincode,
            productCode,
        }));

        // get product media
        const media = yield call(assetApi, {
            refCode: productCode,
        });

        // get product meta data
        const metaData = yield call(metaDataApi, {
            refCode: productCode,
        });

        // get available product color and size
        const { data: { data: productColorSizeCollection } } = yield call(getProductsByParentSku, {
            pincode: selectedPincode,
            parentSku: get(productDetailsList, '[0].parent_sku', ''),
        }) || [];

        // update aggregateRating
        yield put(productAggregatedReviewFetchAction({
            productCode,
        }));

        const additionalDetails = {
            productMedia: media.data.data,
            productMetaData: metaData.data.data,
            productColorSizeCollection,
        };

        // put products into store
        yield put({
            type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH_SUCCESS,
            productDetailsList,
            productCode,
            additionalDetails,
        });
    }
    catch (error) {
        console.error('fetchProductDetailsListSaga', error);
        yield put({
            type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH_ERROR,
            productCode,
        });
    }
}

export function* fetchProductDetailsSaga({
    productCode,
}) {
    try {
        const {
            data: {
                data: [
                    productDetails,
                ],
            },
        } = yield call(getProductInfoAPI, {
            productCode,
        });

        // get product media
        const media = yield call(assetApi, {
            refCode: productCode,
        });

        // get product meta data
        const metaData = yield call(metaDataApi, {
            refCode: productCode,
        });

        // update aggregateRating
        yield put(productAggregatedReviewFetchAction({
            productCode,
        }));

        const additionalDetails = {
            productMedia: media.data.data,
            productMetaData: metaData.data.data,
        };

        // put products into store
        yield put({
            type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH_SUCCESS,
            productDetails,
            productCode,
            additionalDetails,
        });
    }
    catch (error) {
        console.error('fetchProductDetailsSaga', error);
        yield put({
            type: PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH_ERROR,
            productCode,
        });
    }
}

export default [
    takeFirstSagaUtil(PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_LIST_FETCH, fetchProductDetailsListSaga),
    takeFirstSagaUtil(PRODUCT_DETAILS_ACTIONS.PRODUCT_DETAILS_FETCH, fetchProductDetailsSaga),
];
