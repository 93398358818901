export const CART_ACTIONS = {
    ADD_ITEM_IN_CART_INIT: 'ADD_ITEM_IN_CART_INIT',
    REMOVE_ITEM_FROM_CART_INIT: 'REMOVE_ITEM_FROM_CART_INIT',
    UPDATE_ITEMS_DATA_IN_CART: 'UPDATE_ITEMS_DATA_IN_CART',

    APPLY_COUPON_REQUEST: 'APPLY_COUPON_REQUEST',
    APPLY_COUPON_REQUEST_SUCCESS: 'APPLY_COUPON_REQUEST_SUCCESS',
    APPLY_COUPON_REQUEST_ERROR: 'APPLY_COUPON_REQUEST_ERROR',

    REMOVE_COUPON_REQUEST: 'REMOVE_COUPON_REQUEST',

    CHANGE_PAYMENT_METHOD: 'CHANGE_PAYMENT_METHOD',

    UPDATE_DELIVERY_ADDRESS: 'UPDATE_DELIVERY_ADDRESS',

    UPDATE_DELIVERY_DATE_TIME: 'UPDATE_DELIVERY_DATE_TIME',

    MAKE_PURCHASE_REQUEST: 'MAKE_PURCHASE_REQUEST',
    MAKE_PURCHASE_REQUEST_SUCCESS: 'MAKE_PURCHASE_REQUEST_SUCCESS',
    MAKE_PURCHASE_REQUEST_ERROR: 'MAKE_PURCHASE_REQUEST_ERROR',

    RESET_CART: 'RESET_CART',

    COUPONS_FETCH: 'COUPONS_FETCH',
    COUPONS_FETCH_SUCCESS: 'COUPONS_FETCH_SUCCESS',
    COUPONS_FETCH_ERROR: 'COUPONS_FETCH_ERROR',

    UPDATE_REMARKS: 'UPDATE_REMARKS',
};

export const couponsFetchAction = () => ({
    type: CART_ACTIONS.COUPONS_FETCH,
});

export const addItemAction = (payload) => ({
    type: CART_ACTIONS.ADD_ITEM_IN_CART_INIT,
    ...payload,
});

export const removeItemAction = (payload) => ({
    type: CART_ACTIONS.REMOVE_ITEM_FROM_CART_INIT,
    ...payload,
});

export const updateItemsDataAction = (payload) => ({
    type: CART_ACTIONS.UPDATE_ITEMS_DATA_IN_CART,
    ...payload,
});

export const applyCouponAction = ({ couponCode }) => ({
    type: CART_ACTIONS.APPLY_COUPON_REQUEST,
    couponCode,
});

export const removeCouponAction = () => ({
    type: CART_ACTIONS.REMOVE_COUPON_REQUEST,
});

export const changePaymentMethodAction = (payload) => ({
    type: CART_ACTIONS.CHANGE_PAYMENT_METHOD,
    ...payload,
});

export const makePurchaseAction = () => ({
    type: CART_ACTIONS.MAKE_PURCHASE_REQUEST,
});

export const deliveryAddressesUpdateAction = (payload) => ({
    type: CART_ACTIONS.UPDATE_DELIVERY_ADDRESS,
    ...payload,
});

export const deliveryDateTimeUpdateAction = (payload) => ({
    type: CART_ACTIONS.UPDATE_DELIVERY_DATE_TIME,
    ...payload,
});

export const resetCartAction = () => ({
    type: CART_ACTIONS.RESET_CART,
});

export const updateRemarksAction = (payload) => ({
    type: CART_ACTIONS.UPDATE_REMARKS,
    ...payload,
});

export default {
    CART_ACTIONS,
    addItemAction,
    removeItemAction,
    updateItemsDataAction,

    applyCouponAction,
    removeCouponAction,

    changePaymentMethodAction,

    makePurchaseAction,

    deliveryAddressesUpdateAction,

    deliveryDateTimeUpdateAction,

    resetCartAction,

    updateRemarksAction,
};
