import React, { useEffect } from 'react';
import CouponListingComponent from 'src/components/cart/CouponListingComponent';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { couponsFetchAction } from 'src/actions/CartActions';
import NoCouponComponent from 'src/components/cart/NoCouponComponent';

const CouponListingContainer = () => {
    // REDUX
    const dispatch = useDispatch();
    const { couponList, couponFetchStatus } = useSelector((state) => state.cartReducer, shallowEqual);

    // LIFE CYCLE
    useEffect(() => {
        dispatch(couponsFetchAction());
    }, [
        dispatch,
    ]);

    if (couponFetchStatus && couponList.length < 1) {
        return <NoCouponComponent />;
    }

    return (
        <CouponListingComponent
            coupons={couponList}
        />
    );
};

export default CouponListingContainer;
