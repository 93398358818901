import React, { useState, useEffect, useCallback } from 'react';
import LoginComponent from 'src/components/login/LoginComponent';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
    sendOtpAction, confirmCredentialsAction, googleLoginInitAction, googleLoginSuccessAction, googleLoginOneTapSuccessAction,
} from 'src/actions/AuthActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import GoogleOneTapSignIn from 'src/utils/GoogleOneTapSignIn';
import { useHistory, useLocation } from 'react-router';

function LoginContainer() {
    // HOOKS
    const history = useHistory();
    const location = useLocation();

    // REDUX
    const dispatch = useDispatch();
    const {
        isLoggedIn,
        otpSentStatus,
        loginConfirmationStatus,
    } = useSelector((state) => state.authReducer, shallowEqual);

    const {
        clientData: {
            google_client_id: googleClientID,
        },
    } = useSelector((state) => state.appReducer, shallowEqual);

    const {
        userDetails,
    } = useSelector((state) => state.userReducer, shallowEqual);

    // STATE
    const [
        googleLoginStatus,
        setGoogleLoginStatus,
    ] = useState(null);

    // LOCAL HANDLERS
    const resetMessageFields = useCallback(() => {
        setGoogleLoginStatus(null);
        dispatch(googleLoginInitAction());
    }, [
        dispatch,
        setGoogleLoginStatus,
    ]);

    const sendOTP = useCallback(({ phone }) => {
        if (!phone) {
            return;
        }
        resetMessageFields();
        dispatch(sendOtpAction({ phone }));
    }, [
        dispatch,
        resetMessageFields,
    ]);

    const loginHandler = useCallback(({
        identifier,
        password,
    }) => {
        if (!identifier) {
            return;
        }

        if (!password) {
            return;
        }

        resetMessageFields();
        dispatch(confirmCredentialsAction({
            identifier,
            password,
        }));
    }, [
        dispatch,
        resetMessageFields,
    ]);

    const googleLoginSuccessHandler = useCallback((response) => {
        console.debug(response);
        setGoogleLoginStatus(REQUEST_STATUS.SUCCESS);
        dispatch(googleLoginSuccessAction({ ...response }));
    }, [
        dispatch,
        setGoogleLoginStatus,
    ]);
    const googleLoginErrorHandler = useCallback((error) => {
        console.debug(error);
        setGoogleLoginStatus(REQUEST_STATUS.ERROR);
    }, [
        setGoogleLoginStatus,
    ]);
    const onOneTapLoginSuccessHandler = useCallback((response) => {
        console.debug(response);
        setGoogleLoginStatus(REQUEST_STATUS.SUCCESS);
        dispatch(googleLoginOneTapSuccessAction({ ...response }));
    }, [
        dispatch,
        setGoogleLoginStatus,
    ]);
    const onOneTapLoginErrorHandler = useCallback((error) => {
        console.debug(error);
        if (error && error.error !== 'idpiframe_initialization_failed') {
            setGoogleLoginStatus(REQUEST_STATUS.ERROR);
        }
    }, [
        setGoogleLoginStatus,
    ]);

    // any one can be true
    let message = '';
    let errorMessage = '';

    if (otpSentStatus === REQUEST_STATUS.SUCCESS) {
        message = 'OTP sent successful';
    }
    else if (otpSentStatus === REQUEST_STATUS.ERROR) {
        errorMessage = 'Unable to send OTP';
    }
    else if (loginConfirmationStatus === REQUEST_STATUS.SUCCESS) {
        message = 'Login successful';
    }
    else if (loginConfirmationStatus === REQUEST_STATUS.ERROR) {
        errorMessage = 'Login failed';
    }
    else if (googleLoginStatus === REQUEST_STATUS.SUCCESS) {
        message = 'Google Login successful';
    }
    // else if (googleLoginStatus === REQUEST_STATUS.ERROR) {
    //     errorMessage = 'Google Login failed';
    // }

    // LIFE CYCLE
    useEffect(() => {
        if (googleClientID) {
            // init google one tap on mount
            GoogleOneTapSignIn({
                clientId: googleClientID,
                onOneTapLoginSuccess: onOneTapLoginSuccessHandler,
                onOneTapLoginError: onOneTapLoginErrorHandler,
            });
        }
    }, [
        onOneTapLoginSuccessHandler,
        onOneTapLoginErrorHandler,
        googleClientID,
    ]);

    useEffect(() => {
        if (isLoggedIn) {
            // redirect to "from" state or home page
            const { from } = location.state || { from: { pathname: '/' } };
            history.replace(from);
        }
    }, [
        isLoggedIn,
        history,
        location,
    ]);

    return (
        <LoginComponent
            generateOtp={sendOTP}
            loginHandler={loginHandler}
            defaultIdentifier={userDetails.phone}
            message={message}
            errorMessage={errorMessage}
            googleClientID={googleClientID}
            googleSignInInit={resetMessageFields}
            onSuccessGoogleLogin={googleLoginSuccessHandler}
            onErrorGoogleLogin={googleLoginErrorHandler}
        />
    );
}

export default LoginContainer;
