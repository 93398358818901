import { put, call } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { ENUMS_ACTIONS, selectedDeliveryTypesUpdateAction } from 'src/actions/EnumsActions';
import { getDeliveryTypesAPI } from 'src/api/BillingApi';

export function* getDeliveryTypes() {
    try {
        const {
            data: {
                data: deliveryTypes,
            },
        } = yield call(getDeliveryTypesAPI);

        yield put({
            type: ENUMS_ACTIONS.DELIVERY_TYPES_FETCH_SUCCESS,
            deliveryTypes,
        });

        yield put(selectedDeliveryTypesUpdateAction({
            selectedDeliveryType: deliveryTypes[1],
        }));
    }
    catch (error) {
        console.error('getDeliveryTypes', error);
        yield put({
            type: ENUMS_ACTIONS.DELIVERY_TYPES_FETCH_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(ENUMS_ACTIONS.DELIVERY_TYPES_FETCH, getDeliveryTypes),
];
