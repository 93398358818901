/* eslint-disable */
import React from 'react';
import FullWidthCarouselComponent from '../tiles/FullWidthCarouselComponent';

const CarrouselRailComponent = ({
    title,
    children
}) => (
    <section className="section-content mb-4">
        <div className="container">
            <header className="section-heading">
                <h3 className="section-title">{title}</h3>
            </header>
            <FullWidthCarouselComponent>
                {children}
            </FullWidthCarouselComponent>
        </div>
    </section>
);
CarrouselRailComponent.propTypes = {

};

export default CarrouselRailComponent;
