/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import RatingComponent from 'src/components/common/RatingComponent';
import TextComponent from 'src/components/common/text-component/TextComponent';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { productReviewUpsertAction } from 'src/actions/ProductReviewActions';

const ProductReviewForm = ({
    productCode,
}) => {
    const dispatch = useDispatch();

    const {
        userDetails: {
            id: userId,
        },
    } = useSelector((state) => state.userReducer, shallowEqual);

    const userProductReview = useSelector((state) => state.productReviewReducer.reviewByproductCode[productCode]?.userReview, shallowEqual);

    const [
        rating,
        setRating,
    ] = React.useState(5);

    const [
        title,
        setTitle,
    ] = React.useState('');

    const [
        description,
        setDescription,
    ] = React.useState('');

    // if userProductReview change then update the title rating and description
    useEffect(() => {
        if (userProductReview?.id) {
            setTitle(userProductReview.title || '');
            setDescription(userProductReview.description || '');
            setRating(userProductReview.rating || 5);
        }
    }, [
        userProductReview,
    ]);

    // textarea on change handler
    const onDescriptionChangeHandler = (e) => setDescription(e.target.value);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();
        dispatch(productReviewUpsertAction({
            productCode,
            rating,
            title,
            description,
            id: userProductReview?.id,
        }));
    }, [
        title,
        description,
        rating,
        productCode,
    ]);

    if (!userId) {
        return null;
    }

    return (
        <div className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
            <h5 className="mb-3">Rate and Leave Comment</h5>
            <div className="mb-4">
                <RatingComponent
                    rating={rating}
                    canChange
                    onChange={setRating}
                />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className="form-group">
                    <TextComponent
                        className="form-control"
                        placeholder="Title"
                        type="text"
                        onValueChange={setTitle}
                        defaultValue={userProductReview?.title || ''}
                    />
                </div>
                {' '}
                <div className="form-group">
                    <textarea
                        className="form-control"
                        placeholder="Your Comment"
                        onChange={onDescriptionChangeHandler}
                        value={description}
                    />
                </div>
                <div className="form-group">
                    <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                    >
                        {' '}
                        Submit Comment
                    </button>
                </div>
            </form>
        </div>
    );
};

ProductReviewForm.propTypes = {
    productCode: PropTypes.string.isRequired,
};

export default ProductReviewForm;
