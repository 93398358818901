/* eslint-disable*/
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CategoryHeaderComponent from "src/components/navigation/CategoryHeaderComponent";
import { fetchPageByPincodeAction } from "src/actions/LayoutActions";
import { REQUEST_STATUS } from "src/enums/CommonEnums";
import RoutingUtil from "src/utils/RoutingUtil";
import { useHistory } from "react-router";
import RailFactory from "src/factories/RailFactory";
import { productFetchAction } from "src/actions/ProductActions";
import TitleSubtitleTile from "src/components/tiles/TitleSubtitleTile";

const CurationLayoutContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // get page data form redux
  const { pageByPincode, railIdsSortedByPosition, pageByPincodeRequestStatus } =
    useSelector((state) => state.layoutReducer, shallowEqual);

  const { categories, stores } = useSelector(
    (state) => state.productReducer,
    shallowEqual
  );

  // storing categories as array
  const categoryKeys = useMemo(() => {
    return Object.keys(categories || {});
  }, [categories]);

  // adding href click action
  const categoryList = useMemo(() => {
    return categoryKeys.map((category) => ({
      ...categories[category],
      to: RoutingUtil.gotoProductSearch({
        queryParams: {
          q: category,
        },
      }),
    }));
  }, [categories]);

  // FETCH PAGE DATA
  useEffect(() => {
    dispatch(fetchPageByPincodeAction());
  }, []);

  // redirect to search if no data on page
  useEffect(() => {
    if (
      !Object.keys(pageByPincode).length &&
      pageByPincodeRequestStatus === REQUEST_STATUS.SUCCESS
    ) {
      history.replace(RoutingUtil.gotoProductSearch());
    }
  }, [pageByPincode, pageByPincodeRequestStatus]);

  // if categories is not there then get products
  useEffect(() => {
      dispatch(productFetchAction());
  }, []);

  // rails data is present
  let railElements = null;

  if (railIdsSortedByPosition.length) {
    railElements = railIdsSortedByPosition.map((railId) =>
      RailFactory(pageByPincode[railId])
    );
  }

  //   const storeNames = Object.keys(stores || {});

  // if page data is not there then show shimmering
  if (
    !Object.keys(pageByPincode).length &&
    pageByPincodeRequestStatus === REQUEST_STATUS.SUCCESS
  ) {
    return null;
  }

  return (
    <>
      <CategoryHeaderComponent list={categoryList} />

      {railElements}

      {/* <div className="container">
                <div className="row">
                    {storeNames.map(storeKey => {
                        return <div className="col-md-2 col-6" key={storeKey}>
                            <TitleSubtitleTile
                                to={RoutingUtil.gotoProductSearch({
                                    queryParams: {
                                        q: storeKey
                                    }
                                })}
                                title={storeKey}
                                subTitle={`${stores[storeKey].count} Product(s)`}
                                addRandomColor
                            />
                        </div>
                    })}
                </div>
            </div> */}
    </>
  );
};

CurationLayoutContainer.propTypes = {};

export default CurationLayoutContainer;
