import PropTypes from 'prop-types';
import React, { memo, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { applyCouponAction, removeCouponAction } from 'src/actions/CartActions';
import ApplyCouponComponent from 'src/components/common/ApplyCouponComponent';

const ApplyCouponContainer = memo(({ coupon }) => {
    // REDUX
    const dispatch = useDispatch();
    const {
        appliedCoupon,
        applyCouponMessage,
        discount,
    } = useSelector((state) => state.cartReducer, shallowEqual);

    // METHODS
    const applyCoupon = useCallback((couponCode) => {
        dispatch(applyCouponAction({ couponCode }));
    }, [
        dispatch,
    ]);

    const removeCoupon = () => {
        dispatch(removeCouponAction());
    };

    // LIFE CYCLE
    useEffect(() => {
        if (coupon) {
            applyCoupon(coupon);
        }
    }, [
        coupon,
        applyCoupon,
    ]);

    return (
        <ApplyCouponComponent
            onCouponCodeApply={applyCoupon}
            appliedCoupon={appliedCoupon}
            applyCouponMessage={applyCouponMessage}
            isInvalid={!discount}
            discountAmount={discount}
            onCouponRemove={removeCoupon}
        />
    );
});

ApplyCouponContainer.propTypes = {
    coupon: PropTypes.string,
};

ApplyCouponContainer.defaultProps = {
    coupon: null,
};

export default ApplyCouponContainer;
