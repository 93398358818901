import { isIOS } from 'is-ios';
import isAndroid from 'is-android';
import isMobile from 'is-mobile';
import { clamp } from 'lodash-es';

export const isObject = (target) => Object.prototype.toString.call(target) === '[object Object]';

// eslint-disable-next-line no-nested-ternary
export const getChannelID = () => (isMobile() ? (isIOS ? 4 : (isAndroid ? 3 : 2)) : 2);

// eslint-disable-next-line no-nested-ternary
export const getPlatform = () => (isMobile() ? (isIOS ? 'IOS' : (isAndroid ? 'ANDROID' : 'WEB')) : 'WEB');

export const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const calculateBilling = (
    itemsInCart = [],
    discount = 0,
    deliveryCharges = 0,
    deliveryChargesWaveOff = 0,
) => {
    let total = 0;

    for (const key in itemsInCart) {
        if (Object.prototype.hasOwnProperty.call(itemsInCart, key)) {
            const item = itemsInCart[key];
            if (item.id) {
                const perItemPrice = item.final_selling_price * item.count;
                total += perItemPrice;
            }
        }
    }

    const totalAfterDiscount = total - discount;

    const totalAfterDeliveryAdjustment = (totalAfterDiscount + deliveryCharges) - deliveryChargesWaveOff;

    return {
        total,
        discount,
        totalAfterDiscount,
        totalAfterDeliveryAdjustment,
    };
};

export const valueAsPercentageOf = (value = 0, percentageOf = 0) => {
    const result = (clamp(value, 0, Infinity) / clamp(percentageOf, 0, Infinity)) * 100;
    if (Number.isNaN(result)) {
        return 0;
    }
    return result;
};
export const percentageToValue = (percentage = 0, valueOf = 0) => {
    const result = (clamp(valueOf, 0, Infinity) * clamp(percentage, 0, Infinity)) / 100;
    if (Number.isNaN(result)) {
        return 0;
    }
    return result;
};

export const clickedOutside = (evt, target) => {
    let targetElement = evt.target; // clicked element

    do {
        if (targetElement === target) {
            return true;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
    } while (targetElement);

    return false;
};

export const getLocation = (success, error) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        });
        return true;
    }

    return false;
};

// eslint-disable-next-line no-bitwise
export const getRandomLightColor = () => `hsla(${~~(360 * Math.random())},`
    + '70%,'
    + '80%,1)';

export const getRandomDarkColor = () => {
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
};

export const gotoViewPort = (element) => {
    if (element) {
        element.scrollIntoView();
    }
};

export const removeMultipleSpace = function (str) {
    return str.replace(/\s+/g, ' ');
};

export const trimString = function (str = '') {
    return removeMultipleSpace(str.toLowerCase().replace(/^\s+|\s+$/g, ''));
};

export const stringReplaceAll = function (str = '', search = '', replace = '') {
    return str.replace(new RegExp(search, 'g'), replace);
};

export const stringToKebabCase = (str) => {
    const noSpecialChar = str.replace(/[^a-zA-Z0-9]/g, '-');
    const trimmed = trimString(noSpecialChar);
    return stringReplaceAll(trimmed, ' ', '-');
};

export default {
    isObject,
    getChannelID,
    getPlatform,
    groupBy,
    calculateBilling,
    valueAsPercentageOf,
    percentageToValue,
    clickedOutside,
    getLocation,
    getRandomLightColor,
    getRandomDarkColor,
    gotoViewPort,
    removeMultipleSpace,
    trimString,
    stringReplaceAll,
    stringToKebabCase,
};
