import React from 'react';
import PropTypes from 'prop-types';
import RatingComponent from 'src/components/common/RatingComponent';

const ReviewsProgressChart = ({
    avg_rating,
    five,
    four,
    one,
    three,
    total,
    two,
}) => (
    <div className="bg-white rounded shadow-sm p-4 mb-4 clearfix graph-star-rating">
        <h5 className="mb-0 mb-4">Ratings and Reviews</h5>
        <div className="graph-star-rating-header">
            <div className="star-rating">
                <RatingComponent rating={avg_rating || 0} />
                {' '}
                <b className="text-black ml-2">{total || 'No Ratings'}</b>
            </div>
            <p className="text-black mb-4 mt-2">
                Rated
                {' '}
                {avg_rating || '0'}
                {' '}
                out of 5
            </p>
        </div>

        <div className="graph-star-rating-body">
            <div className="rating-list">
                <div className="rating-list-left text-black">
                    5 Star
                </div>
                <div className="rating-list-center">
                    <div className="progress">
                        <div
                            style={{ width: `${five}%` }}
                            aria-valuemax={5}
                            aria-valuemin={0}
                            aria-valuenow={5}
                            role="progressbar"
                            className="progress-bar bg-primary"
                        >
                            <span className="sr-only">
                                {five}
                                % Complete (danger)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="rating-list-right text-black">
                    {five}
                    %
                </div>
            </div>
            <div className="rating-list">
                <div className="rating-list-left text-black">
                    4 Star
                </div>
                <div className="rating-list-center">
                    <div className="progress">
                        <div
                            style={{ width: `${four}%` }}
                            aria-valuemax={5}
                            aria-valuemin={0}
                            aria-valuenow={5}
                            role="progressbar"
                            className="progress-bar bg-primary"
                        >
                            <span className="sr-only">
                                {four}
                                % Complete (danger)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="rating-list-right text-black">
                    {four}
                    %
                </div>
            </div>
            <div className="rating-list">
                <div className="rating-list-left text-black">
                    3 Star
                </div>
                <div className="rating-list-center">
                    <div className="progress">
                        <div
                            style={{ width: `${three}%` }}
                            aria-valuemax={5}
                            aria-valuemin={0}
                            aria-valuenow={5}
                            role="progressbar"
                            className="progress-bar bg-primary"
                        >
                            <span className="sr-only">
                                {three}
                                % Complete (danger)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="rating-list-right text-black">
                    {three}
                    %
                </div>
            </div>
            <div className="rating-list">
                <div className="rating-list-left text-black">
                    2 Star
                </div>
                <div className="rating-list-center">
                    <div className="progress">
                        <div
                            style={{ width: `${two}%` }}
                            aria-valuemax={5}
                            aria-valuemin={0}
                            aria-valuenow={5}
                            role="progressbar"
                            className="progress-bar bg-primary"
                        >
                            <span className="sr-only">
                                {two}
                                % Complete (danger)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="rating-list-right text-black">
                    {two}
                    %
                </div>
            </div>
            <div className="rating-list">
                <div className="rating-list-left text-black">
                    1 Star
                </div>
                <div className="rating-list-center">
                    <div className="progress">
                        <div
                            style={{ width: `${one}%` }}
                            aria-valuemax={5}
                            aria-valuemin={0}
                            aria-valuenow={5}
                            role="progressbar"
                            className="progress-bar bg-primary"
                        >
                            <span className="sr-only">
                                {one}
                                % Complete (danger)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="rating-list-right text-black">
                    {one}
                    %
                </div>
            </div>
        </div>
        {/* <div className="graph-star-rating-footer text-center mt-3 mb-3">
            <button
                type="button"
                className="btn btn-outline-primary btn-sm"
            >
                Rate and Review
            </button>
        </div> */}
    </div>
);

ReviewsProgressChart.propTypes = {
    avg_rating: PropTypes.number,
    five: PropTypes.number,
    four: PropTypes.number,
    one: PropTypes.number,
    three: PropTypes.number,
    total: PropTypes.number,
    two: PropTypes.number,
};

ReviewsProgressChart.defaultProps = {
    avg_rating: 0,
    five: 0,
    four: 0,
    one: 0,
    three: 0,
    total: 0,
    two: 0,
};

export default ReviewsProgressChart;
