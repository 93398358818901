import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const searchSuggestionsApi = function ({
    q,
    pincode,
}) {
    const url = `${config.baseUrl}search/product/suggestions/${pincode}`;
    return getRequest({
        url,
        params: {
            q,
        },
    });
};

export default {
    searchSuggestionsApi,
};
