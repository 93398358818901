/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import LinkComponent from './LinkComponent';

const ButtonWithCountDownComponent = memo(({
    children,
    onClick,
    countDownMilliseconds,
    ...rest
}) => {
    const [
        countDownKey,
        setCountDownKey,
    ] = useState(null);

    const onClickHandler = (e) => {
        let startCountDown = true;
        if (onClick) {
            startCountDown = onClick(e);
        }

        if (startCountDown !== false) {
            setCountDownKey({});
        }
    };

    const renderer = ({
        seconds, completed,
    }) => {
        if (completed || !countDownKey) {
            // Render a completed state
            if (completed) {
                setCountDownKey(null);
            }
            return (
                <LinkComponent
                    onClick={onClickHandler}
                    {...rest}
                >
                    {children}
                </LinkComponent>
            );
        }

        // Render a countdown
        return (
            <LinkComponent
                {...rest}
            >
                <span>
                    wait
                    {' '}
                    {seconds}
                    {' '}
                    seconds
                </span>
            </LinkComponent>
        );
    };

    return (
        <Countdown
            autoStart={!!countDownKey}
            key={countDownKey}
            date={Date.now() + countDownMilliseconds}
            renderer={renderer}
        />
    );
});
ButtonWithCountDownComponent.propTypes = {
    children: PropTypes.any,
    countDownMilliseconds: PropTypes.number,
    onClick: PropTypes.func,
};
ButtonWithCountDownComponent.defaultProps = {
    children: null,
    countDownMilliseconds: 59 * 1000,
    onClick: null,
};

export default ButtonWithCountDownComponent;
