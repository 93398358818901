import { CART_ACTIONS } from 'src/actions/CartActions';
import { PAYMENT_METHODS } from 'src/constants/PaymentConstants';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';
import { AUTH_ACTIONS } from 'src/actions/AuthActions';
import { calculateBilling } from 'src/utils/CommonUtils';

const getInitialState = () => ({
    total: 0,
    discount: 0,
    totalAfterDiscount: 0,
    orderTotalDeliveryCharges: 0,
    orderTotalDeliveryChargeWavedOff: 0,
    totalAfterDeliveryAdjustment: 0,

    appliedCoupon: '',
    applyCouponMessage: '',
    payMethod: PAYMENT_METHODS.ONLINE,
    purchaseRequestStatus: null,
    purchaseErrorList: [],
    storeOrderDetails: {}, // {storeId: {minOrderReached: false, deliveryCharges: 100, total: 200, waveOffDeliveryCharges: 100}}

    couponList: [],
    couponFetchStatus: null,

    itemsInCart: {
        length: 0,
    },

    deliveryAddress: null,
    deliveryDateTime: new Date(new Date().setHours(new Date().getHours() + 2)).getTime(),
    remarks: '',
});

const initialState = getInitialState();

const cartReducer = (state = initialState, {
    type,
    discount,
    appliedCoupon,
    applyCouponMessage,
    payMethod,
    deliveryAddress,
    deliveryDateTime,
    purchaseErrorList,
    couponList,
    remarks,
    addRemoveItemState,
}) => {
    let newState = {
        ...state,
        itemsInCart: { ...state.itemsInCart },
        purchaseErrorList: [],
    };

    switch (type) {
        // COUPONS
        case CART_ACTIONS.COUPONS_FETCH: {
            return {
                ...state,
                couponFetchStatus: null,
            };
        }
        case CART_ACTIONS.COUPONS_FETCH_SUCCESS: {
            return {
                ...state,
                couponList,
                couponFetchStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CART_ACTIONS.COUPONS_FETCH_ERROR: {
            return {
                ...state,
                couponFetchStatus: REQUEST_STATUS.ERROR,
            };
        }

        // ADD REMOVE ITEMS
        case CART_ACTIONS.UPDATE_ITEMS_DATA_IN_CART: {
            return {
                ...newState,
                ...addRemoveItemState,
            };
        }

        // COUPON
        case CART_ACTIONS.APPLY_COUPON_REQUEST: {
            newState = {
                ...newState,
                appliedCoupon: '',
                applyCouponMessage: '',
                discount: 0,
                ...calculateBilling(newState.itemsInCart, 0, newState.orderTotalDeliveryCharges, newState.orderTotalDeliveryChargeWavedOff),
            };
            return newState;
        }
        case CART_ACTIONS.APPLY_COUPON_REQUEST_SUCCESS: {
            newState.discount = discount;
            newState = {
                ...newState,
                ...calculateBilling(newState.itemsInCart, newState.discount, newState.orderTotalDeliveryCharges, newState.orderTotalDeliveryChargeWavedOff),
                appliedCoupon,
                applyCouponMessage,
            };
            return newState;
        }
        case CART_ACTIONS.APPLY_COUPON_REQUEST_ERROR: {
            newState = {
                ...newState,
                ...calculateBilling(newState.itemsInCart, 0, newState.orderTotalDeliveryCharges, newState.orderTotalDeliveryChargeWavedOff),
                discount: 0,
                appliedCoupon,
                applyCouponMessage,
            };
            return newState;
        }
        case CART_ACTIONS.REMOVE_COUPON_REQUEST: {
            newState = {
                ...newState,
                ...calculateBilling(newState.itemsInCart, 0, newState.orderTotalDeliveryCharges, newState.orderTotalDeliveryChargeWavedOff),
                discount: 0,
                appliedCoupon: '',
                applyCouponMessage: '',
            };
            return newState;
        }

        // PAYMENT METHOD
        case CART_ACTIONS.CHANGE_PAYMENT_METHOD: {
            newState = {
                ...newState,
                payMethod,
            };
            return newState;
        }

        // DELIVERY ADDRESS
        case CART_ACTIONS.UPDATE_DELIVERY_ADDRESS: {
            newState = {
                ...newState,
                deliveryAddress,
            };
            return newState;
        }

        // DELIVERY DATE TIME
        case CART_ACTIONS.UPDATE_DELIVERY_DATE_TIME: {
            newState = {
                ...newState,
                deliveryDateTime,
            };
            return newState;
        }

        // PURCHASE
        case CART_ACTIONS.MAKE_PURCHASE_REQUEST: {
            newState = {
                ...newState,
                purchaseRequestStatus: null,
                purchaseErrorList: [],
            };
            return newState;
        }
        case CART_ACTIONS.MAKE_PURCHASE_REQUEST_SUCCESS: {
            newState = {
                ...newState,
                purchaseRequestStatus: REQUEST_STATUS.SUCCESS,
                purchaseErrorList: [],
            };
            return newState;
        }
        case CART_ACTIONS.MAKE_PURCHASE_REQUEST_ERROR: {
            newState = {
                ...newState,
                purchaseRequestStatus: REQUEST_STATUS.ERROR,
                purchaseErrorList,
            };
            return newState;
        }

        // RESET CART
        case CART_ACTIONS.RESET_CART: {
            return getInitialState();
        }

        case AUTH_ACTIONS.LOGOUT: {
            return getInitialState();
        }

        // UPDATE REMARKS
        case CART_ACTIONS.UPDATE_REMARKS: {
            return {
                ...state,
                remarks,
            };
        }
        default:
            return state;
    }
};

const beforeSettingInitialState = (state) => ({
    ...state,
    ...calculateBilling(state.itemsInCart, 0, state.orderTotalDeliveryCharges, state.orderTotalDeliveryChargeWavedOff),
});

export default ReducerSyncWithStorage(cartReducer, {
    keysToBeSync: [
        'itemsInCart',
        'total',
        'storeOrderDetails',
        'orderTotalDeliveryCharges',
        'orderTotalDeliveryChargeWavedOff',
    ],
    initialState,
    storageKeyName: 'cartReducer',
    storage: localStorage,
    beforeSettingInitialState,
});
