import {
    string,
    number,
    shape,
} from 'prop-types';

export const ProductItemPropType = shape({
    category: string,
    description: string,
    expire_days: number,
    final_selling_price: number,
    gst_tax: number,
    id: number,
    img_url: string,
    product_code: string,
    product_name: string,
    product_type_id: number,
    total_price_without_tax: number,
    usage_limit: number,
    version: number,
});

export default {
    ProductItemPropType,
};
