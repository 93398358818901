/* eslint-disable */
export default function SlickNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                right: '20px',
            }}
            onClick={onClick}
        />
    );
}