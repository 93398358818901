/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkComponent from 'src/components/common/LinkComponent';
import { DEFAULT_PRODUCT_IMAGE } from 'src/constants/UiConstants';

const ProductMediaPanel = ({
    mediaList,
}) => {
    const [
        selectedImage,
        setSelectedImage,
    ] = useState(DEFAULT_PRODUCT_IMAGE);

    const rail = mediaList.map((item) => (
        <LinkComponent
            key={item.url}
            onClick={() => {
                setSelectedImage(item);
            }}
            className="item-thumb position-relative"
        >
            <img
                className="centered"
                src={item.url}
            />
        </LinkComponent>
    ));

    return (
        <article className="gallery-wrap">
            <div className="img-big-wrap ">
                <div>
                    <LinkComponent>
                        <img
                            // className="img-fluid"
                            src={selectedImage?.url || mediaList[0]?.url || DEFAULT_PRODUCT_IMAGE}
                        />
                    </LinkComponent>
                </div>
            </div>
            <div className="thumbs-wrap">
                {rail}
            </div>
        </article>
    );
};

ProductMediaPanel.propTypes = {
    mediaList: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
    })),
};

ProductMediaPanel.defaultProps = {
    mediaList: [],
};

export default ProductMediaPanel;
