import { LAYOUT_ACTIONS } from 'src/actions/LayoutActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';

const initialState = {
    pageByPincode: {},
    railIdsSortedByPosition: [],
    pageByPincodeRequestStatus: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE: {
            return {
                ...state,
                pageByPincodeRequestStatus: REQUEST_STATUS.PENDING,
            };
        }
        case LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE_SUCCESS: {
            return {
                ...state,
                pageByPincodeRequestStatus: REQUEST_STATUS.SUCCESS,
                pageByPincode: action.pageByPincode,
                railIdsSortedByPosition: action.railIdsSortedByPosition,
            };
        }
        case LAYOUT_ACTIONS.FETCH_PAGE_BY_PINCODE_ERROR: {
            return {
                ...state,
                pageByPincodeRequestStatus: REQUEST_STATUS.ERROR,
                railIdsSortedByPosition: [],
            };
        }
        case LAYOUT_ACTIONS.RESET_PAGE_BY_PINCODE: {
            return {
                ...state,
                pageByPincode: {},
                railIdsSortedByPosition: [],
                pageByPincodeRequestStatus: null,
            };
        }
        default:
            return state;
    }
};
