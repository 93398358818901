export const ORDER_ACTIONS = {
    ORDER_DETAILS_FETCH: 'ORDER_DETAILS_FETCH',
    ORDER_DETAILS_FETCH_SUCCESS: 'ORDER_DETAILS_FETCH_SUCCESS',
};

export const orderDetailsFetchAction = (payload = {}) => ({
    type: ORDER_ACTIONS.ORDER_DETAILS_FETCH,
    ...payload,
});

export default {
    ORDER_ACTIONS,
    orderDetailsFetchAction,
};
