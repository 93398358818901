/* eslint-disable */
import React from 'react';
import CarrouselMultipleTileRailComponent from 'src/components/rails/CarrouselMultipleTileRailComponent';
import CarrouselRailComponent from 'src/components/rails/CarrouselRailComponent';
import LogWithSubTitleTileComponent from 'src/components/rails/LogWithSubTitleTileComponent';
import NoScrollRailComponent from 'src/components/rails/NoScrollRailComponent';
import NoScrollRailWithHeader from 'src/components/rails/NoScrollRailWithHeader';
import BannerComponent from 'src/components/tiles/BannerComponent';
import ImgTile from 'src/components/tiles/ImgTile';
import SpecialPointsTileComponent from 'src/components/tiles/SpecialPointsTileComponent';
import SquareInfoTileComponent from 'src/components/tiles/SquareInfoTileComponent';
import SquareTileComponent from 'src/components/tiles/SquareTileComponent';
import { TILE_TYPES } from 'src/constants/LayoutConstants';

const RailFactory = (props) => {
    const {
        page_id,
        rail_id,
        title,
        tile_type,
        tiles,
    } = props;

    switch (tile_type) {
        case TILE_TYPES.BANNER: {
            return <BannerComponent
                key={tiles[0]?.tile_id}
                imgUrl={tiles[0]?.img_url}
                to={tiles[0]?.to}
            />;
        }
        case TILE_TYPES.INFO: {
            return (
                <NoScrollRailComponent key={`${page_id}+${rail_id}`}>
                    {tiles.map(({
                        tile_title,
                        description,
                        icon_class,
                        tile_id,
                        to
                    }) => {
                        return <SpecialPointsTileComponent
                            key={tile_id}
                            faIcon={icon_class}
                            title={tile_title}
                            description={description}
                            to={to}
                        />
                    })}
                </NoScrollRailComponent>
            );
        }
        case TILE_TYPES.CAROUSEL: {
            return <CarrouselMultipleTileRailComponent title={title} key={`${page_id}+${rail_id}`}>
                {tiles.map(({
                    tile_id,
                    img_url,
                    product_code,
                    to,
                }) => (<ImgTile
                    key={tile_id}
                    imgUrl={img_url}
                    alt={product_code}
                    to={to}
                />))}
            </CarrouselMultipleTileRailComponent>;
        }
        case TILE_TYPES.CAROUSEL_FULL_HEIGHT_WIDTH: {
            return <CarrouselRailComponent title={title} key={`${page_id}+${rail_id}`}>
                {tiles.map(({
                    tile_id,
                    img_url,
                    product_code,
                    to,
                }) => (<ImgTile
                    key={tile_id}
                    imgUrl={img_url}
                    alt={product_code}
                    to={to}
                />))}
            </CarrouselRailComponent>;
        }
        case TILE_TYPES.GRID_PRODUCT_INFO: {
            return (
                <NoScrollRailWithHeader title={title} key={`${page_id}+${rail_id}`}>
                    {tiles.map(({
                        img_url,
                        tile_id,
                        to,
                        product_name,
                    }) => (
                        <SquareInfoTileComponent
                            product_name={product_name}
                            key={tile_id}
                            imgUrl={img_url}
                            to={to}
                        />
                    ))}
                </NoScrollRailWithHeader>
            );
        }
        case TILE_TYPES.GRID_NORMAL: {
            return (
                <NoScrollRailWithHeader title={title} key={`${page_id}+${rail_id}`}>
                    {tiles.map(({
                        img_url,
                        tile_id,
                        to
                    }) => (
                        <SquareTileComponent
                            key={tile_id}
                            imgUrl={img_url}
                            to={to}
                        />
                    ))}
                </NoScrollRailWithHeader>
            );
        }
        case TILE_TYPES.SMALL_GIRD_IMG_INFO: {
            return (
                <NoScrollRailWithHeader title={title} key={`${page_id}+${rail_id}`}>
                    {tiles.map(({
                        tile_id,
                        tile_title,
                        img_url,
                        to,
                    }) => (<LogWithSubTitleTileComponent
                        key={tile_id}
                        title={tile_title}
                        imgUrl={img_url}
                        to={to}
                    />))}
                </NoScrollRailWithHeader>
            );
        }
        default:
            return null;
    }
};

export default RailFactory;
