import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserAddressPropType } from 'src/constants/prop-types/UserPropTypes';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchStateListAction } from 'src/actions/LocationActions';
import LinkComponent from '../LinkComponent';

const AddressCardComponent = ({
    selectedAddress,
    disabledButtonText,
    selectedButtonText,
    unSelectedButtonText,
    addressDetails,
    onSelect,
    onUnSelect,
    disabled,
}) => {
    const {
        address,
        contact_person: ContactPerson,
        phone,
        pincode,
        state_id,
    } = addressDetails;

    const dispatch = useDispatch();
    const [
        selectedState,
        setSelectedState,
    ] = useState('');

    const {
        states,
    } = useSelector((state) => state.locationReducer, shallowEqual);

    // FETCH STATES
    useEffect(() => {
        if (!states.length) {
            dispatch(fetchStateListAction());
        }
        else {
            const found = states.find((state) => state.state_id === state_id) || {
                state_name: '',
            };
            setSelectedState(found);
        }
    }, [
        states,
    ]);

    let actionButton = null;

    if (disabled) {
        actionButton = (
            <LinkComponent
                className="btn btn-disabled disabled"
            >
                {disabledButtonText}
            </LinkComponent>
        );
    }
    else if (selectedAddress) {
        actionButton = (
            <LinkComponent
                className="btn btn-light disabled"
                onClick={onUnSelect}
            >
                {' '}
                <i className="fa fa-check" />
                {' '}
                {selectedButtonText}
            </LinkComponent>
        );
    }
    else {
        actionButton = (
            <LinkComponent
                className="btn btn-light"
                onClick={onSelect}
            >
                {unSelectedButtonText}
            </LinkComponent>
        );
    }

    return (
        <article className="box mb-3">
            <h6>
                {ContactPerson}
            </h6>
            <p>
                {phone}
            </p>
            <p>
                {address}
            </p>
            <pre>
                {pincode}
            </pre>
            {
                selectedState.state_name ? (
                    <pre>
                        {selectedState.state_name}
                    </pre>
                ) : null
            }
            {actionButton}
            {/* <LinkComponent
                className="btn btn-light"
            >
                {' '}
                <i className="fa fa-pen" />
                {' '}
            </LinkComponent>
            {' '}
            <LinkComponent
                className="btn btn-light"
            >
                {' '}
                <i className="text-danger fa fa-trash" />
            </LinkComponent> */}
        </article>
    );
};

AddressCardComponent.propTypes = {
    selectedAddress: PropTypes.bool,
    disabledButtonText: PropTypes.string,
    selectedButtonText: PropTypes.string,
    unSelectedButtonText: PropTypes.string,
    addressDetails: UserAddressPropType,
    onSelect: PropTypes.func,
    onUnSelect: PropTypes.func,
    disabled: PropTypes.bool,
};

AddressCardComponent.defaultProps = {
    selectedAddress: false,
    disabledButtonText: 'Disabled',
    selectedButtonText: 'Default',
    unSelectedButtonText: 'Make Default',
    addressDetails: {},
    onSelect: null,
    onUnSelect: null,
    disabled: false,
};

export default AddressCardComponent;
