import React, { useCallback, useEffect, useState } from 'react';
import CartComponent from 'src/components/cart/CartComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import EmptyCartComponent from 'src/components/cart/EmptyCartComponent';
import { paymentTypesFetchAction } from 'src/actions/PaymentActions';
import { deliveryTypesFetchAction, selectedDeliveryTypesUpdateAction } from 'src/actions/EnumsActions';
import { deliveryDateTimeUpdateAction, updateRemarksAction } from 'src/actions/CartActions';
import { useLocation } from 'react-router';
import { QUERY_PARAMS } from 'src/constants/RoutingConstants';
import { USER_ADDRESS_MANDATORY } from 'src/constants/PaymentConstants';

const CartContainer = () => {
    const location = useLocation();

    // STATE
    const [
        couponFromUrl,
        setCouponFromUrl,
    ] = useState(null);

    // REDUX
    const dispatch = useDispatch();

    // get items form redux
    const {
        deliveryDateTime,
        itemsInCart: {
            length,
            ...products
        },
    } = useSelector((state) => state.cartReducer, shallowEqual);

    // get selected delivery type from redux
    const {
        selectedDeliveryType,
    } = useSelector((state) => state.enumReducer, shallowEqual);

    // get payment types from redux
    const {
        paymentTypes,
        paymentTypesFetchStatus,
    } = useSelector((state) => state.paymentReducer, shallowEqual);

    // get delivery types from redux
    const {
        deliveryTypes,
        deliveryTypesFetchStatus,
    } = useSelector((state) => state.enumReducer, shallowEqual);

    // get payment types if not in redux
    useEffect(() => {
        if (paymentTypes.length < 1 && !paymentTypesFetchStatus) {
            dispatch(paymentTypesFetchAction());
        }
    }, [
        paymentTypes,
        paymentTypesFetchStatus,
        dispatch,
    ]);

    // get delivery types if not in redux
    useEffect(() => {
        if (deliveryTypes.length < 1 && !deliveryTypesFetchStatus) {
            dispatch(deliveryTypesFetchAction());
        }
    }, [
        deliveryTypes,
        deliveryTypesFetchStatus,
        dispatch,
    ]);

    // get coupon from url
    useEffect(() => {
        const coupon = new URLSearchParams(location.search).get(QUERY_PARAMS.COUPON);
        if (coupon) {
            setCouponFromUrl(coupon);
        }
    }, [
        location,
        setCouponFromUrl,
    ]);

    // METHODS
    const setSelectedDeliveryDate = useCallback((newDeliveryDateTime) => {
        dispatch(deliveryDateTimeUpdateAction({
            deliveryDateTime: newDeliveryDateTime.getTime(),
        }));
    }, [
        dispatch,
    ]);

    // update remark
    const onRemarkChangeHandler = useCallback((remarks) => {
        dispatch(updateRemarksAction({ remarks }));
    }, [
        dispatch,
    ]);

    // delivery type change
    const deliveryTypeChangeHandler = useCallback((deliveryType) => {
        dispatch(selectedDeliveryTypesUpdateAction({
            selectedDeliveryType: deliveryType,
        }));
    }, [
        dispatch,
    ]);

    // RENDERING
    if (!length) {
        return <EmptyCartComponent />;
    }

    const minDate = new Date().setDate(new Date().getDate());
    const maxDate = new Date().setDate(new Date().getDate() + 15);

    // user address selection needed
    const isUserAddressNeeded = !!USER_ADDRESS_MANDATORY[selectedDeliveryType?.id];

    return (
        <CartComponent
            items={products}
            minDeliveryDate={minDate}
            selectedDeliveryDate={deliveryDateTime}
            maxDeliveryDate={maxDate}
            onDeliveryDateChange={setSelectedDeliveryDate}
            coupon={couponFromUrl}
            onRemarksChange={onRemarkChangeHandler}
            deliveryTypes={deliveryTypes}
            deliveryTypeChange={deliveryTypeChangeHandler}
            selectedDeliveryType={selectedDeliveryType}
            isUserAddressNeeded={isUserAddressNeeded}
        />
    );
};

export default CartContainer;
