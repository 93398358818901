import { combineReducers } from 'redux';
import authReducer from './AuthReducer';
import productReducer from './ProductReducer';
import cartReducer from './CartReducer';
import userReducer from './UserReducer';
import storeReducer from './StoresReducer';
import paymentReducer from './PaymentReducer';
import enumReducer from './EnumReducer';
import appReducer from './AppReducer';
import orderReducer from './OrderReducer';
import locationReducer from './LocationReducer';
import searchReducer from './SearchReducer';
import layoutReducer from './LayoutReducer';
import productDetailsReducer from './ProductDetailsReducer';
import productReviewReducer from './ProductReviewReducer';

export default combineReducers({
    authReducer,
    productReducer,
    cartReducer,
    userReducer,
    storeReducer,
    paymentReducer,
    enumReducer,
    appReducer,
    orderReducer,
    locationReducer,
    searchReducer,
    layoutReducer,
    productDetailsReducer,
    productReviewReducer,
});
