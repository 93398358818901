// date range: http://adphorus.github.io/react-date-range/#calendar
// date time picker: https://material-ui-pickers.dev/api/DateTimePicker
import React, { memo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DateTimePickerComponent = memo(({
    onAccept,
    minDate,
    selectedDate,
    maxDate,
    label,
    disableFuture,
    disablePast,
    showTimeSelector,
    openTo,
}) => {
    const onDateAccept = (value) => {
        onAccept(new Date(value));
    };
    if (showTimeSelector) {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    variant="inline"
                    label={label}
                    value={selectedDate}
                    autoOk
                    maxDate={maxDate}
                    onAccept={onDateAccept}
                    minDate={minDate}
                    style={{
                        width: '180px',
                    }}
                    onChange={onDateAccept}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    openTo={openTo}
                // disableToolbar
                />
            </MuiPickersUtilsProvider>
        );
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                variant="inline"
                label={label}
                value={selectedDate}
                autoOk
                maxDate={maxDate}
                onAccept={onDateAccept}
                minDate={minDate}
                style={{
                    width: '180px',
                }}
                onChange={onDateAccept}
                disablePast={disablePast}
                disableFuture={disableFuture}
                openTo={openTo}
                format="dd-MMM-yyyy"
            />
        </MuiPickersUtilsProvider>
    );
});

DateTimePickerComponent.propTypes = {
    onAccept: PropTypes.func,
    minDate: PropTypes.number,
    selectedDate: PropTypes.number,
    maxDate: PropTypes.number,
    label: PropTypes.string,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    showTimeSelector: PropTypes.bool,
    openTo: PropTypes.string,
};

DateTimePickerComponent.defaultProps = {
    onAccept: null,
    minDate: 1798,
    selectedDate: null,
    maxDate: 4102444800000,
    label: 'Select Date',
    disableFuture: null,
    disablePast: null,
    showTimeSelector: true,
    openTo: '',
};

export default DateTimePickerComponent;
