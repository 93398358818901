import { PRODUCT_ACTIONS } from 'src/actions/ProductActions';
import { REQUEST_STATUS } from 'src/enums/CommonEnums';

const initialState = () => ({
    products: [],
    categories: {},
    stores: {},
    productFetchStatus: null,
});

const resetState = {
    productFetchStatus: null,
};

export default (state = initialState(), action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case PRODUCT_ACTIONS.PRODUCT_FETCH: {
            return newState;
        }
        case PRODUCT_ACTIONS.PRODUCT_FETCH_SUCCESS: {
            newState = {
                ...newState,
                products: action.products,
                categories: action.categories,
                stores: action.stores,
                totalProducts: action.totalProducts,
                productFetchStatus: REQUEST_STATUS.SUCCESS,
            };
            break;
        }
        case PRODUCT_ACTIONS.PRODUCT_FETCH_ERROR: {
            newState = {
                ...newState,
                productFetchStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }
        case PRODUCT_ACTIONS.PRODUCT_RESET: {
            newState = {
                ...state,
                products: [],
            };
            break;
        }
        default:
            return state;
    }

    return newState;
};
