/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const RatingComponent = ({
    rating,
    onChange,
    canChange,
}) => {
    const halfStar = (rating % 1 !== 0) ? [
        <i
            className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
            key="half"
        />,
        ' ',
    ] : null;
    const flooredRating = Math.floor(rating || 0);

    const stars = Array.from({ length: flooredRating }, (v, i) => [
        <i
            className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
            key={i}
            onClick={() => onChange(i + 1)}
        />,
        ' ',
    ]);

    return (
        <ul className="rating-stars">
            <li
                className="stars-active"
            >
                {stars}
                {halfStar}
            </li>
            <li>
                <i
                    className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
                    onClick={() => onChange(1)}
                />
                {' '}
                <i
                    className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
                    onClick={() => onChange(2)}
                />
                {' '}
                <i
                    className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
                    onClick={() => onChange(3)}
                />
                {' '}
                <i
                    className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
                    onClick={() => onChange(4)}
                />
                {' '}
                <i
                    className={`fa fa-star ${canChange ? 'hand-cursor' : ''}`}
                    onClick={() => onChange(5)}
                />
            </li>
        </ul>
    );
};

RatingComponent.propTypes = {
    rating: PropTypes.number,
    onChange: PropTypes.func,
    canChange: PropTypes.bool,
};

RatingComponent.defaultProps = {
    rating: 0,
    onChange: () => { },
    canChange: false,
};

export default RatingComponent;
