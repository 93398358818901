import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../common/LinkComponent';

const ProductStackHeader = memo(forwardRef(({
    total,
    onClearFilter,
    clearButtonVisibility,
    clearButtonLabel,
    searchQuery,
}, ref) => (
    <header
        className="mb-3"
        ref={ref}
        style={{
            overflow: 'auto',
        }}
    >
        {/* <div className="form-inline">
            <strong className="mr-md-auto">
                {total}
                {' '}
                Items found
                {' '}
            </strong>
            <select className="mr-2 form-control">
                <option>Latest items</option>
                <option>Trending</option>
                <option>Most Popular</option>
                <option>Cheapest</option>
            </select>
            <div className="btn-group">
                <LinkComponent
                    href="page-listing-grid.html"
                    className="btn btn-light"
                    data-toggle="tooltip"
                    title="List view"
                >
                    <i className="fa fa-bars" />

                </LinkComponent>
                <LinkComponent
                    href="page-listing-large.html"
                    className="btn btn-light active"
                    data-toggle="tooltip"
                    title="Grid view"
                >
                    <i className="fa fa-th" />

                </LinkComponent>
            </div>
        </div> */}

        <div
            style={{
                float: 'left',
            }}
        >
            {' '}
            {searchQuery ? (
                <i>
                    {searchQuery}
                    {': '}
                </i>
            ) : null}
            <strong className="mr-md-auto">
                {total}
                {' '}
                Items found
                {' '}
            </strong>

        </div>

        <LinkComponent
            style={{
                float: 'right',
            }}
            onClick={onClearFilter}
            className={`btn rounded-pill btn-outline-primary ${clearButtonVisibility ? '' : 'hide'}`}
        >
            {clearButtonLabel}
        </LinkComponent>
    </header>
)));

ProductStackHeader.propTypes = {
    total: PropTypes.number.isRequired,
    onClearFilter: PropTypes.func,
    clearButtonVisibility: PropTypes.bool,
    clearButtonLabel: PropTypes.string,
};

ProductStackHeader.defaultProps = {
    onClearFilter: null,
    clearButtonVisibility: null,
    clearButtonLabel: null,
};

export default ProductStackHeader;
