/* eslint-disable */
import React from 'react';
import MultipleTileCarouselComponent from '../tiles/MultipleTileCarouselComponent';

const CarrouselMultipleTileRailComponent = ({
    title,
    invert,
    children,
}) => (
    <section className="section-content mb-4">
        <div className="container">
            <header className="section-heading">
                <h3 className="section-title">{title}</h3>
            </header>
            <MultipleTileCarouselComponent>
                {children}
            </MultipleTileCarouselComponent>
        </div>
    </section>
);
CarrouselMultipleTileRailComponent.propTypes = {

};

export default CarrouselMultipleTileRailComponent;
