/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useRef, memo } from 'react';
import TextComponent from './text-component/TextComponent';

const ApplyCouponComponent = memo(({
    onCouponCodeApply,
    onCouponRemove,
    discountAmount,
    isInvalid,
    appliedCoupon,
    applyCouponMessage,
}) => {
    const inputRef = useRef();

    const hasCoupon = !!appliedCoupon;

    const onApplyHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onCouponCodeApply && inputRef.current && inputRef.current.value) {
            onCouponCodeApply(inputRef.current.value);
        }
    };

    const onRemoveHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onCouponRemove) {
            onCouponRemove(inputRef);
        }
    };

    const actionButtonElement = hasCoupon && !isInvalid ? (
        <button
            onClick={onRemoveHandler}
            type="button"
            className="btn btn-light"
        >
            Remove
        </button>
    ) : (
        <button
            onClick={onApplyHandler}
            type="button"
            className="btn btn-primary"
        >
            Apply
        </button>
    );

    let messageElement = null;
    if (hasCoupon) {
        if (isInvalid) {
            messageElement = (
                <div
                    className="alert alert-danger text-capitalize"
                    role="alert"
                >
                    {applyCouponMessage}
                </div>
            );
        }
        else {
            messageElement = (
                <div
                    className="alert alert-primary text-capitalize"
                    role="alert"
                >
                    {`Congrats! You got ${discountAmount} OFF`}
                </div>
            );
        }
    }
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div>
                    <div className="form-group">
                        <label>Have coupon?</label>
                        <div className="input-group">
                            <TextComponent
                                ref={inputRef}
                                type="text"
                                className="form-control"
                                onEnter={onApplyHandler}
                                placeholder="Coupon Code"
                                disabled={(hasCoupon && !isInvalid)}
                                defaultValue={appliedCoupon}
                            />
                            <span className="input-group-append">
                                {actionButtonElement}
                            </span>
                        </div>
                    </div>
                    {messageElement}
                </div>
            </div>
        </div>
    );
});

ApplyCouponComponent.propTypes = {
    onCouponCodeApply: PropTypes.func.isRequired,
    onCouponRemove: PropTypes.func,
    discountAmount: PropTypes.number,
    isInvalid: PropTypes.bool,
    appliedCoupon: PropTypes.string,
    applyCouponMessage: PropTypes.string,
};

ApplyCouponComponent.defaultProps = {
    onCouponRemove: null,
    discountAmount: null,
    isInvalid: null,
    appliedCoupon: null,
    applyCouponMessage: null,
};

export default ApplyCouponComponent;
