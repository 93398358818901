import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const metaDataApi = function ({
    refCode,
}) {
    const url = `${config.baseUrl}meta-data`;
    return getRequest({
        url,
        params: {
            ref_id: refCode,
        },
    });
};

export default {
    metaDataApi,
};
