import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';

export const assetApi = function ({
    refCode,
}) {
    const url = `${config.baseUrl}assets/${refCode}_assets`;
    return getRequest({
        url,
    });
};

export default {
    assetApi,
};
