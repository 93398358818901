import { put, call, select } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { getPincodeInfoAPI, getGoogleLocationDataAPI, getStateListAPI } from 'src/api/LocationApi';
import {
    fetchPincodeInfoErrorAction,
    fetchPincodeInfoSuccessAction,
    fetchPincodeStoreProductCountErrorAction,
    fetchPincodeStoreProductCountSuccessAction,
    fetchStateListSuccessAction,
    googleRevGeoSuccessAction,
    LOCATION_ACTIONS,
    pincodeSelectedAction,
} from 'src/actions/LocationActions';
import { productFetchAction, PRODUCT_ACTIONS } from 'src/actions/ProductActions';
import { getStoreAndProductCountByPincodeAPI } from 'src/api/ProductApi';
import { couponsFetchAction, resetCartAction } from 'src/actions/CartActions';
import { resetPageByPincodeAction } from 'src/actions/LayoutActions';

export function* getPincodeInfoSaga({ pincode }) {
    try {
        const {
            data: {
                data: pincodeInfo,
            },
        } = yield call(getPincodeInfoAPI, { pincode });

        // empty products so that new fetch can happen
        yield put({
            type: PRODUCT_ACTIONS.PRODUCT_FETCH_SUCCESS,
            products: [],
        });

        yield put(fetchPincodeInfoSuccessAction({
            pincode,
            pincodeInfo,
        }));
    }
    catch (error) {
        console.error('getPincodeInfoSaga', error);
        yield put(fetchPincodeInfoErrorAction({
            pincode,
        }));
    }
}

export function* getStoreAndProductCountByPincodeSaga({ pincode }) {
    try {
        const {
            data: {
                data: storeAndProductCount,
            },
        } = yield call(getStoreAndProductCountByPincodeAPI, { pincode });

        // empty products so that new fetch can happen
        yield put({
            type: PRODUCT_ACTIONS.PRODUCT_FETCH_SUCCESS,
        });

        yield put(fetchPincodeStoreProductCountSuccessAction({
            pincode,
            storeAndProductCount,
        }));
    }
    catch (error) {
        console.error('getStoreAndProductCountByPincodeSaga', error);
        yield put(fetchPincodeStoreProductCountErrorAction({
            pincode,
        }));
    }
}

export function* getGoogleLocationDataSaga({
    latitude,
    longitude,
}) {
    const {
        appReducer: {
            clientData: {
                google_geo_api_key,
            },
        },
    } = yield select();

    if (!google_geo_api_key || !latitude || !longitude) {
        return;
    }

    try {
        const {
            results,
        } = yield call(getGoogleLocationDataAPI, {
            query: `${latitude}, ${longitude}`,
            key: google_geo_api_key,
        });

        const [
            {
                address_components,
                formatted_address,
            },
        ] = results;
        const {
            long_name,
        } = address_components.find((item) => item.types.includes('postal_code'));

        yield put(googleRevGeoSuccessAction({
            currentAddress: {
                address: formatted_address,
                pincode: long_name,
            },
        }));
    }
    catch (error) {
        console.error('getGoogleLocationDataSaga', error);
    }
}

function* pincodeChangedSaga({ pincode }) {
    const {
        locationReducer: {
            selectedPincode,
        },
    } = yield select();

    if (selectedPincode !== pincode) {
        yield put(resetCartAction());
        yield put(pincodeSelectedAction({ selectedPincode: pincode }));
        yield put(resetPageByPincodeAction());
        yield put(productFetchAction());
        yield put(couponsFetchAction());
    }
}

function* fetchStatesSaga() {
    try {
        const {
            data: {
                data: states,
            },
        } = yield call(getStateListAPI);

        yield put(fetchStateListSuccessAction({
            states,
        }));
    }
    catch (error) {
        console.error('LOG: fetchStatesSaga > error', error);
    }
}

export default [
    takeFirstSagaUtil(LOCATION_ACTIONS.PINCODE_INFO_FETCH, getPincodeInfoSaga),
    takeFirstSagaUtil(LOCATION_ACTIONS.PINCODE_STORE_PRODUCT_COUNT_FETCH, getStoreAndProductCountByPincodeSaga),
    takeFirstSagaUtil(LOCATION_ACTIONS.GOOGLE_REV_GEO_FETCH, getGoogleLocationDataSaga),
    takeFirstSagaUtil(LOCATION_ACTIONS.PINCODE_CHANGED, pincodeChangedSaga),
    takeFirstSagaUtil(LOCATION_ACTIONS.STATES_LIST_FETCH, fetchStatesSaga),
];
