import { put, call, select } from 'redux-saga/effects';
import { getCategoriesByPincodeAPI, getProductByPincodeAPI } from 'src/api/ProductApi';
import { productReset, PRODUCT_ACTIONS } from 'src/actions/ProductActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { getStoresByPincodeAPI } from 'src/api/StoreApi';
import { STORE_ACTIONS } from 'src/actions/StoreActions';
import { get } from 'lodash-es';

export function* fetchProductSaga({
    page,
    rows,
    storeNames,
    categories,
    price,
    searchQuery,
}) {
    yield put(productReset());
    try {
        const {
            locationReducer: {
                selectedPincode,
            },
            productReducer: {
                stores: storesInReducer,
                categories: categoriesInReducer,
            },
        } = yield select();

        if (!selectedPincode) {
            return;
        }

        let products = [];
        let storeList = [];
        let categoryList = [];

        ({ data: { data: products } } = yield call(getProductByPincodeAPI, {
            pincode: selectedPincode,
            page,
            rows,
            storeNames,
            categories,
            price,
            searchQuery,
        }));

        const totalProducts = get(products, '[0].total_rows', 0);
        let storesMap = {};
        let categoriesMap = {};

        // if stores are not there then fetch stores
        if (!Object.keys(storesInReducer || {}).length) {
            ({ data: { data: storeList } } = yield call(getStoresByPincodeAPI, { pincode: selectedPincode }));

            for (let i = 0; i < storeList.length; i++) {
                const store = storeList[i];
                storesMap[store.name] = store;
            }

            // putting store reducer (it should be an array)
            yield put({
                type: STORE_ACTIONS.STORE_FETCH_SUCCESS,
                stores: storeList,
            });
        }
        else {
            storesMap = storesInReducer;
        }

        // if categories are not there then fetch categories
        if (!Object.keys(categoriesInReducer || {}).length) {
            ({ data: { data: categoryList } } = yield call(getCategoriesByPincodeAPI, { pincode: selectedPincode }));

            for (let i = 0; i < categoryList.length; i++) {
                const item = categoryList[i];
                categoriesMap[item.category] = item;
            }
        }
        else {
            categoriesMap = categoriesInReducer;
        }

        // put products into store
        yield put({
            type: PRODUCT_ACTIONS.PRODUCT_FETCH_SUCCESS,
            products,
            stores: storesMap,
            categories: categoriesMap,
            totalProducts,
        });
    }
    catch (error) {
        console.error('fetchProduct', error);
        yield put({
            type: PRODUCT_ACTIONS.PRODUCT_FETCH_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(PRODUCT_ACTIONS.PRODUCT_FETCH, fetchProductSaga),
];
