/* eslint-disable */
import React from 'react';
import LinkWrap from '../common/LinkWrap';

const SquareTileComponent = ({
    imgUrl,
    to
}) => (
    <div
        className="col-sm-4 col-md-3 mb-3"
    >
        <LinkWrap to={to}>
            <img
                className="img-fluid"
                src={imgUrl}
                alt="First slide"
            />
        </LinkWrap>
    </div>
);

SquareTileComponent.propTypes = {

};

export default SquareTileComponent;
