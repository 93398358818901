export const ROUTING_URL = {
    HOME_PAGE: '/',
    PRODUCT_SEARCH_PAGE: '/search',
    CART_PAGE: '/cart',
    LOGIN_PAGE: '/login',
    ORDERS_PAGE: '/profile/orders',
    USER_ADDRESS_PAGE: '/profile/address',
    USER_PROFILE_PAGE: '/profile',
    USER_DETAILS_PAGE: '/profile/details',
    COUPON_PAGE: '/coupons',
    PIN_CODE_SELECTION_PAGE: '/pin-code',
    PRODUCT_DETAILS_PAGE: '/product-details',
    PRODUCT_REVIEW_PAGE: '/product-review',
};

export const QUERY_PARAMS = {
    SEARCH_QUERY: 'q',
    COUPON: 'coupon',
};

export default {
    QUERY_PARAMS,
    ROUTING_URL,
};
