/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { ProductItemPropType } from 'src/constants/prop-types/ProductPropTypes';
import { DeliveryTypePropTypes } from 'src/constants/prop-types/OrderPropTypes';
import PropTypes from 'prop-types';
import DeliveryAddressContainer from 'src/containers/profile/DeliveryAddressContainer';
import ApplyCouponContainer from 'src/containers/cart/ApplyCouponContainer';
import PaymentCardContainer from 'src/containers/cart/PaymentCardContainer';
import { groupBy } from 'lodash-es';
import StoreAddressContainer from 'src/containers/smart-components/StoreAddressContainer';
import CartArticleListComponent from '../common/CartArticleListComponent';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import TextComponent from '../common/text-component/TextComponent';

const CartComponent = ({
    items,
    onDeliveryDateChange,
    minDeliveryDate,
    selectedDeliveryDate,
    maxDeliveryDate,
    coupon,
    onRemarksChange,
    deliveryTypes,
    deliveryTypeChange,
    selectedDeliveryType,
    isUserAddressNeeded,
}) => {
    const [
        groupByStore,
        setGroupByStore,
    ] = useState({});

    useEffect(() => {
        const groupedItems = groupBy(items, (item) => item.store_name);
        setGroupByStore(groupedItems);
    }, [
        items,
        setGroupByStore,
    ]);
    const cartItemElementsByStore = Object.keys(groupByStore).map((storeName, index) => (
        <div
            className="card mb-3"
            key={storeName}
        >
            <CartArticleListComponent
                title={`Order #${index + 1} From ${storeName} Store`}
                items={groupByStore[storeName]}
            />
            <div className="mr-1 ml-3 mb-2">
                <StoreAddressContainer storeName={storeName} />
            </div>
        </div>
    ));

    return (
        <section className="section-content padding-y">
            <div className="container">
                <div className="row">
                    <aside className="col-lg-9">
                        {cartItemElementsByStore}
                        <div className="container-fluid mt-3 mb-3 mb-md-0">
                            <div className="row">
                                {/* time */}
                                <div className="col-md-4 pl-0 pr-0 pr-md-3">
                                    <div className="alert alert-success">
                                        <div className="icontext">
                                            <i className="icon text-success fa fa-truck" />
                                            <DateTimePickerComponent
                                                onAccept={onDeliveryDateChange}
                                                minDate={minDeliveryDate}
                                                selectedDate={selectedDeliveryDate}
                                                maxDate={maxDeliveryDate}
                                                label="Preferred Delivery Time"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* remarks */}
                                <div className="col-md-4 pl-0 pr-0 pr-md-3">
                                    <label>Remarks</label>
                                    <TextComponent
                                        type="text"
                                        className="form-control"
                                        onValueChange={onRemarksChange}
                                    />
                                </div>

                                {/* delivery type */}
                                <div className="col-md-4 pl-0 pr-0 mt-2 mt-md-0">
                                    <div>
                                        <label>Delivery Type</label>
                                    </div>
                                    <div
                                        className="btn-group"
                                        role="group"
                                        aria-label="Basic example"
                                    >
                                        {
                                            deliveryTypes.map((deliveryType) => (
                                                <button
                                                    key={deliveryType.id}
                                                    onClick={() => deliveryTypeChange && deliveryTypeChange(deliveryType)}
                                                    type="button"
                                                    className={`btn text-capitalize ${selectedDeliveryType?.id === deliveryType.id ? 'btn-primary' : 'btn-light'}`}
                                                >
                                                    {deliveryType.name}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isUserAddressNeeded ? <DeliveryAddressContainer /> : null}
                    </aside>
                    <aside className="col-lg-3">
                        <ApplyCouponContainer coupon={coupon} />
                        <PaymentCardContainer />
                    </aside>
                </div>
            </div>
        </section>
    );
};
CartComponent.propTypes = {
    items: PropTypes.objectOf(ProductItemPropType),
    onDeliveryDateChange: PropTypes.func,
    minDeliveryDate: PropTypes.number,
    selectedDeliveryDate: PropTypes.number,
    maxDeliveryDate: PropTypes.number,
    coupon: PropTypes.string,
    onRemarksChange: PropTypes.func,
    deliveryTypeChange: PropTypes.func,
    deliveryTypes: PropTypes.arrayOf(DeliveryTypePropTypes),
    selectedDeliveryType: DeliveryTypePropTypes,
    isUserAddressNeeded: PropTypes.bool,
};

CartComponent.defaultProps = {
    items: {},
    onDeliveryDateChange: null,
    minDeliveryDate: null,
    selectedDeliveryDate: null,
    maxDeliveryDate: null,
    coupon: null,
    onRemarksChange: null,
    deliveryTypeChange: () => { },
    deliveryTypes: [],
    selectedDeliveryType: {},
    isUserAddressNeeded: true,
};

export default CartComponent;
