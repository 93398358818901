import { put, call, select } from 'redux-saga/effects';
import { productAggregatedReviewFetchAction, PRODUCT_REVIEW_ACTIONS } from 'src/actions/ProductReviewActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import {
    getAggregateReviewByProductCode, getReviewByProductCode, getReviewByProductCodeAndUserId, upsertReviewAPI,
} from 'src/api/ReviewApi';
import { appHeadMessageShow } from 'src/actions/AppActions';

export function* fetchProductReviewSaga({
    productCode,
}) {
    try {
        const { userReducer: { userDetails: { id: userId } } } = yield select();

        // fetch all reviews for a product
        const { data: { data: reviews } } = yield call(getReviewByProductCode, {
            productCode,
        });

        let userReview = {};
        // fetch user review for a product
        if (userId) {
            ({
                data: {
                    data: [
                        userReview,
                    ],
                },
            } = yield call(getReviewByProductCodeAndUserId, {
                productCode,
                userId,
            }));
        }

        // update aggregateRating
        yield put(productAggregatedReviewFetchAction({
            productCode,
        }));

        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH_SUCCESS,
            reviews,
            userReview,
            productCode,
        });
    }
    catch (error) {
        console.error('fetchProductReviewSaga', error);
        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH_ERROR,
        });
    }
}

export function* upsertReview({
    title,
    description,
    productCode,
    rating,
    id,
}) {
    try {
        const { userReducer: { userDetails: { id: userId } } } = yield select();

        // call api to add
        yield call(upsertReviewAPI, {
            id,
            customer_id: userId,
            rating,
            title,
            description,
            product_code: productCode,
        });

        // success
        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT_SUCCESS,
        });

        yield put(appHeadMessageShow({
            headMessage: [
                'Review has been added',
            ],
        }));

        yield call(fetchProductReviewSaga, {
            productCode,
        });
    }
    catch (error) {
        console.error('upsertReview', error);
        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT_ERROR,
        });
        yield put(appHeadMessageShow({
            headMessage: [
                'Please try again after sometime',
            ],
        }));
    }
}

function* fetchAggregatedReviewSaga({
    productCode,
}) {
    try {
        // get product rating
        const { data: { data: reviewAggregation } } = yield call(getAggregateReviewByProductCode, {
            productCode,
        });

        // update aggregateRating
        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_AGGREGATED_REVIEW_SUCCESS,
            reviewAggregation,
            productCode,
        });
    }
    catch (error) {
        console.error('fetchAggregatedReviewSaga', error);
        yield put({
            type: PRODUCT_REVIEW_ACTIONS.PRODUCT_AGGREGATED_REVIEW_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH, fetchProductReviewSaga),
    takeFirstSagaUtil(PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT, upsertReview),
    takeFirstSagaUtil(PRODUCT_REVIEW_ACTIONS.PRODUCT_AGGREGATED_REVIEW, fetchAggregatedReviewSaga),
];
