/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SUB_LIST_SIZE = 2;

const TupleComponent = ({
    list,
    className,
}) => {
    const [
        subList,
        setSubList,
    ] = useState([]);

    const [
        showAll,
        setShowAll,
    ] = useState(false);

    const showAllHandler = useCallback(() => {
        setShowAll(true);
    }, []);

    useEffect(() => {
        setSubList(list.slice(0, SUB_LIST_SIZE));
    }, [
        list,
    ]);

    const showMoreElement = list.length > SUB_LIST_SIZE && !showAll ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
            className="badge badge-primary ml-1 cursor-pointer"
            onClick={showAllHandler}
        >
            +
            {list.length - SUB_LIST_SIZE}
            {' '}
            more
        </span>
    ) : null;

    if (!Array.isArray(list) || !list.length) {
        return null;
    }

    return (
        <div className={className}>
            {(showAll ? list : subList).map((value) => (
                <span
                    key={value}
                    className="badge badge-info ml-1"
                >
                    {value}
                </span>
            ))}
            {showMoreElement}
        </div>
    );
};

TupleComponent.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
};

TupleComponent.defaultProps = {
    className: '',
    list: [],
};

export default TupleComponent;
