export const PRODUCT_REVIEW_ACTIONS = {
    PRODUCT_REVIEW_FETCH: 'PRODUCT_REVIEW_FETCH',
    PRODUCT_REVIEW_FETCH_SUCCESS: 'PRODUCT_REVIEW_FETCH_SUCCESS',
    PRODUCT_REVIEW_FETCH_ERROR: 'PRODUCT_REVIEW_FETCH_ERROR',

    PRODUCT_REVIEW_UPSERT: 'PRODUCT_REVIEW_UPSERT',
    PRODUCT_REVIEW_UPSERT_SUCCESS: 'PRODUCT_REVIEW_UPSERT_SUCCESS',
    PRODUCT_REVIEW_UPSERT_ERROR: 'PRODUCT_REVIEW_UPSERT_ERROR',

    PRODUCT_AGGREGATED_REVIEW: 'PRODUCT_AGGREGATED_REVIEW',
    PRODUCT_AGGREGATED_REVIEW_SUCCESS: 'PRODUCT_AGGREGATED_REVIEW_SUCCESS',
    PRODUCT_AGGREGATED_REVIEW_ERROR: 'PRODUCT_AGGREGATED_REVIEW_ERROR',
};

export const productReviewFetchAction = (payload) => ({
    type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_FETCH,
    ...payload,
});

export const productReviewUpsertAction = (payload) => ({
    type: PRODUCT_REVIEW_ACTIONS.PRODUCT_REVIEW_UPSERT,
    ...payload,
});

export const productAggregatedReviewFetchAction = (payload) => ({
    type: PRODUCT_REVIEW_ACTIONS.PRODUCT_AGGREGATED_REVIEW,
    ...payload,
});

export default {
    PRODUCT_REVIEW_ACTIONS,
    productReviewFetchAction,
    productReviewUpsertAction,
    productAggregatedReviewFetchAction,
};
